import React from 'react';
import Sanitized from "@components/Sanitized";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Styles } from './SubContentStyles';
import { TSubContent } from '../types';
import { isMobile } from '@common/deviceTypeHelper';
import { translationTools } from '@components/MessageContent/WelcomeUserMessageContent/utils';

const SLOT_MACHINE_NON_PLAYABLE_DESCRIPTION = "Sorry! You can only play once per day!";
const SlotMachineDescriptionText = (props) => {
    const [html, setHtml] = useState(props?.subContent?.description);
    const { i18n } = useTranslation();

    // SLOT MACHINE RELATED button non playable text
    useEffect(() => {
        if (props?.UIData?.button?.userCanPlay === false) {
            setHtml(SLOT_MACHINE_NON_PLAYABLE_DESCRIPTION);
        }
    }, [props?.UIData?.button?.userCanPlay])

    // SLOT MACHINE RELATED translations
    useEffect(() => {
        if (i18n.language !== 'en'
            && props?.UIData?.button?.userCanPlay === false) {
            const _abrtCtrl = new AbortController();
            (async () => {
                try {
                    const restTranslationArr = await translationTools.translateArrOfStr([SLOT_MACHINE_NON_PLAYABLE_DESCRIPTION], i18n.language, _abrtCtrl);
                    setHtml(restTranslationArr[0]);
                } catch (err) {
                    console.error('Error occured in SubContent.tsx in SLOT MACHINE subType description related translations!', err)
                }
            })();
            return () => _abrtCtrl.abort();
        }
    }, [html, props?.UIData?.button?.userCanPlay, i18n.language])

    return (<Sanitized html={html} />)
}

const DescriptionTextMaster = (props) => {
    if (props?.subContent?.subType === 'slotMachine') {
        return <SlotMachineDescriptionText {...props} />
    }
    return <Sanitized html={props?.subContent?.description} />;
}

type TProps = {
    subContent: TSubContent;
    UIData: any;
};
const Description = (
    props: TProps
) => {
    return (
        <div style={Styles.description(isMobile())}>
            <DescriptionTextMaster {...props} />
        </div>
    );
}

export { Description }