import React, { createContext, Dispatch, MutableRefObject, SetStateAction, useContext } from 'react';
import { MorphType } from '@common/MorphType';
import { IErrand, IPrompt, IVideo } from '@interfaces/Conversation';
import { ChatFetchParamType } from '../../src/Pages/Conversation/ChatFetchStateManager';

/**
 * This context exists in the global scope and stores
 * data that should be shared across all conversations
 * and all components in the app.
 *
 * This includes elements related to the following
 * Loading, captcha, keyboard navigation refs
 *
 * NOTICE: Avoid adding useState variables when possible
 */
export interface IRootContext {
  messagesAreLoading: boolean;
  setMessagesAreLoading: React.Dispatch<React.SetStateAction<boolean>>;
  openedCaptcha?: boolean;
  operatorView?: boolean;
  setOpenedCaptcha?: (value: boolean) => void;
  selectedIndex: number[];
  setSelectedIndex?: Dispatch<SetStateAction<number[]>>;
  getErrandsLength: () => number;
  leaveFormGoToParentChat: (parentId: string) => void;
  loadMoreErrands: () => Promise<void>;
  syncErrandsSequence: (chatIds: Array<string>) => void;
  editErrandId?: MutableRefObject<string> | null;
  errands: IErrand[];
  setErrands: Dispatch<SetStateAction<IErrand[]>>;
  rootMorphType?: MorphType; //Creating a rootMorphType here because errandContext's morphType does not reach ErrandsContainer
  setRootMorphType?: any;
  morphedId?: MutableRefObject<string> | null;
  createSignatureMobileIsOn?: boolean;
  setCreateSignatureMobileIsOn?: (value: boolean) => void;
  childErrands?: IErrand[];
  errandColorRef?: MutableRefObject<string> | null;
  userSelectedPromptRef?: MutableRefObject<IPrompt> | null; 
  userSelectedPrompt: string;
  setUserSelectedPrompt: Dispatch<SetStateAction<string>>;
  userSelectedVideoRef?: MutableRefObject<IVideo> | null;
  videoMenuTitle: string;
  setVideoMenuTitle: Dispatch<SetStateAction<string>>;
  promptName?: string;
  setPromptName?: Dispatch<SetStateAction<string>>;
  handleActionWorkflow: (id: string, type: string, chatId: string, recipients?: string[]) => void;
  triggerSlotMachine?: (errandId: string, userAction: string) => void;
  drawerRef?: MutableRefObject<HTMLDivElement> | null;
  conversationFooterRef?: MutableRefObject<HTMLDivElement> | null;
  isWidget: boolean;
  viewReload: number,
  chatFetchDataRef: MutableRefObject<ChatFetchParamType>;
  fetchClosedChats: () => Promise<void>;
  chatBulkClose: (errands?: IErrand[]) => Promise<void>;
  consentChecked: boolean;
  setConsentChecked: (value: boolean) => void;
  returnConsentGiven: boolean;
  setReturnConsentGiven: (value: boolean) => void;
  clickedDisabledFeatures: boolean;
  setClickedDisabledFeatures: (value: boolean) => void;
  shakingConsent: boolean;
  handleShakingConsent: () => void;
  showSlotMachine: boolean;
}

export const RootContext = createContext<IRootContext | null>(null);

/**
 * As MRGN-265 asks for, on the frontend display Peter's Captcha when
 * the user sends more than 3 consecutive messages in less 2 seconds.
 */
const LIMITER_RESET_TIME = 2 * 1000; // 2 seconds
const LIMITER_MAX_MSG = 3;

let messageCount = 0;

setInterval(() => {
  messageCount = 0;
}, LIMITER_RESET_TIME);

export function addSentMessage(convoContext: any) {
  messageCount++;
  if (convoContext.operatorView === false && messageCount > LIMITER_MAX_MSG) {
    convoContext.setOpenedCaptcha(true);

    // This fixes a chat focusing bug with Peter's Captcha
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement?.blur();
    }
  }
}

export const useRootContext = () => useContext<IRootContext>(RootContext);