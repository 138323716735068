import React from 'react';
import { TSubContent } from "../types";
import { MountGlareEffect, StillGlareEffect } from "./Effects";
import { isMobile } from '@common/deviceTypeHelper';

interface IMasterProps {
    isRevealed: boolean,
    rightPos: number,
    glareOpacity: number,
    subContent: TSubContent,
    wasHidden: boolean,
    show: boolean
};

const Master = ({ isRevealed, rightPos, glareOpacity, subContent, wasHidden, show }: IMasterProps) => {
    if (!show) {
        return <></>;
    }

    // Slot Machine
    if (subContent.subType === 'slotMachine') {
        // only if was not hidden before.
        if (!wasHidden) {
            if (!isRevealed) {
                return <MountGlareEffect />;
            } else {
                return <StillGlareEffect isMobile={isMobile()} rightPos={rightPos} opacity={glareOpacity} />;
            }
        }
    }


    return <></>;
}

export {
    Master
}