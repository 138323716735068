import React from 'react';
import { Styles } from "./SubContentStyles";

const Overlay = (props) => {
    {/* dark overlay */ }
    return (
        <div style={Styles.overlay(props.overlayOpacity)}></div>
    );
}

export {
    Overlay
}