import ThinClientUtils from "@common/ThinClientUtils";

// Functions to get, set, and remove session cookies related to the user
function setSessionCookie(name, value) {
  // Set the cookie with the session scope (expires when the browser session ends)
  document.cookie = `${name}=${value}; path=/;`;
}

function getSessionCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();  // Return the cookie value
  }
  return null;  // Cookie not found
}

function deleteSessionCookie(name) {
  document.cookie = `${name}=; path=/; max-age=-1;`;  // Set a negative max-age to remove the cookie
}

// Use sessionCookies for userAccessToken so that it can be shared amongst
// all open tabs and be automatically removed upon closing the browser

export const getUserAccessToken = () => {
  return getSessionCookie("userAccessToken");
}
export const setUserAccessToken = (token) => {
  setSessionCookie("userAccessToken", token);
}
export const removeUserAccessToken = () => {
  deleteSessionCookie("userAccessToken");
}

// Functions to get, set, and remove local storage related to the user.
export const getChatId = (): string =>
  localStorage.getItem('chatId');
export const getCountryCode = (): string =>
  localStorage.getItem('countryCode');
export const getIsThinClient = (): string =>
  localStorage.getItem('isThinClient');
export const getPreselectedLanguage = (): string =>
  localStorage.getItem('preselectedLanguage');
export const getStateISO = (): string =>
  localStorage.getItem('stateISO');
export const getTimezone = (): string =>
  localStorage.getItem('timezone');
export const getZipCode = (): string =>
  localStorage.getItem('zipCode');
export const getUnblurChat = (): string =>
  localStorage.getItem('unblurChat');
export const getUnblurLocation = (): string =>
  localStorage.getItem('unblurLocation');
// export const getUserAccessToken = (): string =>
//   localStorage.getItem('userAccessToken');
export const getUserId = (): string =>
  localStorage.getItem('userId');
export const getUserLocation = (): string =>
  localStorage.getItem('userLocation');
export const getIsSessionRestoring = (): string =>
  localStorage.getItem('isSessionRestoring');
export const getUserPlayedLast24Hours = (): boolean => {
  if (!localStorage.getItem('userPlayedTimestamp')) {
    return false;
  } else {
    const now = new Date(); // Current date and time
    const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000); // 24 hours ago
    const dateFromString = new Date(localStorage.getItem('userPlayedTimestamp'))
    return dateFromString > twentyFourHoursAgo && dateFromString <= now;
  }
}
export const getAdditionalUIData = (): string | null => localStorage.getItem('additionalUIData');
export const getWindowLocationPath = (): string =>
  localStorage.getItem('windowLocationPath');
export const getNftData = (): string | null =>
  localStorage.getItem('nftData');

export const removeChatId = (): void =>
  localStorage.removeItem('chatId');
export const removeCountryCode = (): void =>
  localStorage.removeItem('countryCode');
export const removeIsThinClient = (): void =>
  localStorage.removeItem('isThinClient');
export const removePreselectedLanguage = (): void =>
  localStorage.removeItem('preselectedLanguage');
export const removeStateISO = (): void =>
  localStorage.removeItem('stateISO');
export const removeTimezone = (): void =>
  localStorage.removeItem('timezone');
export const removeZipCode = (): void =>
  localStorage.removeItem('zipCode');
export const removeUnblurChat = (): void =>
  localStorage.removeItem('unblurChat');
export const removeUnblurLocation = (): void =>
  localStorage.removeItem('unblurLocation');
// export const removeUserAccessToken = (): void =>
//   localStorage.removeItem('userAccessToken');
export const removeUserConsent = (): void =>
  localStorage.removeItem('userConsent');
export const removeUserConsentIntro = (): void =>
  localStorage.removeItem('userConsentIntro');
export const removeUserId = (): void =>
  localStorage.removeItem('userId');
export const removeUserLocation = (): void =>
  localStorage.removeItem('userLocation');
export const removeAdditionalUIData = (): void =>
  localStorage.removeItem('additionalUIData');
export const removeIsSessionRestoring = (): void =>
  localStorage.removeItem('isSessionRestoring');
export const removeWindowLocationPath = (): void =>
  localStorage.removeItem('windowLocationPath');
export const removeNftData = (): void =>
  localStorage.removeItem('nftData');

export const setAdditionalUIData = (value: string): void =>
  localStorage.setItem('additionalUIData', value);
export const setChatId = (value: string): void =>
  localStorage.setItem('chatId', value);
export const setCountryCode = (value: string): void =>
  localStorage.setItem('countryCode', value);
export const setIsThinClient = (value: string): void =>
  localStorage.setItem('isThinClient', value);
export const setPreselectedLanguage = (value: string): void =>
  localStorage.setItem('preselectedLanguage', value);
export const setUserPlayedTimestamp = (): void =>
  localStorage.setItem('userPlayedTimestamp', new Date().toString());
export const setStateISO = (value: string): void =>
  localStorage.setItem('stateISO', value);
export const setTimezone = (value: string): void =>
  localStorage.setItem('timezone', value);
export const setZipCode = (value: string): void =>
  localStorage.setItem('zipCode', value);
export const setUnblurChat = (value: string): void =>
  localStorage.setItem('unblurChat', value);
export const setUnblurLocation = (value: string): void =>
  localStorage.setItem('unblurLocation', value);
// export const setUserAccessToken = (value: string): void =>
//   localStorage.setItem('userAccessToken', value);
export const setUserId = (value: string): void =>
  localStorage.setItem('userId', value);
export const setUserLocation = (value: string): void =>
  localStorage.setItem('userLocation', value);
export const setIsSessionRestoring = (value: string): void =>
  localStorage.setItem('isSessionRestoring', value);
export const setWindowLocationPath = (value: string): void =>
  localStorage.setItem('windowLocationPath', value);
export const setNftData = (value: string): void =>
  localStorage.setItem('nftData', value);

export const clearUserCookies = (): void =>
  document.cookie.split(';').forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
  });

// remove the user storage
export const removeUserSession = (): void => {
  // removeUserConsent(); // don't remove this as it requires the user to consent again after login.
  removeUserId();
  removeUserAccessToken();
  removeUnblurChat();
  removeUnblurLocation();
  removePreselectedLanguage();
  clearUserCookies();
};

// set the user storage and their session info
export const setUserSession = (token: string, id: string) => {
  // If its blank we have no reason to be setting it
  if (token !== undefined && id !== undefined) {
    setUserAccessToken(token);
    setUserId(id);

    // Must manually dispatch storage events for Thin Client
    // because by default, storage events aren't emitted in the 
    // same browser window where the localStorage is updated.
    // Thin Client uses event listeners to retrieve the userId
    // and userAccessToken as soon as they are stored.
    if (ThinClientUtils.isThinClient()) {
      window.dispatchEvent(new StorageEvent('storage', {
        key: 'userAccessToken',
        newValue: token,
        oldValue: localStorage.getItem('userAccessToken'),
        url: window.location.href,
        storageArea: localStorage
      }));
      window.dispatchEvent(new StorageEvent('storage', {
        key: 'userId',
        newValue: id,
        oldValue: localStorage.getItem('userId'),
        url: window.location.href,
        storageArea: localStorage
      }));
    }
  }
  // send the userId and access token to be stored in the widget's parent localStorage
  const searchParams = new URLSearchParams(document.location.search);
  if (searchParams.get('widget') === 'true' && searchParams.get('origin')) {
    window.parent.postMessage(
      { source: 'AngelAi', functionality: 'setStorageData', userId: id, userAccessToken: token },
      origin
    );
  }
};
