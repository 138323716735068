/**
 * Enums and Interfaces
 * */

// the pages in the wallet UI
enum Page {
  Welcome,
  RecoverAccount,
  ViewRecoveryPhrase,
  AccessPin,
  LinkDeviceDesktop,
  LinkDeviceMobile,
  LinkDeviceMobileSuccess,
  MyNftsListView,
  MyNftsSingleView,
  ApprovedAccounts,
  AttachedNftSingleView,
  BidsOnNftListView,
  BidsOnNftSingleView,
  AcceptedBidSingleView,
  PublicNftsListView,
  PublicNftsSingleView,
}

// TODO: these values should be obtained from the variables in MorphWallet.module.css
enum Color {
  White = '#FFFFFF',
  Orange = '#FF7400',
  Blue = '#010D2F',
  Violet = '#6050DC',
  DarkBlue = '#01011A',
  LightGray = '#D3D3D3',
  Gray = '#808080',
  Red = '#FF0000',
}

// the various events dispatched and received by the wallet UI
enum Event {
  ChatSend = 'walletChatSend', // for receiving messages sent from the chat box
  ChatInput = 'walletChatInput', // for identifying whenever the user has typed any character in the chat box (i.e. onChange)
}

// the various NFT types
// TODO: pull this from the DB
enum NftType {
  TRUAPP = 'TRUAPP', // TRU-Approval
  TRUBID = 'TRUBID', // Purchase Bid
  TICKET = 'TICKET', // Event Ticket
}

// the various user types that can exist on an NFT
// TODO: pull this from DB
enum NftUserType {
  OWNER = 'OWNER', // owner
  APPROVED = 'APPROVED', // approved user
}

// the various NFT lists that can be pulled in the UI
enum NftList {
  MyNfts,
  BidsOnNft,
  PublicNfts,
}

// the "Token" type with all the data that can be expected on an NFT
interface Token {
  tokenId: number;
  contractAddress: string;
  name: string;
  nftType: NftType;
  userType: NftUserType;
  owner: string;
  metaData: any;
  isPublic: boolean;
  isBurned: boolean;
}

export { Page, Color, Event, NftType, NftUserType, NftList, Token };
