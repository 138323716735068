import React from 'react';
import styled from "@emotion/styled";
import { keyframes } from "@mui/material";
import { ReactNode } from "react";
import { TSubContent } from "../types";
import { Styles } from './SubContentStyles';
import { isMobile } from '@common/deviceTypeHelper';

const neonFlashAnimation = keyframes`
  0%, 100% {
    background-position: 0%;
  }
  50% {
    background-position: 300%;
  }
`;

const TitleNeonFlashContainer = styled('div')<{ styleObj: React.CSSProperties, className: string }>(({ styleObj }) => ({
    ...styleObj,
    background: "linear-gradient(90deg, #ffffff, #860083 10%, #ffffff)",
    backgroundSize: '400%',
    animation: `${neonFlashAnimation} 5s ease infinite`,
    animationDelay: '0s',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    position: 'relative'
}));

interface ITitleProps {
    subContent: TSubContent,
    wasHidden: boolean,
    isRevealed: boolean,
    text: string
};

const Text = (props) => {
    return (
        <span style={Styles.titleSpan(isMobile())}>
            {props.text}
        </span>
    )
}

const Title = ({ subContent, wasHidden, isRevealed, text }: ITitleProps) => {
    const className = 'BWMC_title-container-master';

    if (subContent.subType === 'slotMachine' && !wasHidden && isRevealed) {
        return (
            <TitleNeonFlashContainer className={`${className}-${subContent.subType}`} styleObj={Styles.title}>
                <Text text={text} />
            </TitleNeonFlashContainer>
        );
    }

    return (
        <div style={Styles.title}>
            <Text text={text} />
        </div>
    );
}

export {
    Title
}