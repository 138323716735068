import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Styles from '@styles/MessageContent/BlockchainWelcomeMessageContent.module.css';
import { useUserContext } from '@contexts/user';
import Sanitized from '@components/Sanitized';
import { useTranslation } from 'react-i18next';
import { getIconPath } from './BlockchainWelcomeMessageContent/utils';
import { SubContentPart } from './BlockchainWelcomeMessageContent/SubContent/SubContent';
import { THeaderData, TSubContent } from './BlockchainWelcomeMessageContent/types';
import { isMobile } from '@common/deviceTypeHelper';
import { ValidatorFunctions } from '@common/Validators';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useErrandContext } from '@contexts/ErrandContext';
import useThrottleImmediate from '@common/hooks/useThrottleImmediate';

const getInitIsExpanded = (header, isOperator) => {
  if (( header && ValidatorFunctions.isUndefinedOrNull(header?.MP4url) ) || isOperator) return false;
  return true;
}

const getInitShowWelcome = (header, isOperator) => {
  if (( header && ValidatorFunctions.isUndefinedOrNull(header?.MP4url) ) || isOperator) return true;
  return false
}

const getInitMargin = (header, isOperator) => {
  if (( header && ValidatorFunctions.isUndefinedOrNull(header?.MP4url) ) || isOperator) return false;
  return true;
}

const prependCDNUrl = (url) => {
  return `${process.env.REACT_APP_MORGAN_CDN}${url}`;
}

const BlockchainWelcomeMessageContent = ({ message, errand }) => {
  const { isOperator } = useUserContext();
  const { setSubContentPartExternalState } = useErrandContext();
  // const russellImage = `${process.env.REACT_APP_MORGAN_CDN}/Images/WUM_Images/rptop2.png`;
  const { t } = useTranslation();
  const videoRef = useRef<HTMLVideoElement>(null);
  // local states
  const [isExpandedVideo, setIsExpandedVideo] = useState(getInitIsExpanded(message?.header, isOperator));
  const [showWelcome, setShowWelcome] = useState(getInitShowWelcome(message?.header, isOperator));
  const [addMargin, setAddMargin] = useState(getInitMargin(message?.header, isOperator));
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsExpandedVideo(true);
    setShowWelcome(false);
    setAddMargin(true);
    setIsPlaying(true);
  };

  const handleEnded = () => {
    setIsExpandedVideo(false);
    setShowWelcome(true);
    setAddMargin(false);
    setIsPlaying(false);
  };

  const handlePlayPause = () => {
    if (videoRef.current){
      if (isExpandedVideo) return;
      if (isPlaying){
        videoRef.current.pause();
      }else{
        videoRef.current.play()
        videoRef.current.muted = false;
      }
    }
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (videoRef.current && !videoRef.current.contains(event.target as Node)) {
      handleEnded();
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  useEffect(() => {
    if (isExpandedVideo) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isExpandedVideo]);

  console.log(message);

  let messageTitle = message?.title;
  let messageSubtitle = message?.subtitle;
  let steps = message?.steps;
  let footer = message?.footer;
  let delimIdx = footer?.indexOf(':');
  let header = message?.header as THeaderData;
  let subContent = message?.subContent as TSubContent;
  const footer1 = footer?.substring(0, delimIdx);
  const footer2 = footer?.substring(delimIdx + 1);
  // If true, uses the default orange color instead of the blockchain blue
  const useDefaultColor = message?.useDefaultColor === true;

  // If this event has a special gif, it will be named after the event.
  // Otherwise, use the default gif for this component
  const gifName = (message.specialGif ? message.eventName : 'default') + 'NftEventAngel';
  const gifUrl = getIconPath(gifName + '.gif');

  const getTopPart = () => {
    if (header && header.type === 'expandedHeader') {
      // depending on type, show the needed UI
      if (ValidatorFunctions.isNotUndefinedNorNull(header?.gif) 
        && ValidatorFunctions.isNotEmptyString(header?.gif)) {
        const ContainerStyle = {
          marginTop: '22px',
          height: '100px',
          width: '100%',
          borderRadius: '15px 15px 0px 0px',
          zIndex: 5,
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          border: '1px solid var(--peach700)',
        };
        const gifSrc = getIconPath(header.gif);
        return (
          <div style={ContainerStyle}>
            <div style={{ marginTop: '5px', zIndex: 1 }} />
            <img src={gifSrc} alt="AngelAi logo" className={Styles.imageNoBorderRadius} />
          </div>
        )
      } else {
        return <></>;
      }
    } else {
      // return default if no special header data provided
      return (
        <div className={Styles.topCircle}>
          <div className={Styles.innerCircle} />
          <img src={gifUrl} alt="AngelAi logo" className={Styles.image} />
        </div>
      );
    }
  }

  const onWholeMessageClickTouchHandler = useCallback((e) => {
    // if no subContent present, do nothing.
    if(ValidatorFunctions.isUndefinedOrNull(subContent)) return;

    // send signal to open.
    // this is being processed in useEffect in SubContent.tsx
    // what this does is it checks if the clicked message is the same as the clicked one AND eTarget does not contain the slideout portion AND
    // only in that case it opens the slideOut portion.
    setSubContentPartExternalState((prev) => {
      return {
        ...prev,
        openSignal: { 
          messageId: message?._id, 
          eTarget: e?.target
        }
      }
    })
  }, [subContent, message?._id, setSubContentPartExternalState])

  const onWholeMessageClickTouchThrottledHandler = useThrottleImmediate(onWholeMessageClickTouchHandler, 1000);

  const getWelcomeContainerStartClassNameArr = () => {
    let classNames = [Styles.welcomeContainer];

    if (subContent && subContent?.type === 'slideOut' && isMobile()) {
      classNames.push(Styles.WCSlideOutMobileWidth);
      classNames.push(Styles.WCSlideOutMobileFontSize);
    } else {
      classNames.push(Styles.WCDefaultWidth);
    }

    return classNames;
  }
  const thanksMp4 = `${process.env.REACT_APP_MORGAN_CDN}/Images/WUM_Images/RPThankYou.mp4`

  if (!message) {
    console.info('Message is undefined');
    return <></>;
  }

  const mainContainerClassnameString = [
    ...getWelcomeContainerStartClassNameArr(),
    ...(isOperator ? [Styles.operatorView] : []),
    ...(message.useDefaultColor && addMargin ? [Styles.addMargin] : [])
  ].join(' ');

  return (
    <div 
      className={mainContainerClassnameString}
      onClick={onWholeMessageClickTouchThrottledHandler} 
      onTouchEnd={onWholeMessageClickTouchThrottledHandler}
    >
      {/* Russel Image Part -- commented out until a more suitable image is found */}
      {/* {message.useDefaultColor &&
        ValidatorFunctions.isUndefinedOrNull(header) && (
          <div className={Styles.russellIntroImg}>
            <img src={russellImage} alt="Tick Icon" />
            <div className={Styles.swagText}>{t('relaxSwag')}</div>
          </div>
        )} */}
      {/* Video Part */}
      {message.useDefaultColor &&
        (ValidatorFunctions.isUndefinedOrNull(header) 
        || ( ValidatorFunctions.isNotUndefinedNorNull(header) 
            && header?.type === 'expandedHeader' && header?.MP4url !== null 
            && header?.MP4url !== "" && header?.MP4url !== undefined )
        ) &&
        <div className={Styles.videoContainer} onClick={handlePlayPause}>
          <video
            ref={videoRef}
            autoPlay={!isOperator}
            playsInline
            muted
            controls={isExpandedVideo}
            onPlay={handlePlay}
            onEnded={handleEnded}
            onError={handleEnded}
            className={[Styles.videoBox,
            ...(isExpandedVideo ? [Styles.expandedVideo] : [])
            ].join(' ')}
          >
            <source src={ValidatorFunctions.isNotUndefinedNorNull(header?.MP4url) ? prependCDNUrl(header.MP4url) : thanksMp4} type="video/mp4" />
            <></>
          </video>
          <div className={Styles.videoControls}>
            {!isPlaying &&
              <>
                <PlayArrowIcon className={Styles.videoButton} onClick={handlePlayPause} />
              </>
            }

          </div>
        </div>
      }

      {(!message.useDefaultColor || (message.useDefaultColor && showWelcome)) && (<div>
        {getTopPart()}
        <div className={`${Styles.top} ${(header && header?.type === 'expandedHeader' && header?.gif) ? '' : [Styles.topMarginsPaddings, Styles.topBorderRadiuses].join(' ')} ${useDefaultColor ? ' ' + Styles.useDefaultBackgroundColor : ''}`}>
          {messageTitle}
        </div>
        <div
          className={`
            ${Styles.bottom}${useDefaultColor ? ' ' + Styles.useDefaultBorderColor : ''}
            ${(subContent && subContent?.type === 'slideOut' && subContent?.subType === 'slotMachine') ? Styles.bottomRightBottomCornerStraight : Styles.bottomDefaultBorderRadius}
          `}
        >
          <>
            <div className={Styles.bottomTop}>
              <div className={Styles.bottomTopLeft}>{messageSubtitle}</div>
              <div className={Styles.bottomTopMiddle} />
              <div
                className={`${Styles.bottomTopRight}${useDefaultColor ? ' ' + Styles.useDefaultBackgroundColor : ''}`}
              />
            </div>
            <div className={`${Styles.bottomBottom} ${subContent && subContent.type === 'slideOut' ? [Styles.posRelative, Styles.pr24, Styles.poppinsFamily, Styles.bottomBottomSlideOutPadding].join(' ') : ''}`}>
              {subContent && (<SubContentPart subContent={subContent} errand={errand} message={message} />)}
              {Array.isArray(steps) &&
                steps.map((step, idx) => (
                  <div key={idx} className={idx === 0 ? Styles.step1 : Styles.stepAfter}>
                    <div
                      className={`${Styles.stepCircle}${useDefaultColor ? ' ' + Styles.useDefaultBackgroundColor : ''
                        }`}
                    >
                      <div className={Styles.imgCircle}>
                        <img src={getIconPath(step.icon)} alt={step.icon} />
                      </div>
                    </div>
                    <div>
                      <Sanitized html={step.message} />
                    </div>
                  </div>
                ))}
            </div>
          </>
          {footer1 && footer2 && (
            <div className={Styles.footer}>
              <span className={Styles.footer1}>{footer1}:</span> {footer2}
            </div>
          )}
        </div>
      </div>)}
    </div>
  );
};

export default BlockchainWelcomeMessageContent;
