import { keyframes } from "@mui/material";
import styled from "@emotion/styled";

// Define keyframes for glare animation
const glare = keyframes`
  0% {
    transform: scale(1); /* Start with no size */
    opacity: 0; /* Fully transparent */
    box-shadow: none; /* Remove all shadows initially */
  }

  50% {
    transform: scale(2); /* Expand the glare */
    opacity: 1; /* Full visibility at the mid-point */
    box-shadow: 0 0 15px 10px rgba(255, 84, 28, 0.8), /* Stronger orange */
                0 0 30px 20px rgba(255, 84, 28, 0.7); /* Reduced transparency */
  }

  100% {
    transform: scale(2.3); /* Fully expanded */
    opacity: 0; /* Fade out completely */
    box-shadow: 0 0 20px 15px rgba(255, 84, 28, 0.6), /* Lighter orange */
                0 0 35px 30px rgba(255, 84, 28, 0.5); /* Even more transparent */
  }
`;
const stillGlare = keyframes`
  0%, 100% {
    background-position: 0%; /* Keep the center of the gradient static initially */
  }

  50% {
    background-position: 100%; /* Animate the gradient to move */
  }
`;

// Styled component for glare animation
const MountGlareEffect = styled('div')(() => ({
  width: '30%',
  height: '30%',
  top: '35%',
  right: '22%',
  borderRadius: '0%',
  animation: `${glare} 0.75s infinite`,
  animationDelay: '0.2s',
  animationTimingFunction: 'ease',
  position: 'absolute',
  zIndex: '-2',
  transition: 'all 0.3s ease'
}));

const StillGlareEffect = styled('div')<{ isMobile: boolean, rightPos: number, opacity: number }>(({ isMobile, rightPos, opacity }) => ({
  height: 'calc(100% + 4px)',
  width: isMobile ? '160px' : '180px',
  top: '-3px',
  right: `${rightPos}px`,
  borderRadius: '0px 10px 10px 0px',
  animation: `${stillGlare} 3.5s infinite`,
  animationDelay: '1.4s',
  animationTimingFunction: 'ease',
  background: 'radial-gradient(circle, #ff00ff, #ff541c, #b600b6)',
  transform: 'scale(1.01)',
  backgroundSize: '200%',
  position: 'absolute',
  zIndex: '-2',
  transition: 'all 0.7s ease-out',
  filter: 'blur(8px)',
  opacity: opacity
}));


export {
  MountGlareEffect,
  StillGlareEffect
}