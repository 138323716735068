import { useRef } from 'react';

const useThrottleImmediate = (callback: (...args: any[]) => void, delay: number) => {
    const isThrottledRef = useRef(false);

    return (...args: any[]) => {
        if (!isThrottledRef.current) {
            callback(...args); // Fire immediately
            isThrottledRef.current = true;
            setTimeout(() => {
                isThrottledRef.current = false; // Allow next call after the delay
            }, delay);
        }
    };
};

export default useThrottleImmediate;
