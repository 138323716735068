import { styled } from '@mui/system';
import { useMediaQuery } from '@mui/material';

export const Styles: React.FC<any> = styled('div')<any>(
  ({ theme }) => `
  z-index: 3;
  font-size: 8px;
  height: fit-content;
  padding: 0;
  padding-left: ${useMediaQuery(theme.breakpoints.up('md')) ? '34px' : '7px'};
  text-transform: capitalize;
  white-space: nowrap;
  margin-left: ${useMediaQuery(theme.breakpoints.up('md')) ? '30px' : '20px'};
  &.isPrivate {
    margin-left: 0;
  }
  div {
    width: 100%;
    margin: auto;
    background-color: ${
      useMediaQuery(theme.breakpoints.up('md')) ? 'transparent' : 'var(--peach000)'
    };
    color: var(--orange600);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    opacity: 0;
    transition: opacity .25s linear;
  }
  span {
    display: none;
    animation: fade .9s linear infinite;
    background-color: var(--orange050);
    border-radius: 50%;
    height: 6px;
    margin: 0 3px 0 0;
    width: 6px;
    :nth-of-type(2) {
      animation-delay: -0.3s;
    }
    :nth-of-type(3) {
      animation-delay: -0.6s;
    }
  }
  @keyframes fade {
    0% {
      opacity 1;
    }
    100% {
      opacity: 0.3;
    }
  }
  p {
    display: none;
    font-family: var(--typographyFontFamily);
    padding: 0;
    margin: 0 0 0 6px;
    font-size: 12px;
    color: var(--orange600);
  }
  &.typing {
    div {
      opacity: 1;
      position: absolute;
      bottom: 60px;
    }
    span {
      display: inline-block;
    }
    p {
      display: inline-block;
    }
  }
  &.morganIsAnalyzing {
    img {
      float: left;
      background: transparent;
      width: 45px;
      max-width: 45px;
      height: 32px;
      max-height: 32px;
      animation: floating 10s infinite ease;
      margin: -3px -5px;
  
      @keyframes floating {
        0% {
          transform: translateY(0px);
        },
        50% {
          transform: translateY(-10px);
        },
        100% {
          transform: translateY(0px);
        }
      }
    }
    div {
      opacity: 1;
    }
    span {
      display: inline-block;
    }
    p {
      display: inline-block;
      margin: 0 0 0 10px;
    }
  }
  `
);
