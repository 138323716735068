import React from 'react';
import { _localStorageTools } from './localStorageTools';
import { Grow, keyframes, styled } from '@mui/material';
import LanguageUtils from '@common/LanguageUtils';
import { ValidatorFunctions } from '@common/Validators';

interface ParsedMsgRenderData {
  title?: string;
  options?: { html: string }[];
  consent?: {
    accepted: string,
    initial: string
  };
}

interface ErrorStatus {
  error?: boolean;
}

// Create dynamic keyframes based on the passed height
const expandAnimation = (height) => keyframes`
  to {
    opacity: 1;
    height: ${height}px; !important;
  }
`;

// Create dynamic keyframes based on the passed height
const slideInAnimation = (height) => keyframes`
  to {
    opacity: 1;
    transform: translateX(0px);
    height: ${height}px; !important;
  }
`;

// Create a styled component with dynamic keyframes
const ExpandAnimationBox = styled('div')<{
  height: number; // Specify that height is a number
}>(({ height }) => ({
  animation: `${expandAnimation(height)} 0.6s forwards`,
}));

// Create a styled component with dynamic keyframes
const SlideInAnimationBox = styled('div')<{
  height: number; // Specify that height is a number
}>(({ height }) => ({
  animation: `${slideInAnimation(height)} 0.6s forwards`,
}));


// extracts string from data-instructions-text attribute
// which has an instruction to show in multiTypingFooterAnimation component
function extractCustomInstruction(htmlString) {
  // Create a temporary DOM element to parse the string
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = htmlString;

  // Find the element with the custom attribute
  const spanElement = tempDiv.querySelector('[data-instructions-text]');

  // Return the value of the custom attribute if it exists
  return spanElement ? spanElement.getAttribute('data-instructions-text') : null;
}

const translationTools = (function () {
  const DEBUG = false; // Toggle this to enable/disable logging
  const CACHE_KEY = 'translationTools_cache';
  const MAX_CACHE_SIZE = 1 * 1024 * 1024; // 1 MB
  let cache = {}; // Preload cache at declaration

  // Utility for logging
  const log = (...args) => {
    if (DEBUG) console.log('[ 👾 translationTools ]', ...args);
  };

  // Convert bytes to MBs for logging
  const bytesToMB = (bytes) => (bytes / (1024 * 1024)).toFixed(2) + ' MB';

  // Helper to estimate object size in bytes
  const estimateSize = (obj) => new Blob([JSON.stringify(obj)]).size;

  // Load cache from localStorage at declaration
  const loadCache = () => {
    try {
      const storedCache = JSON.parse(localStorage.getItem(CACHE_KEY) || '{}');
      log('Cache loaded:', storedCache);
      return storedCache;
    } catch (err) {
      log('Error loading cache:', err);
      return {};
    }
  };

  // Save cache to localStorage
  const saveCache = () => {
    try {
      localStorage.setItem(CACHE_KEY, JSON.stringify(cache));
      log('Cache saved:', cache);
    } catch (err) {
      log('Error saving cache:', err);
    }
  };

  // Prepare request key
  const prepareRequestKey = (strArr, targetLn) =>
    `${targetLn}_${JSON.stringify(strArr.sort())}`; // Sort to ensure consistent keys

  // Manage cache size
  const manageCacheSize = () => {
    let currentSize = estimateSize(cache);
    const entries = Object.entries(cache);
    log('Initial cache size:', bytesToMB(currentSize));

    while (currentSize > MAX_CACHE_SIZE && entries.length) {
      const [oldestKey] = entries.shift();
      delete cache[oldestKey];
      currentSize = estimateSize(cache);
      log('Removed oldest cache entry:', oldestKey, 'New size:', bytesToMB(currentSize));
    }
  };

  const prepareStrArrData = (strArr) =>
    strArr.map((str) => ({ message: str, source: 'en' }));

  const translateArrOfStr = async (strArr, targetLn, abortController) => {
    const requestKey = prepareRequestKey(strArr, targetLn);
    log('Request key:', requestKey);

    // Check cache
    if (cache[requestKey]) {
      let currentSize = estimateSize(cache);
      log('Current cache size in bytes:', currentSize);
      log('Serving from cache for key:', requestKey);
      return cache[requestKey];
    }

    // Perform translation
    log('Performing translation for:', { strArr, targetLn });
    const translation = await LanguageUtils.translateMany(
      prepareStrArrData(strArr),
      targetLn,
      abortController
    );

    // Save to cache
    cache[requestKey] = translation;
    manageCacheSize();
    log('Added to cache:', { key: requestKey, translation });

    return translation;
  };

  // Load cache at function declaration
  cache = loadCache();

  // Save cache when the page closes
  window.addEventListener('beforeunload', saveCache);

  return {
    translateArrOfStr
  };
})();

// msg here is strigified JSON string
const parseMessageRenderData = (msg: string): ParsedMsgRenderData & ErrorStatus => {
  try {
    const parsed = JSON.parse(decodeURIComponent(msg)) as ParsedMsgRenderData;
    // save to init store, mainly used for lang translations
    initMessageStateBuffer.set(parsed);
    console.log("Initial parsed!", parsed)
    return parsed;
  } catch (err) {
    return { title: 'Some error occured on our end. Please bear with us for a moment.', options: [], error: true };
  }
};

// Used for saving the initial state for the message (mainly for translation logic part)
const initMessageStateBuffer = (function () {
  let _data: ParsedMsgRenderData | null = null;

  const set = (data: ParsedMsgRenderData) => {
    _data = data;
  };

  const get = () => _data;

  return {
    get,
    set,
  };
})();

const OnMountMessageWrapper = (props) => {
  return (
    <Grow in={true} timeout={props.firstMount === true ? 1000 : 0}>
      {props.children}
    </Grow>
  );
};

const getFirstMountValueFromStorage = () =>
  _localStorageTools.getFromLocalStorage(_localStorageTools.KEYS.IS_FIRST_MOUNT_STORAGE_KEY);

const isLastMsg = (_lastId, _currId) => {
  if(ValidatorFunctions.isNotUndefinedNorNull(_lastId) && ValidatorFunctions.isNotUndefinedNorNull(_currId)) {
    return _lastId === _currId;
  } else {
    return false;
  }
};

const getAllMountedInitValue = (isCurrMsgLastInChat, isFirstMountValue, isOperator) => {
  if (isOperator) {
    return true;
  }

  if (isCurrMsgLastInChat === true) {
    if (isFirstMountValue === null) {
      return false;
    }

    const isFirstMount = _localStorageTools.strBoolean(isFirstMountValue);
    if (isFirstMount) {
      // if its the first mount
      // return false (allMounted is set to false) as it is not mounted yet
      return false;
    } else {
      // if not first mount (was mounted before)
      // return true (meaning allMounted will be set to true) and no animation played
      return true;
    }
  } else {
    // if not the last message in chat
    return true; // meaning allMounted will be set to true (no animation played)
  }
};

const getIsFirstMountValue = (isCurrMsgLastInChat, isFirstMountValue, isOperator) => {
  if (isOperator) {
    return false;
  }
  // return isCurrMsgLastInChat === true ? (isFirstMountValue === null) ? true : (_localStorageTools.strBoolean(isFirstMountValue)) : false
  if (isCurrMsgLastInChat === true) {
    if (isFirstMountValue === null) {
      return true; // if no value available in storage, return true, meaning it is a first mount
    }

    const isFirstMount = _localStorageTools.strBoolean(isFirstMountValue);
    if (isFirstMount) return true; // if its a first mount (true), return true
    else return false; // if false, meaning it was mounted before, return false
  } else {
    return false; // if its not the last msg in chat, return false (meaning it is not the first mount)
  }
};

export {
  extractCustomInstruction,
  parseMessageRenderData,
  initMessageStateBuffer,
  ParsedMsgRenderData,
  ErrorStatus,
  getFirstMountValueFromStorage,
  isLastMsg,
  getAllMountedInitValue,
  getIsFirstMountValue,
  OnMountMessageWrapper,
  slideInAnimation,
  ExpandAnimationBox,
  SlideInAnimationBox,
  translationTools
};