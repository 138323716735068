import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from "@common/deviceTypeHelper";
import Sanitized from "@components/Sanitized";
import { useRootContext } from "@contexts/RootContext";
import { IErrand } from "@interfaces/Conversation";
import { Styles } from "./SubContentStyles";
import { TSubContent } from "../types";
import { shouldPreventPlaySlotMachine } from "@common/SlotMachineUtils";
import { useTranslation } from 'react-i18next';
import { LocalStateUpdatersContext } from './SubContent';
import { translationTools } from '@components/MessageContent/WelcomeUserMessageContent/utils';

interface IActionButtonProps {
    wasHidden: boolean,
    buttonHovered: boolean,
    isRevealed: boolean,
    hide: () => void,
    replayGif: boolean,
    subContent: TSubContent,
    errand: IErrand,
    btnUIData: {
        userCanPlay: boolean;
    } | {
        userCanPlay?: undefined;
    },
    initialGifPlayed: boolean;
}

interface IDefaultActionButton {
    getSubTypeBtnHoveredStyle: () => any;
    onButtonClick: (e: any) => Promise<void>;
    onButtonMouseEnter: (e: any) => void;
    onButtonMouseLeave: (e: any) => void;
    subContent: TSubContent;
}
const DefaultActionButton = (props: IDefaultActionButton) => {
    return (
        <div
            style={{ ...Styles.button, ...props.getSubTypeBtnHoveredStyle() }}
            onClick={props.onButtonClick}
            onMouseEnter={props.onButtonMouseEnter}
            onMouseLeave={props.onButtonMouseLeave}
        >
            <span style={Styles.buttonSpan}>
                <Sanitized html={props.subContent.buttonText} />
            </span>
        </div>
    );
}

function hoursLeftForDayToPass(dateString) {
    const inputDate = new Date(dateString);
    // @ts-ignore
    if (isNaN(inputDate)) {
        throw new Error("Invalid date string");
    }
    // Calculate the time 24 hours after the input date
    const oneDayLater = new Date(inputDate.getTime() + 24 * 60 * 60 * 1000);
    // Get the current time
    const now = new Date();
    // Calculate the time difference from now until 24 hours after the input date
    // @ts-ignore
    const timeDifference = oneDayLater - now;
    if (timeDifference < 0) {
        return 0; // If the time has passed, return 0 hours left
    }
    const hoursLeft = Math.floor(timeDifference / (1000 * 60 * 60)); // Full hours only
    return hoursLeft;
}

const GET_INIT_NON_PLAYABLE_TEXT = () => `TIMER ${hoursLeftForDayToPass(localStorage.getItem('userPlayedTimestamp'))} HOURS`;
const NonPlayableSlotMachineButton = (props) => {
    const [txt, setTxt] = useState('')
    const { i18n } = useTranslation();

    useEffect(() => {
        setTxt(GET_INIT_NON_PLAYABLE_TEXT());
    }, [])

    useEffect(() => {
        if (i18n.language !== 'en') {
            const _abortController = new AbortController();
            (async () => {
                try {
                    const translationResArr = await translationTools.translateArrOfStr([GET_INIT_NON_PLAYABLE_TEXT()], i18n.language, _abortController);
                    setTxt(translationResArr[0]);
                } catch (err) {
                    console.error('ActionButton.tsx, error occured during button timer text translation in user already played a game edge case!', err);
                }
            })()
            return () => _abortController.abort();
        } else {
            setTxt(GET_INIT_NON_PLAYABLE_TEXT());
        }
    }, [i18n.language])


    return (
        <div
            style={{ ...Styles.button, ...props.getSubTypeBtnHoveredStyle() }}
        >
            <span>
                {txt}
            </span>
        </div>
    );
}

// Handlers object for each of subType of subContent.
const Handlers = {
    slotMachine: async (e, slotMachineData, triggerSlotMachine, errand, updateUIData) => {
        const { action, userAction } = slotMachineData;
        e?.preventDefault();
        try {
            // We first check to make sure the Slot Machine useraction is not resolved,
            // so the user cannot play more than once per message sent by operator
            // It is important to note we are not checking hasPlayedToday and returning, but hitting Core, because if they have played, we must have Core send the message into the chat.
            const timeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'America/Los_Angeles';
            let { preventPlay } = await shouldPreventPlaySlotMachine(errand, action, timeZone);

            if (!preventPlay) {
                triggerSlotMachine(errand._id, userAction);
            } else {
                updateUIData({ button: { userCanPlay: false } });
                return;
            }
        } catch (e) {
            console.error(`Error checking for played Slot Machine games: ${e}`);
        }
    }
}

const ActionButton = (props: IActionButtonProps) => {

    const {
        updateReplayGif,
        updateGlareOpacity,
        updateForceEnlargeGif,
        updateButtonHovered,
        updateUIData,
    } = useContext(LocalStateUpdatersContext);

    const rootContext = useRootContext();

    const getSubTypeBtnHoveredStyle = () => {
        const fetchedStyle = Styles?.subtypeButton?.hovered?.[props.subContent.subType];
        if (props.wasHidden) {
            if (props.subContent.subType === 'slotMachine') return {};
        }
        return props.buttonHovered ? fetchedStyle ?? {} : {};
    }

    const onButtonClick = async (e) => {
        if (!props.isRevealed) return;
        // slot machine handler
        if (props.subContent.subType === 'slotMachine') {
            props.hide(); // hide immidiately
            // call in some time
            setTimeout(() => {
                Handlers.slotMachine(e, props?.subContent?.slotMachineData, rootContext.triggerSlotMachine, props.errand, updateUIData);
            }, 700);
        }
    }

    const onButtonMouseEnter = (e) => {
        e?.preventDefault();
        if (!props.isRevealed) return;
        if (isMobile() === true) return;

        if (props.subContent.subType === 'slotMachine') {
            if (!props.wasHidden && props.initialGifPlayed === true) {
                updateForceEnlargeGif(true);
            }
            if (
                props.replayGif === false
                && props?.btnUIData?.userCanPlay === true
                && props?.wasHidden === false
                && props?.initialGifPlayed === true
            ) {
                updateReplayGif(true);
            }
            updateGlareOpacity(0);
        }

        updateButtonHovered(true);
    }

    const onButtonMouseLeave = (e) => {
        e?.preventDefault();
        if (!props.isRevealed) return;
        if (isMobile() === true) return;

        if (props.subContent.subType === 'slotMachine') {
            if (!props.wasHidden) {
                updateForceEnlargeGif(false);
            }
            updateGlareOpacity(1);
        }
        updateButtonHovered(false);
    }

    if (props.subContent.subType === 'slotMachine') {
        if (props?.btnUIData?.userCanPlay === false) {
            return (
                <NonPlayableSlotMachineButton getSubTypeBtnHoveredStyle={getSubTypeBtnHoveredStyle} />
            );
        }
    }

    return (
        <DefaultActionButton
            getSubTypeBtnHoveredStyle={getSubTypeBtnHoveredStyle}
            onButtonClick={onButtonClick}
            onButtonMouseEnter={onButtonMouseEnter}
            onButtonMouseLeave={onButtonMouseLeave}
            subContent={props.subContent}
        />
    );
}


export {
    ActionButton
}