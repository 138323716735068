import React, { createContext, Dispatch, MutableRefObject, RefObject, SetStateAction, useContext } from 'react';
import { ElectronicSignatureEventType, FormBodyType, EsignSignatureType, EsignActionStateType, PaymentActionStateType, FormBodySizeType, FormBottomButtonType } from '../Forms/commonForms';
import { MorphType, morphIndentType } from '@common/MorphType';
import type YouTubePlayer from 'react-player/youtube';
import { IErrand, IPrompt, IDate } from '@interfaces/Conversation';
import { LoanProduct } from '@mTypes/TChooseLoanProducts';
import { TVideoListMenuState } from '@components/MorphVideoListMenu/types';
import { TUserPromptsMenuState } from '@components/MorphUserPromptsMenu/types';
import { TMultipleFooterTypingAnimationsState } from '@components/FooterTypingAnimation/types';
import { TWelcomeUserMessageState } from '@components/MessageContent/WelcomeUserMessageContent/WelcomeUserMessageContent';
import { AngelSignData } from '@mTypes/AngelSign';


/**
 * This context will store data that unique to each errand
 *
 * This includes elements related to the following
 * editMessageId, inputRef, conversationRef, etc.
 * 
 * NOTICE: Avoid adding useState variables when possible
 */

export interface IErrandContext {
  bodyRef?: MutableRefObject<null | HTMLDivElement>; // div wrapping messages for this errand
  boundaryRef?: MutableRefObject<null | HTMLDivElement>; // div wrapping this errand
  footerInputRef?: MutableRefObject<null | HTMLInputElement>; // chatInput ref for focusing
  footerRef?: MutableRefObject<null | HTMLDivElement>; // div wrapping the footer for this errand
  titleContainerRef?: MutableRefObject<null | HTMLDivElement>;
  dynamicConversationWrapperRef?: MutableRefObject<HTMLDivElement> | null;
  searchRefs?: MutableRefObject<{ _id: string, htmlElement: HTMLDivElement }[]>; // array of messages included in search results
  showAddFriendTooltip?: boolean; // boolean to show add friend tooltip for thin client
  setShowAddFriendTooltip?: (value: boolean) => void; // change to show or hide add friend tooltip for thin client
  createFormHandler: (index: number) => void;
  morphIndent: MutableRefObject<null | HTMLDivElement>; // div with class bottomBox in MorphedConversationFooter
  editMessageId?: string;
  setEditMessageId?: Dispatch<SetStateAction<string>>;
  mainPhoto?: number; //MorphPhoto menu: for the photo selector, keeps track of the main photo to send.
  photoSelectorIndex?: number; //MorphPhoto menu: for the photo selector, keeps track of the main photo to send.
  setMainPhoto?: (value: number | null) => void;
  setPhotoSelectorIndex?: (value: number | null) => void;
  photoLimit?: number;
  setPhotoLimit?: (value: number | null) => void;

  isUserWatchingLive: boolean | null; // used for ActivityTracker Live component to show up when user joins live watch.
  setIsUserWatchingLive: Dispatch<SetStateAction<boolean>> | null; // used for ActivityTracker Live component to show up when user joins live watch.

  errand: IErrand;
  errandId: string;
  errandType: string;

  canvasBoundaryRef: any;
  signatureTypeRef: MutableRefObject<EsignSignatureType>;
  esignActionState: EsignActionStateType;
  setEsignActionState: any;
  paymentActionState: PaymentActionStateType;
  setPaymentActionState: any;
  formCurrentPage: number;
  setFormCurrentPage: any;
  formTotalPages: number;
  setFormTotalPages: any;
  formBody: FormBodyType;
  setFormBody: (value: FormBodyType) => void;
  formBodySize: FormBodySizeType;
  formBottomButton: FormBottomButtonType;
  morphType: MorphType;
  setMorphType: any;
  newFormEvent: (event: ElectronicSignatureEventType) => void;
  mobileIndentType: morphIndentType;
  setMobileIndentType: (value: morphIndentType) => void;
  wetSignature: string;
  setWetSignature: (value: string) => void;
  wetInitial: string;
  setWetInitial: (value: string) => void;
  formBodyScrollState: any;
  setFormBodyScrollState: any;
  getPrimaryParticipantFullName: any;
  rotationLockedRef?: MutableRefObject<boolean>;
  isAnalyzing?: boolean;
  setIsAnalyzing?: (value: boolean) => void;
  isMorganTyping?: boolean;
  setIsMorganTyping?: (value: boolean) => void;
  showSentiment?: boolean;
  setShowSentiment?: (value: boolean) => void;
  messageOptionsRef: MutableRefObject<HTMLDivElement | null>;
  messageOptionsIndex?: number; // the index of the message for which we need to display options
  setMessageOptionsIndex?: Dispatch<SetStateAction<number>>; // change the user selected message index
  isMorphedFooterCloseButtonOnLeft?: Boolean; // whether the close button is rendered on left or right
  setIsMorphedFooterCloseButtonOnLeft?: Dispatch<SetStateAction<boolean>>; // change the user selected message index
  songPlayerData?: { id: string; artist: string; title: string; thumbnail: string; url: string; pause: boolean; mute: boolean; show: boolean; closing: boolean; };
  setSongPlayerData?: Dispatch<SetStateAction<{ id: string; artist: string; title: string; thumbnail: string; url: string; pause: boolean; mute: boolean; show: boolean; closing: boolean; }>>;
  playerRef?: RefObject<YouTubePlayer>;
  replyToRef: MutableRefObject<{ chat: string, originalMessage: string, originalText: string }>;
  comparedLoanProducts: Array<LoanProduct>;
  setComparedLoanProducts: Dispatch<SetStateAction<Array<LoanProduct>>>;
  userPromptsMenuState: TUserPromptsMenuState;
  setUserPromptsMenuState: Dispatch<SetStateAction<TUserPromptsMenuState>>;
  morphUserPromptsMenuRef: MutableRefObject<{getWfIdName: () => string}> | null;
  videoListMenuState: TVideoListMenuState;
  setVideoListMenuState: Dispatch<SetStateAction<TVideoListMenuState>>;
  isSearchOpen: boolean;
  setIsPopupOpen: Dispatch<SetStateAction<boolean>>;
  isPopupOpen: boolean;
  clickedConsentIntro: boolean;
  setClickedConsentIntro: Dispatch<SetStateAction<boolean>>;
  setValue: Dispatch<SetStateAction<string>>;
  multipleFooterTypingAnimations: TMultipleFooterTypingAnimationsState;
  setMultipleFooterTypingAnimations: Dispatch<SetStateAction<TMultipleFooterTypingAnimationsState>>;
  welcomeUserMessageState: TWelcomeUserMessageState;
  setWelcomeUserMessageState: Dispatch<SetStateAction<TWelcomeUserMessageState>>;
  bypassToMintFlag: boolean;
  setBypassToMintFlag: Dispatch<SetStateAction<boolean>>;
  nftData: Record<string, any> | null;
  setNftData: Dispatch<SetStateAction<Record<string, any> | null>>;
  messageFilter: string;
  setMessageFilter: (value: string) => void;
  triggeredSlotMachine: boolean;
  setTriggeredSlotMachine: Dispatch<SetStateAction<boolean>>;
  subContentPartExternalState: TSubContentPartExternalState;
  setSubContentPartExternalState: Dispatch<SetStateAction<TSubContentPartExternalState>>;
  angelSignData: AngelSignData;
  setAngelSignData: Dispatch<SetStateAction<AngelSignData>>;
}

type TSubContentPartExternalState = {openSignal: {eTarget: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement> | null, messageId: string | null}};

export const ErrandContext = createContext<IErrandContext | null>(null);

export const useErrandContext = () => useContext<IErrandContext>(ErrandContext);
