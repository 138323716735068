import React from 'react';
import Styles from '@styles/MessageContent/SlotMachineAfterWinAnimation.module.css';
import { useRootContext } from '@contexts/RootContext';

const Truck = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/Truck.svg';
const PrizeFireworks = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/PrizeFireworks.svg';

const Cap = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/Cap.svg';
const TShirtPolo = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/TShirtPolo.svg';
const TShirt = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/TShirt.svg';
const GiftBox = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/GiftBox.svg';
const Coffee = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/Coffee.svg';
const DiwaliTickets = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/DiwaliTickets.svg';
const MindsetTickets = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/MindsetTickets.svg';
const CoffeeMug = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/CoffeeMug.svg';
const WaterBottle = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/WaterBottle.svg';

type SlotMachineAfterWinAnimationProps = {
  prizeName: string;
};

const SlotMachineAfterWinAnimation = ({ prizeName }: SlotMachineAfterWinAnimationProps) => {
  const { showSlotMachine } = useRootContext();

  const getPrizeIconSrc = (prize: string) => {
    switch (prize) {
      case 'Coffee':
        return Coffee;
      case 'Gift Box':
        return GiftBox;
      case 'T-Shirt':
        return TShirt;
      case 'Polo Shirt':
        return TShirtPolo;
      case 'Cap':
        return Cap;
      case 'Diwali Party ticket':
        return DiwaliTickets;
      case 'Mindset Summit ticket':
        return MindsetTickets;
      case 'Russell Peters T-Shirt':
        return TShirt;
      case 'Coffee Mug':
        return CoffeeMug;
      case 'Water Bottle':
        return WaterBottle;
    }
  };

  return (
    <div className={Styles.rootContainer}>
      <div className={Styles.topCircle}></div>
      <img src={Truck} className={`${Styles.truck} ${!showSlotMachine && Styles.truckAnimation}`} draggable={false} />
      <img
        src={getPrizeIconSrc(prizeName)}
        className={`${Styles.prize} ${!showSlotMachine && Styles.prizeAnimation}`}
        draggable={false}
      />
      <img
        src={PrizeFireworks}
        className={`${Styles.fireworks} ${!showSlotMachine && Styles.fireworksAnimation}`}
        draggable={false}
      />
    </div>
  );
};

export default SlotMachineAfterWinAnimation;
