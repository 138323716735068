import React, { PropsWithChildren, useCallback, useRef, useState, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useRootContext } from '@contexts/RootContext';
import { useSwipeable } from 'react-swipeable';

import Errand from '@components/Errand';
import ErrandsTitle from '@components/ErrandsTitle';
import ErrandsFooter from '@components/ErrandsFooter';
import { MorphType } from '@common/MorphType';
import { IErrand } from '@interfaces/Conversation';
import ErrorBoundary from '@components/ErrorBoundary';
import UserPromptList from './UserPromptList';
import Styles from '@styles/ErrandsContainer.module.css';
import useWindowDimensions from '@common/hooks/useWindowDimensions';
import { useUserContext } from '@contexts/user';
import { useTranslation } from 'react-i18next';
import { ValidatorFunctions } from '@common/Validators';
import { ChatFetchCheckIfAlreadyLoaded } from '../../src/Pages/Conversation/ChatFetchStateManager';

//render the Droppable elements after an animation frame, strictMode causes Droppable to malfunction otherwise.
const StrictModeDroppable = (props) => {
  const { children, ...rest } = props;
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...rest}>{children}</Droppable>;
};

/*
 *  This component renders the errands box on the right side of the page
 *
 *  This component has the following properties:
 *    - author - The name of the person that composed the message
 *    - time - Time the message was sent/recieved
 *    - message - The actual content of the message
 *    - isOperator - A boolean value telling us if the author was an operator or not ,
 *     this will decide if the message is rendered on the left or right of the screen and
 *     in the appropriate
 */

const ErrandsContainer: React.FC<PropsWithChildren<any>> = (props) => {
  const { t } = useTranslation();
  const { activeErrandCount, tpConsentGiven } = useUserContext();
  const {
    drawerRef,
    chatFetchDataRef,
    errands,
    setRootMorphType,
    setMessagesAreLoading,
    syncErrandsSequence,
    loadMoreErrands,
    selectedIndex,
    setSelectedIndex,
    setErrands,
    returnConsentGiven
  } = useRootContext();

  const endOfErrands = useRef(null);
  const startOfErrands = useRef(null);
  const droppableRef = useRef(null);

  const [, setDraggedErrand] = useState(null);
  const { isDesktop } = useWindowDimensions();

  const [showUserPromptList, setShowUserPromptList] = useState(false);
  const [checkedErrands, setCheckedErrands] = useState<IErrand[]>([]);

  const handleSelectErrand = useCallback((index) => {
    if (index < 0) {
      console.info('Unable to select errand, index is invalid');
      return;
    }

    if (!tpConsentGiven || !returnConsentGiven) return;

    // hide the errands drawer on mobile devices. On desktop it is always visible
    drawerRef.current?.click();

    // close new errand morphed footer if it is open
    setRootMorphType(MorphType.None);
    setMessagesAreLoading(true);
    setSelectedIndex((prev) => {
      if (!Array.isArray(prev)) return [index];
      prev[prev.length - 1] = index;
      return [...prev];
    });
  }, [setSelectedIndex, drawerRef, setMessagesAreLoading, setRootMorphType, tpConsentGiven, returnConsentGiven]);

  const toggleCheckedErrands = useCallback((errand, index, currentParticipant) => {
    if (!errand._id) return;
    if (errand.isDefault) return handleSelectErrand(index);
    if (!tpConsentGiven || !returnConsentGiven) return;

    setCheckedErrands((prev) => {
      const index = prev.findIndex((e) => e._id === errand._id);
      if (index !== -1) {
        return [...prev.slice(0, index), ...prev.slice(index + 1)];
      }
      return [...prev, { ...errand, currentParticipantIsPrimary: currentParticipant.primary, currentParticipantId: currentParticipant._id }];
    });
  }, [handleSelectErrand, tpConsentGiven, returnConsentGiven]);

  const handlers = useSwipeable({
    onSwipedRight: () => {
      // hide the errands drawer on mobile devices. On desktop it is always visible
      drawerRef.current?.click();
    }
  })

  const handleOnDragEnd = useCallback(
    async (result, errandsList: IErrand[]) => {
      try {
        if (!result.destination) {
          return;
        }

        const { source, destination } = result;

        if (source.index === destination.index) {
          return;
        }

        const movedErrand = errandsList[source.index];

        if (destination.index > source.index) {
          // errand moving up ---> shift errands down
          for (let i = source.index; i < destination.index; i++) {
            errandsList[i] = errandsList[i + 1];
          }
        } else {
          // errand moving down ---> shift errands up
          for (let i = source.index; i > destination.index; i--) {
            errandsList[i] = errandsList[i - 1];
          }
        }

        errandsList[destination.index] = movedErrand;

        // update positions according to current view
        errandsList.forEach((errand, i) => {
          errand.position = i;
        });

        setErrands(errandsList);

        if (selectedIndex.length === 1) {
          // single screen view
          if (selectedIndex[0] === source.index) {
            setSelectedIndex([destination.index]);
          } else if (selectedIndex[0] === destination.index) {
            setSelectedIndex([source.index]);
          } else {
            console.info('selectedIndex is not affected by the drag and drop');
          }
          if (
            (selectedIndex[0] < source.index && destination.index <= selectedIndex[0]) ||
            (selectedIndex[0] > source.index && destination.index >= selectedIndex[0])
          ) {
            setSelectedIndex([selectedIndex[0] + (destination.index > source.index ? -1 : 1)]);
          }
        } else if (selectedIndex.length === 2) {
          // split screen view
          const [firstIndex, secondIndex] = selectedIndex;
          let updatedSelectedIndexes = [firstIndex];

          if (secondIndex === source.index) {
            updatedSelectedIndexes.push(destination.index);
          } else if (secondIndex === destination.index) {
            updatedSelectedIndexes.push(source.index);
          } else {
            updatedSelectedIndexes.push(secondIndex);
          }

          if (
            (secondIndex < source.index && destination.index <= secondIndex) ||
            (secondIndex > source.index && destination.index >= secondIndex)
          ) {
            updatedSelectedIndexes = [firstIndex, secondIndex + (destination.index > source.index ? -1 : 1)];
          }

          setSelectedIndex(updatedSelectedIndexes);
        } else {
          console.error('selectedIndex has more than 2 elements!');
        }

        await syncErrandsSequence(errandsList.map((x) => x._id));
      } catch (err) {
        console.error('Something is wrong with handleOnDragEnd', err);
      }
    },
    [selectedIndex, setErrands, setSelectedIndex, syncErrandsSequence]
  );

  const handleDragStart = useCallback((start) => {
    const { draggableId } = start
    setDraggedErrand(draggableId)
  }, []);

  const checkIfIdMatches = useCallback((_id) => {
    if (errands[selectedIndex[0]] === undefined) {
      return false;
    }

    return errands[selectedIndex[0]]._id === _id;
  }, [errands, selectedIndex]);

  const handleScroll = useCallback(() => {
    const parameters = droppableRef.current;

    if (ValidatorFunctions.isUndefinedOrNull(parameters)) {
      return;
    }

    const scrollTop = parameters.scrollTop;
    const scrollHeight = parameters.scrollHeight;
    const { height } = parameters.getBoundingClientRect();

    const viewRatio = (scrollTop + height) / scrollHeight;

    if (viewRatio > 0.9) {
      loadMoreErrands();
    }
  }, [loadMoreErrands]);

  // Following useEffect used to check if fetched Data ocuppied area is less than scroll size
  useEffect(() => {
    handleScroll();

    setCheckedErrands((prev) => {
      const removeList = [];
      const data = chatFetchDataRef.current;

      // collect chats that doesn't exist on current list
      for (const elm of prev) {
        if (ChatFetchCheckIfAlreadyLoaded(data, elm._id) === false) {
          removeList.push(elm);
        }
      }

      // we detected difference between current errands list vs selectedErrands list
      if (removeList.length > 0) {
        return [...prev.filter((obj) => !removeList.includes(obj))];
      }

      // default routine, no re-render
      return prev;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errands]);

  return (
    <ErrorBoundary debug={`./src/Components/ErrandsContainer.tsx: level 0`}>
      <aside className={[Styles.aside, ...(isDesktop ? [Styles.isDesktop] : [])].join(' ')} {...handlers}>
        <UserPromptList
          setShowUserPromptList={setShowUserPromptList}
          showUserPromptList={showUserPromptList}
        />
        <ErrandsTitle />
        <DragDropContext onDragStart={handleDragStart} onDragEnd={(result) => handleOnDragEnd(result, errands)}>
          <StrictModeDroppable droppableId="errands-list">
            {(provided) => (
              <ul className={Styles.ul}
                ref={(node) => {
                  provided.innerRef(node);
                  droppableRef.current = node; // Set the droppableRef to the current node
                }}
                {...provided.droppableProps}
                onScroll={handleScroll}
              >
                <li>
                  <button
                    onClick={() => endOfErrands?.current?.focus()}
                    ref={startOfErrands}
                    id="startOfErrands"
                    aria-label="Skip errands list"
                    style={{
                      backgroundColor: 'var(--gray000)',
                      border: 'none',
                      height: '1px',
                      margin: 0,
                      outlineColor: 'var(--peach900)',
                      padding: 0,
                      width: '100%',
                      '&:hover': {
                        backgroundColor: 'var(--peach900)',
                      },
                    } as React.CSSProperties}
                  />
                </li>
                {errands.map(
                  (
                    errand: IErrand,
                    index: number
                  ) => {
                    if (errand.isDefault) {
                      return (
                        <li
                          className={[
                            Styles.li
                          ].join(' ')}
                        >
                          <ErrorBoundary key={index} debug={`./src/Components/ErrandsContainer.tsx: level 1`}>
                            <Errand
                              {...props}
                              isSelected={selectedIndex.includes(index)}
                              checkedErrands={checkedErrands}
                              toggleCheckedErrands={toggleCheckedErrands}
                              isMatch={checkIfIdMatches(errand._id)}
                              errand={errand}
                              dragHandleProps={{}}
                              isDragging={false}
                              handleSelectErrand={handleSelectErrand}
                              index={index}
                            />
                          </ErrorBoundary>
                        </li>
                      );
                    }
                    return (
                      <Draggable key={errand._id} draggableId={errand._id} index={index}>
                        {(provided, snapshot) => (
                          <li
                            className={[
                              Styles.li
                            ].join(' ')}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            {provided.placeholder}
                            <ErrorBoundary key={index} debug={`./src/Components/ErrandsContainer.tsx: level 2`}>
                              <Errand
                                {...props}
                                isSelected={selectedIndex.includes(index)}
                                checkedErrands={checkedErrands}
                                toggleCheckedErrands={toggleCheckedErrands}
                                isMatch={checkIfIdMatches(errand._id)}
                                errand={errand}
                                dragHandleProps={provided.dragHandleProps}
                                isDragging={snapshot.isDragging}
                                handleSelectErrand={handleSelectErrand}
                                index={index}
                              />
                            </ErrorBoundary>
                          </li>
                        )}
                      </Draggable>
                    )
                  }
                )}

                {provided.placeholder}
                <li>
                  <button
                    onClick={() => startOfErrands?.current?.focus()}
                    ref={endOfErrands}
                    id="endOfErrands"
                    aria-label="Back to start of errands list"
                    style={{
                      backgroundColor: 'var(--gray000)',
                      border: 'none',
                      height: '1px',
                      margin: 0,
                      outlineColor: 'var(--peach900)',
                      padding: 0,
                      width: '100%',
                      '&:hover': {
                        backgroundColor: 'var(--peach900)',
                      },
                    } as React.CSSProperties}
                  />
                </li>
                {activeErrandCount > 150 && (
                  <li className={Styles.errandNotice}>{t('errandNotice')}{activeErrandCount}</li>
                )}
              </ul>
            )}
          </StrictModeDroppable>
        </DragDropContext>
        <ErrandsFooter
          setShowUserPromptList={setShowUserPromptList}
          checkedErrands={checkedErrands}
          setCheckedErrands={setCheckedErrands}
        />
      </aside>
    </ErrorBoundary>
  );
};

export default ErrandsContainer;