import React, { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { getDropdownDescription, isNotOtpNorPassword } from '@common/StringUtils';
import { MorphType } from '@common/MorphType';
import { useErrandContext } from '@contexts/ErrandContext';
import { useRootContext } from '@contexts/RootContext';
import { useUserContext } from '@contexts/user';
import { ActionIcon } from "./ActionIcon";

import type { FC } from "react";
import type { IErrand, IMessage } from '@interfaces/Conversation';
import type { Option } from "@components/MorphSwiper/Types";

type ContentProps = {
  message: IMessage;
  errand: IErrand
  options: Option[]
}

const Content: FC<ContentProps> = ({ message, errand, options }) => {
  const { t } = useTranslation();
  const rootContext = useRootContext();
  const { isUser } = useUserContext();
  const errandContext = useErrandContext();

  const handleClickEdit = useCallback(() => {
    // set up editable message value
    let msg = message.message || '';
    // Remove replies
    if (msg.indexOf('</blockquote>') !== -1) {
      // Remove the old message.
      msg = msg.split(`</blockquote>`)[1];
    }
    // Remove previous edits
    if (msg.indexOf('<i class="messageArrow"/>') !== -1) {
      // Remove the old message.
      msg = msg.split(`<i class="messageArrow"/>`)[1];
    }
    // Remove user message from operator corrections
    if (msg.indexOf(t('acceptedData')) !== -1) {
      // Remove the user message
      msg = msg.split(t('acceptedData'))[1];
      // Remove the ) at the end of the message
      msg = msg.substring(0, msg.length - 1).trim();
    }
    // Remove html
    if (msg.indexOf('href=') !== -1) {
      msg = msg.replace(/<\/?a[^>]*>/g, '');
    }

    // Parse the field data to show the description by extracting it from the message. 
    msg = getDropdownDescription(msg);

    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(msg, 'text/html');

    msg = htmlDoc.body.textContent;

    // set states as needed
    rootContext.setErrands((prev) => {
      if (!Array.isArray(prev)) {
        console.warn('setErrands prev is not an array');
        prev = [];
      }
      let index = prev.findIndex((e) => e._id === message.chat);
      if (index === -1) return prev;
      if (message.messageType === 'Field') {
        prev[index].icon = message.icon;
        prev[index].placeholder = message?.action?.description;
        prev[index].action = {
          ...message?.userAction,
          action: {
            ...message?.action,
          },
          userActionId: message?.userAction?._id,
          active: true,
        };
        prev[index].recipients = message.intendedAudience ? [message.sender._id, ...message.intendedAudience].sort() : [];
      } else {
        prev[index].icon = '';
        prev[index].action = null;
      }
      if (message.intendedAudience) {
        prev[index].recipients = [message.sender._id, ...message.intendedAudience].sort();
      } else {
        prev[index].recipients = [];
      }
      return [...prev];
    });
    errandContext.setEditMessageId(message._id);
    if (isNotOtpNorPassword(message)) errandContext.setValue(msg);
    errandContext.footerInputRef.current?.focus();

    // set edit morph type logic
    errandContext.setMorphType(MorphType.Edit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return (
    <button 
      style={{ 
        minWidth: "12rem", 
        maxWidth: "29.3125rem", 
        cursor: "auto",
        ...( isUser ? { padding: "16px 0 0 10px" } : {}),
      }} 
      onClick={handleClickEdit}
    >
      <header 
        style={{
          padding: "4px",
          fontSize: "16px",
          fontWeight: 600,
          color: "var(--gray000)",
          backgroundColor: "var(--orange700)",
          border: "1px solid var(--orange700)",
          borderRadius: "8px 8px 0 0",
          textAlign: "center",
          position: "relative",
        }}
      >
        <Trans>Selected</Trans>
        <ActionIcon {...{ message, errand }} />
      </header>
      <ol 
        style={{
          padding: "4px 16px",
          backgroundColor: "var(--peach600)",
          border: "1px solid var(--orange700)",
          borderRadius: "0 0 8px 8px",
          listStyleType: "none",
        }}
      >
        { 
          options.slice(0, 7).map((option) => (
            <li 
              key={option.keyword}
              style={{
                fontSize: "12px",
                fontFamily: "Poppins",
                textAlign: "left",
                color: "var(--blue999)",
                padding: "4px 0",
                position: "relative",
              }}
            >
              <i
                style={{
                  width: "20px",
                  aspectRatio: "1/1",
                  fontSize: "12px",
                  fontWeight: "600",
                  textAlign: "center",
                  color: "var(--gray000)",
                  backgroundColor: "var(--green500)",
                  border: "2px solid var(--peach100)",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: "50%",
                  left: "-26px",
                  transform: "translateY(-50%)",
                }}
              />
              { option.description }
            </li>
          ))
        }
      </ol>
    </button>
  );
}

export { Content };
export type { ContentProps };
