const Styles = {
  MainContainer: (isFullyRevealed, isVerySmallDevice, isMobile, rightPos, isRevealed, fastAnimation = false) =>
    ({
      backgroundColor: 'var(--peach100)',
      position: 'absolute',
      height: 'calc(100% + 4px)',
      maxWidth: isMobile ? '160px' : '180px',
      zIndex: isVerySmallDevice && isFullyRevealed ? 2 : -1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      right: `${rightPos}px`,
      top: '-3px',
      borderRadius: '0px 10px 10px 0px',
      border: '1px solid var(--peach700)',
      transition: `all ${fastAnimation ? '0.5s' : '1.4s'} ease-in-out, z-index 0s`,
      ...(isRevealed === false ? { cursor: 'pointer' } : {}),
    } as React.CSSProperties),

  title: {
    textAlign: 'center',
    backgroundColor: 'var(--peach700)',
    borderRadius: '0px 8px 0px 0px',
    color: 'white',
  } as React.CSSProperties,

  titleSpan: (isMobile) => ({
    fontFamily: 'Poppins',
    fontWeight: 800,
    padding: isMobile ? '10px' : '6px',
    display: 'block',
    fontSize: '0.8rem'
  }) as React.CSSProperties,

  gifContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '8px',
  } as React.CSSProperties,

  description: (isMobile: boolean) => ({
    padding: '10px',
    paddingBottom: isMobile ? '15px' : '10px',
    textAlign: 'center',
    color: 'var(--peach700)',
    fontFamily: 'Poppins',
    fontSize: '0.8rem'
  }) as React.CSSProperties,

  button: {
    transition: 'all 0.3s ease',
    padding: '4px 20px',
    backgroundColor: 'rgb(238, 216, 201)',
    width: '101.5%',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: '0px 0px 10px 0px',
    border: '1px solid var(--peach700)',
    cursor: 'pointer',
    color: 'var(--peach500)'
  } as React.CSSProperties,

  buttonSpan: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '0.8rem'
  } as React.CSSProperties,

  overlay: (opacity) =>
    ({
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0.2)',
      opacity: opacity,
      pointerEvents: 'none',
      borderRadius: '0 10px 10px 0',
      filter: 'blur(3px)',
      transition: 'all 0.6s ease',
    } as React.CSSProperties),

    subtypeButton: {
      hovered: {
        slotMachine: {
          backgroundColor: 'var(--peach700)',
          color: 'white',
          boxShadow: '0px 0px 10px rgba(255, 0, 255, 0.5)'
        }
      }
    }
};

export { Styles };
