/**
 * Basic string transformation/manipulation functions
 * 
 * @author Dennis J. Borrero
 */


/**
 * Convert the first character of a string to upper case. 
 * 
 * @param {String} str input string
 * @returns capitalized string
 */
export const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Masks a string given a mask character
 * 
 * @param {String} value string to mask
 * @param {String} mask character to use while masking string
 * @returns masked string
 */
export const maskMessage = (value: string, mask: string | any[]) => {
    if (!value?.length || !mask?.length) return value;
    if (mask?.length === 1) { // usually password fields
      if (value.length > 12) value = value.substring(0, 12); //limit to 12 chars
      return value
        ?.split('')
        ?.map((_) => '•')
        ?.join('');
    }
    if (value?.length !== mask?.length) return value;
    const maskedValue = value?.split('')?.map((char, index) => {
      if (mask[index] !== '#') return mask[index];
      return char;
    });
    return maskedValue.join('');
};

// we will define string separator variable only once
export const qidSeparatorString: string = '<separator/>';

export const getDropdownDescription = (fieldValue: string): string => {
  if (typeof fieldValue !== "string") {
    return "";
  }

  // Extract the description for the dropdown selection from QID/endpoint values.
  const separatorFoundIndex = fieldValue.indexOf(qidSeparatorString);

  if (separatorFoundIndex !== -1) {
    // separator found parse left side for DESCRIPTION
    fieldValue = fieldValue.slice(0, separatorFoundIndex).trim();
  }

  return fieldValue;
};

export const getDropdownData = (fieldValue: string): string => {
  if (typeof fieldValue !== "string") {
    return "";
  }

  // Extract the data to be used from the QID or Endpoint
  const separatorFoundIndex = fieldValue.indexOf(qidSeparatorString);

  if (separatorFoundIndex !== -1) {
    // seperator found parse right side for DATA
    fieldValue = fieldValue.slice(separatorFoundIndex + qidSeparatorString.length - fieldValue.length).trim();
  }

  return fieldValue;
};

export const removeHtmlTags = (str) => str?.replace(/(<([^>]+)>)/gi, '');

export const isNotOtpNorPassword = (message) => {
  if (!message || !message.action || !message.action.fieldName) return false;

  const msgFieldName = message.action.fieldName.toLowerCase();
  const uneditableSubstrings = ['password', 'otp'];
  const matches = uneditableSubstrings.filter((substring) => (msgFieldName || '').indexOf(substring) !== -1);
  if (matches.length > 0) return false;

  // if we are here, then we did not find any OTP or PASSWORD in message field fieldname or err is present.
  return true;
};

export const containsOnlyDigits = (str: string): boolean => {
  return /^\d+$/.test(str); // returns true if only digits, otherwise false
};

// Function to properly format names
export const titleCase = (/** @type {string} */ string) => {
  return string.replace(
    /\w\S*/g,
    (str) => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()
  );
};