/**
 * @file NewPasswordInput.tsx
 * @description A component specifically for handling the user input for
 * New Password action fields
 * @author Eric Velepucha <eric.velepucha@swmc.com>
 */

import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@common/hooks/reduxHooks';
import { selectInputValue, updateInputValue } from '@slices/inputSlice';
import { attemptedPassword, resetAttemptedPassword, selectIsPasswordValid } from '@slices/passwordSlice';
import Styles from '@styles/NewPasswordInputStyles.module.css';
import useDebounce from '@common/hooks/useDebounce';
import { useFooterContext } from '@contexts/FooterContext';

type NewPasswordInputProps = {
  handleSubmitProp: () => void;
  showPassword: boolean;
};

const NewPasswordInput: React.FC<NewPasswordInputProps> = ({ handleSubmitProp, showPassword }) => {
  const dispatch = useAppDispatch();
  const inputValue = useAppSelector(selectInputValue);
  const isPasswordValid = useAppSelector(selectIsPasswordValid);
  const [showShakeAnimation, setShowShakeAnimation] = useState(false);
  const footerContext = useFooterContext();

  const handleChange = (e) => {
    dispatch(updateInputValue(e.target.value));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isPasswordValid) {
      dispatch(attemptedPassword());
      setShowShakeAnimation(true);
      setTimeout(() => {
        setShowShakeAnimation(false);
      }, 300);
      return;
    }
    handleSubmitProp();
  };

  useEffect(() => {
    return () => {
      dispatch(resetAttemptedPassword());
      dispatch(updateInputValue(''));
      footerContext.chatInputFieldRef.current?.update('');
    };
  }, [dispatch, footerContext.chatInputFieldRef]);
  
  // Will not cause a re-render of the ConvFooter component
  useDebounce(() => {
    if (!isPasswordValid){
      footerContext.chatInputFieldRef.current?.update('')
    } else {
      footerContext.chatInputFieldRef.current?.update(inputValue )
    }
  }, 400, [inputValue]);

  return (
    <input
      className={`${Styles.input} ${showShakeAnimation && Styles.shake}`}
      type={showPassword ? 'text' : 'password'}
      autoComplete="new-password"
      value={inputValue}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      autoFocus
    />
  );
};

export default NewPasswordInput;
