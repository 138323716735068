import MessageSender from '@components/MessageSender';
import { IErrand, IMessage } from '@interfaces/Conversation';
import React, { useCallback, useEffect, useState } from 'react';
import Styles from '@styles/MessageContent/SlotMachineMessageContent.module.css';
import { getUserPlayedLast24Hours } from '@storage/userStorage';
import { useRootContext } from '@contexts/RootContext';
import { PlayArrowRounded } from '@mui/icons-material';
import SlotMachineAfterWinAnimation from './SlotMachineAfterWinAnimation';
import { shouldPreventPlaySlotMachine } from '@common/SlotMachineUtils';
import { useErrandContext } from '@contexts/ErrandContext';
import { MorphType } from '@common/MorphType';
import { uiTranslationController } from '@common/common';

const RoundArrow = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/RoundArrow.svg';
const GoodLuckCursive = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/GoodLuckCursive.svg';
const TShirt = process.env.REACT_APP_MORGAN_CDN + '/Images/SlotMachine/TShirt.svg';

type TSlotMessageContentProps = {
  errand: IErrand;
  message: IMessage;
};

enum SlotMachineState {
  ReadyToPlay,
  AfterLoss,
  AfterWin,
}

const MiniSlotMachine = () => {
  return (
    <>
      <div className={Styles.topCircle}>
        <div className={Styles.topCircleDot}></div>
      </div>
      <div className={Styles.bottomCircle}></div>
      <div className={Styles.miniSlotMachineContainer}>
        <div className={Styles.slotsContainer}>
          <div className={Styles.slot}>
            <img src={TShirt} draggable={false} />
          </div>
          <div className={Styles.slot}>
            <img src={TShirt} draggable={false} />
          </div>
          <div className={Styles.slot}>
            <img src={TShirt} draggable={false} />
          </div>
        </div>
        <button className={Styles.playButton}>
          <PlayArrowRounded />
        </button>
      </div>
    </>
  );
};

type CountdownTimerProps = {
  timeLeft: string;
  timeUnit: string;
};

const CountdownTimer = ({ timeLeft, timeUnit }: CountdownTimerProps) => {
  return (
    <div className={Styles.timerContainer}>
      <div className={Styles.timerTopCircle}>
        <img src={RoundArrow} draggable={false} />
        <div className={Styles.timer}>
          <p>Timer</p>
          <h3>{timeLeft}</h3>
          <p>{timeUnit}</p>
        </div>
      </div>
      <img src={GoodLuckCursive} draggable={false} />
    </div>
  );
};

const SlotMachineMessageContent = ({ errand, message }: TSlotMessageContentProps) => {
  const { dispMessage } = uiTranslationController(message);
  const [timeLeft, setTimeLeft] = useState('');
  const [timeUnit, setTimeUnit] = useState('');

  // Check message for prize name
  const [msg, prize] = message?.message?.split('<>');

  // If prize is not null nor undefined, then the action is resolved
  const isActionResolved = !!prize;

  // If prize name is not 'No Prize' then the user has won a prize
  const isPrizeWon = isActionResolved && prize !== 'No Prize';

  // Choose the state
  let initialState = SlotMachineState.ReadyToPlay;
  if (!isActionResolved) {
    initialState = SlotMachineState.ReadyToPlay;
  }
  if (isActionResolved && isPrizeWon) {
    initialState = SlotMachineState.AfterWin;
  }
  if (isActionResolved && !isPrizeWon) {
    initialState = SlotMachineState.AfterLoss;
  }

  const [slotMachineState, setSlotMachineState] = useState(initialState);
  const rootContext = useRootContext();
  const errandContext = useErrandContext();

  const handleClickBubble = async () => {
    if (!rootContext.returnConsentGiven){
      rootContext.handleShakingConsent();
      return;
    }
    errandContext.setTriggeredSlotMachine(true);
    try {
      const timeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'America/Los_Angeles';
      let { preventPlay } = await shouldPreventPlaySlotMachine(errand, message?.action, timeZone);

      if (!preventPlay) {
        rootContext.triggerSlotMachine(errand._id, message.userAction?._id);
      } else {
        return;
      }
    } catch (e) {
      console.error(`Error checking for played Slot Machine games: ${e}`);
    }

    // if (!getUserPlayedLast24Hours()) {
    //   rootContext.triggerSlotMachine(errand._id, message.userAction?._id);
    // }
  };

  const calculateTimeLeft = useCallback(() => {
    const now = new Date().getTime();
    const messageTime = new Date(message.updatedAt).getTime();
    const twentyFourHoursLater = messageTime + 24 * 60 * 60 * 1000;
    const difference = twentyFourHoursLater - now;
    if (difference <= 0) {
      return 0;
    }
    return difference;
  }, [message.updatedAt]);

  useEffect(() => {
    // Update the state if the message updated
    if (!isActionResolved) {
      setSlotMachineState(SlotMachineState.ReadyToPlay);
    }
    if (isActionResolved && isPrizeWon) {
      setSlotMachineState(SlotMachineState.AfterWin);
    }
    if (isActionResolved && !isPrizeWon) {
      setSlotMachineState(SlotMachineState.AfterLoss);
    }
  }, [message]);

  useEffect(() => {
    // Set initial time left
    const timeLeft = calculateTimeLeft();
    const timeUnit = timeLeft > 3600000 ? 'hours' : 'mins';
    // Convert the time difference to the appropriate unit
    const convertedTime =
      timeUnit === 'hours'
        ? timeLeft / (1000 * 60 * 60) // Convert to hours
        : timeLeft / (1000 * 60); // Convert to minutes

    setTimeLeft(`${convertedTime.toFixed(0)}`);
    setTimeUnit(timeUnit);

    // Update every 10s
    // only set interval if user played and got no prize
    if (getUserPlayedLast24Hours() && (!prize || prize === 'No Prize')) {
      const interval = setInterval(() => {
        const timeLeft = calculateTimeLeft();
        const timeUnit = timeLeft > 3600000 ? 'hours' : 'mins';
        setTimeLeft(`${convertedTime.toFixed(0)}`);
        setTimeUnit(timeUnit);
      }, 10000);

      // Cleanup interval on component unmount
      return () => clearInterval(interval);
    }
  }, [calculateTimeLeft, message.updatedAt]);

  useEffect(() => {
    if (errandContext.morphType === MorphType.SlotMachine && getUserPlayedLast24Hours()) {
      errandContext.setMorphType(MorphType.None);
    }
  }, []);

  return (
    <div className={Styles.rootContainer}>
      <MessageSender errand={errand} message={message} isEditing={false} />
      <div className={Styles.bubbleContainer} onClick={handleClickBubble}>
        <div className={Styles.left}>
          <p>{dispMessage.split('<>')[0]}</p>
        </div>
        <div className={Styles.right}>
          {slotMachineState === SlotMachineState.ReadyToPlay && <MiniSlotMachine />}
          {slotMachineState === SlotMachineState.AfterLoss && (
            <CountdownTimer timeLeft={timeLeft} timeUnit={timeUnit} />
          )}
          {slotMachineState === SlotMachineState.AfterWin && <SlotMachineAfterWinAnimation prizeName={prize} />}
        </div>
      </div>
    </div>
  );
};

export default SlotMachineMessageContent;
