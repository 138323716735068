import React from "react";
import { useUserContext } from '@contexts/user';
import Style from "../Styles.module.css";

const Loader = () => {
  const { isOperator } = useUserContext();
  
  return (
    <div className={Style.pulse}>
      <header 
        style={{ height: "3rem", width: "20rem", background: "var(--orange700)", borderRadius: "8px 8px 0 0", position: "relative" }}
      >
        <div 
          style={{
            width: "36px",
            aspectRatio: "1/1",
            background: "var(--orange700)",
            border: "2px solid var(--peach100)",
            borderRadius: "50%",
            position: "absolute",
            top: 0,
            ...(isOperator
              ? { right: 0, transform: "translate(40%, -40%)" }
              : { left: 0, transform: "translate(-40%, -40%)" }
            )
          }}
        />
      </header>
      <ul
        style={{
          height: "8rem",
          padding: "8px",
          backgroundColor: "var(--peach600)",
          border: "1px solid var(--orange700)",
          borderRadius: "0 0 8px 8px", 
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          listStyleType: "none",
        }}
      />
    </div>
  );
}

export { Loader }