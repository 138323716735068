import LanguageUtils from '@common/LanguageUtils';

import type { Option } from "@components/MorphSwiper/Types";

const translateOptions = async (options: Option[]) => {
  try {
    const language = LanguageUtils.fetchLocalizationLanguageSetting() || 'en';
    if (language === 'en') return options;

    return await Promise.all(options.map(async (option) => ({
       ...option, 
       description: await LanguageUtils.translateOne(option.description, language) 
    })));
  } catch (error) {
    console.error(`Could not translate prompts: `, options, error);
    return options;
  }
}

export { translateOptions };
