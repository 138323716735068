import React, { ReactNode, memo, useMemo } from 'react';

import MessageDate from './MessageDate';
import MessageSender from './MessageSender';
import eventBus from '@common/eventBus.js';
import { IErrand, IMessage } from '@interfaces/Conversation';
import { useErrandContext } from '@contexts/ErrandContext';
import { useRootContext } from '@contexts/RootContext';
import SentimentEmoji from '@components/SentimentEmoji';
import { Sentiments } from '@mTypes/TSentiment';
import { MorphType } from '@common/MorphType';
import Styles from '@styles/MessageContentWrapperStyles.module.css';
import { useTranslation } from 'react-i18next';
import MessageReply from '@components/MessageReply/MessageReply';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { Tooltip } from '@mui/material';
import { ResetFooterUserAction } from '@common/common';
import { useUserContext } from '@contexts/user';


// hide message sender at this level for these message types.
const UNWRAPPED_MESSAGE_TYPES = ['Disclaimer'];
const CHILDREN_ONLY_MESSAGE_TYPES = ['Notification', 'Video'];
const UNKNOWN_SENDER_MESSAGE_TYPES = ['Errand', 'Referrer', 'Notification', 'Invite', 'HelpTip', 'HelpTipGroup'];
const INCOMPATIBLE_MESSAGE_TYPES = ['Action', 'Errand', 'Invite', 'Notification', 'SignatureConfirmation', 'Video', 'PermissionRejected', 'ImagingUploadStatus', 'CustomLink', 'Appointment', 'CreditRepairWelcome', 'UserPromptsMenu', 'VideoListMenu', 'CalculatorResult', 'RatePricingResult', 'RefinanceCalculatorWelcome', 'CalculatorsWelcome', 'BlockchainMessage', 'BlockchainWelcome', 'Order'];

type TMessageContentWrapper = {
  children: ReactNode,
  errand: IErrand,
  index: number,
  message: IMessage,
  showDate: boolean,
  showSentiment: boolean,
};

const MessageContentWrapper = memo(({
  children, errand, index, message, showDate, showSentiment
}: TMessageContentWrapper) => {
  const { t } = useTranslation();
  const errandContext = useErrandContext();
  const rootContext = useRootContext();
  const userContext = useUserContext();

  const toggleMessageOptions = () => {
    if (errandContext.morphType === MorphType.PrivateChat) return;
    if (!message.visible && !message.operatorView) return;
    if (!message.operatorView && (!userContext.tpConsentGiven || !rootContext.returnConsentGiven)) {
      rootContext.handleShakingConsent();
      return;
    }
    if (message.messageStatus === 'deleted') return;
    if (INCOMPATIBLE_MESSAGE_TYPES.indexOf(message?.messageType) !== -1) return;
    errandContext.setMessageOptionsIndex((prev: number) => {
      if (prev === index) {
        errandContext.setIsMorphedFooterCloseButtonOnLeft(false);
        errandContext.setMorphType(MorphType.None);
        return -1;
      }
      errandContext.setIsMorphedFooterCloseButtonOnLeft(!message.alignByCurrentUser);
      errandContext.setMorphType(MorphType.MessageOptions);

      rootContext.setErrands((elm) => {
        const chatObj = elm.find((e) => e._id === errand?._id);

        if (chatObj) {
          ResetFooterUserAction(chatObj);
          
          // force re-render
          return [...elm];
        }

        // no re-render
        return elm;
      });

      return index;
    });
  }

  const renderNode = useMemo(() => {
    if (errandContext.morphType !== MorphType.MessageOptions) return false;
    if (errandContext.messageOptionsIndex !== index) return false;
    return true;
  }, [errandContext.morphType, errandContext.messageOptionsIndex, index]);

  const renderBorder = useMemo(() => {
    if (errandContext.morphType !== MorphType.MessageOptions) return false;
    if (errandContext.messageOptionsIndex !> index) return false;
    return true;
  }, [errandContext.morphType, errandContext.messageOptionsIndex, index]);

  const isLastMessage = useMemo(() => {
    return errand.messages.length === index + 1;
  }, [errand.messages?.length, index]);

  const isUnwrapped = useMemo(() => {
    return UNWRAPPED_MESSAGE_TYPES.indexOf(message?.messageType) !== -1;
  }, [message?.messageType]);

  const isChildrenOnly = useMemo(() => {
    return CHILDREN_ONLY_MESSAGE_TYPES.indexOf(message?.messageType) !== -1;
  },[message?.messageType]);

  const isSenderShown = useMemo(() => {
    return UNKNOWN_SENDER_MESSAGE_TYPES.indexOf(message?.messageType) === -1 && message?.action?.description !== 'Slot Machine';
  },[message?.messageType]);

  const isEditing = useMemo(() => {
    return errandContext.editMessageId === message?._id;
  }, [errandContext.editMessageId, message?._id]);

  const showMessageOptionsToggle = useMemo(() => {
    return INCOMPATIBLE_MESSAGE_TYPES.indexOf(message?.messageType) === -1;
  }, [message?.messageType]);

  const RenderSentiment = () => (
    <Tooltip title={t(`${message?.sentiment || 'Neutral'}`)} placement='top'>
      <div className={Styles.sentiment}>
        <SentimentEmoji sentiment={Sentiments[message?.sentiment]} regularEmoji={true} />
      </div>
    </Tooltip>
  );

  const RenderMessageOptionsToggle = () => (
    <Tooltip title={t('openMessageOptions')} placement='top'>
      <button
        data-chat-id={message.chat}
        data-message-id={message._id}
        data-sender-id={message.sender?._id}
        onClick={toggleMessageOptions}
        className={[Styles.button,
          ...(userContext.isUser && (!userContext.tpConsentGiven || !rootContext.returnConsentGiven) ? [Styles.disable] : [])
        ].join(' ')}
      >
        <MoreHoriz />
      </button>
    </Tooltip>
  );

  const RenderLeftOfMessage = () => (
    <>
      {showMessageOptionsToggle && <RenderMessageOptionsToggle />}
      {showSentiment && <RenderSentiment />}
    </>
  );

  const RenderRightOfMessage = () => (
    <>
      {showSentiment && message?.operatorView && <RenderSentiment />}
      {showMessageOptionsToggle && <RenderMessageOptionsToggle />}
    </>
  );

  return isUnwrapped ? (<>{children}</>) : (
    <article
      ref={renderNode ? errandContext.messageOptionsRef : null}
      className={[
        Styles.messageContentWrapper,
        ...(renderBorder ? [Styles.renderBorder] : []),
        ...(renderNode ? [Styles.renderNode] : []),
        ...(showDate ? [Styles.showDate] : []),
        ...(isLastMessage ? [Styles.isLastMessage] : []),
        ...(message.alignByCurrentUser ? [Styles.alignByCurrentUser] : []),
        ...(errandContext.isMorphedFooterCloseButtonOnLeft ? [Styles.isMorphedFooterCloseButtonOnLeft] : []),
        ...(!message.visible ? [Styles.blur] : []),
        ...(!message.sender?._id ? [Styles.hidden] : []),
      ].join(' ')}
    >
      <div className={Styles.node} aria-hidden={true} />
      {showDate && <MessageDate message={message} />}
      {isChildrenOnly ? (
        <>{children}</>
      ) : (
        <>
          {isSenderShown ? <MessageSender errand={errand} isEditing={isEditing} message={message} /> : <></>}
          {message.replyTo ? <MessageReply message={message} /> : <></>}
          <div
            className={[
              Styles.messageWrapper,
              ...(isEditing ? [Styles.isEditing] : []),
            ].join(' ')}
          >
            {message.alignByCurrentUser && <RenderLeftOfMessage />}
            {children}
            {!message.alignByCurrentUser && <RenderRightOfMessage />}
          </div>
        </>
      )}
    </article>
  );
});

export default MessageContentWrapper;
