import React from 'react';
import { isMobile } from "@common/deviceTypeHelper";
import { useState, useMemo, useEffect } from "react";
import { TSubContent } from "../types";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { VERY_SMALL_DEVICE_SHIFT_VALUE } from './SubContent';

interface ICloseButtonProps {
    subContent: TSubContent;
    show: boolean;
    isVerySmallDevice: boolean;
    isFullyRevealed: boolean;
    isRevealed: boolean;
    rightOffsetPos: number;
    onClick: () => void;
}

const CloseBtnBaseStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    position: "absolute",
}
const CloseBtnStyles = {
    default: (rightPos, opacity) => ({
        ...CloseBtnBaseStyle,
        backgroundColor: "var(--peach700)",
        width: "12px",
        height: "40px",
        borderRadius: "0 10px 10px 0px",
        top: "43%",
        right: `${rightPos}px`,
        opacity: opacity,
        zIndex: -2
    }) as React.CSSProperties,
    mobile: (isFullyRevealed, isVerySmallDevice, rightPos, opacity) => ({
        ...CloseBtnBaseStyle,
        right: `${rightPos + (isVerySmallDevice ? VERY_SMALL_DEVICE_SHIFT_VALUE : 0)}px`,
        opacity: opacity,
        top: '7px',
        zIndex: isVerySmallDevice && isFullyRevealed ? 3 : 1,
    }) as React.CSSProperties,
}

const CloseButton = (props: ICloseButtonProps) => {
    const closedPos = 0;
    const [rightPos, setRightPos] = useState(closedPos);
    const [opacity, setOpacity] = useState(0);
    const isMobileView = useMemo(() => isMobile(), []);
    // default
    const openPos = (props.rightOffsetPos - 12); // rightOffsetPos will be negative

    const show = () => {
        setRightPos(openPos);
        setOpacity(1);
    }

    const hide = () => {
        setRightPos(props.rightOffsetPos);
        setOpacity(0);
    }

    useEffect(() => {
        // if parent slideout was revealed
        if (props.isRevealed) {
            const tm = setTimeout(() => {
                // show the close button
                show();
            }, 1000);

            return () => clearTimeout(tm);
        } else {
            hide();
        }
    }, [props.isRevealed]);

    if (!props.show) {
        return <></>;
    }

    const onClickHandler = () => {
        hide();
        // compelete
        setTimeout(() => {
            props.onClick();
        }, 500); // animation time of hiding of the close button
    }

    const renderCloseIcon = () => {
        if (isMobileView) {
            return <ChevronLeftIcon sx={{ width: '20px', height: '20px' }} />
        }
        else {
            return "<"
        }
    }

    const closeBtnStyle =
        isMobileView === true
            ? CloseBtnStyles.mobile(props.isFullyRevealed, props.isVerySmallDevice, props.isRevealed ? -22 : 0, opacity)
            : CloseBtnStyles.default(rightPos, opacity);

    return (
        <div style={closeBtnStyle} onTouchEnd={onClickHandler} onClick={onClickHandler}>
            {renderCloseIcon()}
        </div>
    )
}

export {
    CloseButton
}