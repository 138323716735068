import React from "react";
import { useQIDOptionsFromActionId } from "@common/hooks/useQIDOptionsFromActionId"
import { Content } from "./Components/Content";
import { Loader } from "./Components/Loader";
import { getSelctedOptionsFromMessage } from "./Helpers";

import type { FC } from "react";
import type { IErrand, IMessage } from '@interfaces/Conversation';

type SelectMultpleOutGoingMessageContentProps = {
  message: IMessage,
  errand: IErrand,
}

const SelectMultpleOutGoingMessageContent: FC<SelectMultpleOutGoingMessageContentProps> = ({ message, errand }) => {
  const [options, loading] = useQIDOptionsFromActionId(message?.action._id);

  if (loading) return <Loader />

  return <Content {...{ message, errand, options: getSelctedOptionsFromMessage(message.message, options) }} />
}

export { SelectMultpleOutGoingMessageContent }