import React, { useCallback } from "react";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { MorphType } from '@common/MorphType';
import { useErrandContext } from '@contexts/ErrandContext';
import { useRootContext } from '@contexts/RootContext';
import { useSocketContext } from '@contexts/socket';
import { useUserContext } from '@contexts/user';
import axiosCall from '@services/axios';

import type { FC } from "react";
import type { IErrand, IMessage } from '@interfaces/Conversation';

type ActionIconProps = {
  message: IMessage;
  errand: IErrand;
}

const ActionIcon: FC<ActionIconProps> = ({ message, errand }) => {
  const { setErrands } = useRootContext();
  const { isOperator } = useUserContext();
  const { messagesSocket } = useSocketContext();
  const { footerInputRef, morphType } = useErrandContext();

  const triggerQuestion = useCallback(async () => {
    if (!message.visible) return;

    // Returning early for if button is Clicked when Morph is Open
    if (morphType === MorphType.SelectMultiple) return;

    // updates the icon, placeholder, and action on user side when the operator clicks the icon
    if (isOperator) {
      messagesSocket.current?.emit('emit-chat-event', {
        chatId: errand._id,
        data: {
          type: 'action',
          message: message._id,
        },
        recipients: [],
      });
    } else {
      // sets the corresponding userAction status to in-progress
      const payload = {
        url: `useraction/${message?.userAction?._id}`,
        method: 'put',
        data: {
          status: 'in-progress',
        },
      };

      await axiosCall(payload);
      setErrands((prev) => {
        if (!Array.isArray(prev)) {
          console.warn('setErrands prev is not an array');
          prev = [];
        }
        let index = prev.findIndex((e) => e._id === errand._id);
        prev[index] = {
          ...prev[index],
          icon: message.icon,
          placeholder: message?.action?.description,
          action: {
            ...message?.userAction,
            action: message?.action,
            userActionId: message?.userAction?._id,
            active: true,
          },
          recipients: message.intendedAudience ? [message.sender._id, ...message.intendedAudience].sort() : [],
        };
        return [...prev];
      });
      console.log('User triggered action, ' + message?.action?._id);
      footerInputRef?.current?.focus();
    }
  }, [setErrands, isOperator, messagesSocket, footerInputRef, morphType]);

  return (
    <button 
      style={{
        width: "36px",
        aspectRatio: "1/1",
        backgroundColor: "var(--orange700)",
        border: "2px solid var(--peach100)",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        cursor: "pointer",
        ...(isOperator
          ? { right: 0, transform: "translate(40%, -40%)" }
          : { left: 0, transform: "translate(-40%, -40%)" }
        )
      }}
      onClick={triggerQuestion}
    >
      { message?.icon ? (
        <img
          style={{
            height: '20px',
            width: '20px',
            filter: 'brightness(0) saturate(100%) invert(100%) drop-shadow(4px 3px 3px purple)',
          }}
          src={message?.icon}
          alt={`Action Icon`}
        />
      ) : (
        <QuestionMarkIcon
          fontSize="small"
          sx={{
            color: 'var(--gray000)',
            height: '20px',
            width: '20px',
            filter: 'brightness(0) saturate(100%) invert(100%) drop-shadow(4px 3px 3px purple)',
          }}
        />
      )}
    </button>
  )
}

export { ActionIcon };
