/**
 * @file inputSlice.ts
 * @description Slice that stores the data for the Input component
 * @author Eric Velepucha
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

interface InputState {
  value: string;
}

const initialState: InputState = {
  value: '',
};

export const inputSlice = createSlice({
  name: 'input',
  initialState,
  reducers: {
    updateInputValue: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
  },
});

export const { updateInputValue } = inputSlice.actions;
export const selectInputValue = (state: RootState) => state.input.value;
export default inputSlice.reducer;
