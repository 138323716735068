import { ValidatorFunctions } from '@common/Validators';
import React, { useContext } from 'react';
import { useState, useEffect } from "react";
import { LocalStateUpdatersContext } from './SubContent';
import { Styles } from './SubContentStyles';
import { isMobile } from '@common/deviceTypeHelper';

type TProps = {
    slideOutRevealed: boolean;
    forceEnlargeGif: boolean;
    enlargeOnStart: boolean;
    classNameSuffix: string;
    playOnStart: boolean;
    gifDuration: number;
    replayGif: boolean;
    staticSrc: string;
    gifSrc: string;
}

const GifOrImage = (props: TProps) => {
    const {
        updateInitialGifPlayed,
        updateReplayGif
    } = useContext(LocalStateUpdatersContext);

    const {
        slideOutRevealed,
        forceEnlargeGif,
        classNameSuffix,
        enlargeOnStart,
        gifDuration,
        playOnStart,
        replayGif,
        staticSrc,
        gifSrc
    } = props;
    const [isPlaying, setIsPlaying] = useState(false); // Controls whether the GIF is playing

    // On slide out first time revealed 
    useEffect(() => {
        if (
            slideOutRevealed === true
            && playOnStart === true
            && ValidatorFunctions.isNotUndefinedNorNull(gifSrc)
            && ValidatorFunctions.isNotEmptyString(gifSrc)
            && ValidatorFunctions.isNotUndefinedNorNull(gifDuration)
        ) {
            // play only once fully revealed.
            setIsPlaying((_) => true);

            let timeout;

            timeout = setTimeout(() => {
                setIsPlaying((_) => false); // Stop playing after the duration
                updateInitialGifPlayed(true);
            }, gifDuration);
        }
    }, [slideOutRevealed, playOnStart]);

    // any incoming consequent replays of the gif
    useEffect(() => {
        if (replayGif === true) {
            // normal procedure (play(true) -> timeout -> setPlay(false))
            setIsPlaying(true);

            let timeout;

            timeout = setTimeout(() => {
                setIsPlaying((_) => false); // Stop playing after the duration
                // unlock the replayGif state
                updateReplayGif(false);
            }, gifDuration);

            return () => clearTimeout(timeout);
        }
        else {
            setIsPlaying(false);
        }
    }, [replayGif])

    const getAnimatedClassname = () => {
        const baseAnimationClass = 'BWMC_subContent-gif-enlarge';
        return ` ${baseAnimationClass} ${baseAnimationClass}${classNameSuffix ? `-${classNameSuffix}` : ''}`
    }

    const getGifImgSrc = () => isPlaying ? gifSrc : staticSrc;

    // enlarge animation handling
    let classNameStr = 'BWMC_subContent-gif'; // base class
    if (forceEnlargeGif === true) {
        classNameStr += ' BWMC_subContent-gif-force-enlarge';
    }
    else {
        classNameStr += enlargeOnStart && isPlaying && !replayGif ? getAnimatedClassname() : '';
    }

    return (
        <div style={Styles.gifContainer}>
            <img
                src={getGifImgSrc()}
                className={classNameStr}
                alt="Slots Gif"
                style={{
                    zIndex: 1,
                    backgroundColor: 'transparent',
                    width: isMobile() ? '150px' : '125px',
                    objectFit: 'cover'
                }}
            />
        </div>
    );
}

export {
    GifOrImage
}