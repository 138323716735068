import React, { useContext, useEffect, useRef } from 'react';
// Hooks & Contexts
import { IErrandContext, ErrandContext } from '@contexts/ErrandContext';
import { useHasMounted } from '@common/hooks/useHasMounted';
// Api related tools
import { getOrderIdFrom, fetchOrderData } from '../Elements/Order';
// Interfaces, Constants & 
import { IMainTrackerProps, IOrderData } from '../interfaces';
import { ALL_ELEMENTS } from '../Config/Layouts';
import { REVEAL, HIDE } from '../Constants';
// Misc
import { ArrayValueValidator, StringValueValidator, isGivenMorphTypeFormRelated } from '../misc';
import { getUserPlayedLast24Hours } from '@storage/userStorage';
import { ValidatorFunctions } from '@common/Validators';
import { useTranslation } from 'react-i18next';
import {
  findSlotMachineActionMessageIn,
  findMessageOfTypeOrder,
  findMessageOfTypePropertyListing,
} from '@common/userMessagesUtils';
import { TimeoutController } from '../Controllers';

// This component contains all the logic needed for showing/hiding elements on Main Screen
// These are: Auth, Context, PrivateMode, EditingMode, Order, SlotMachine, Live, PropertyListing, Esign.
// Check out Layouts.ts file for elements configuration for each tracker.
export const MainTracker = (props: IMainTrackerProps) => {
  const { handleActivityChange, checkIfShown, AbortControllersRef } = props;

  // used for Live element (isWatching is being reflected in isUserWatchingLive variable from VideoDialog state)
  const errandContext = useContext<IErrandContext>(ErrandContext);
  // used for Live element logic.
  const userWatchingLive = errandContext.isUserWatchingLive;
  // used for ESign element check (by MorphType)
  const currMorphType = errandContext.morphType;
  // used for Translations in ALL_ELEMENTS.
  const { i18n } = useTranslation();
  // used for marking if the chat messages check was performed.
  // Chat Messages chat is as follows:
  // --- Go through each message in chat (the ones that loaded on first load only)
  // --- check for particular msg types and perform certain logic based on that type.
  const chatMessagesCheckDone = useRef(false);

  // used for lang switch handling of order & prop listing element logic.
  const hasMounted = useHasMounted();

  // Handle Order component func that runs when last message is of type Order
  // OR when there is a message of Order type present in chat.
  const handleOrderElement = async (htmlStr, withNavigation: boolean = true) => {
    const isAlreadyShown = checkIfShown(ALL_ELEMENTS.Order) === true;
    // do nothing if already shown.
    if (isAlreadyShown) {
      return;
    }
    // if not shown, fetch data and show it.
    const fetchAbortCtrl = new AbortController();
    // push abort controller to ref arr. [used for unmount clean up process]
    AbortControllersRef.current.push(fetchAbortCtrl);
    const orderId = getOrderIdFrom(htmlStr);
    let orderUIData: IOrderData;
    if (orderId === null) {
      console.error('handleOrderElement: [orderId] from passed [htmlStr] argument is null.', { orderId: orderId, htmlStr: htmlStr })
      return;
    }
    // check if order has cached data
    const orderIdFromStorage = props.ActivityOptionalDataStorage.current?.Order?._id;
    // check if the same
    if (orderIdFromStorage === orderId) {
      orderUIData = {
        ...(props.ActivityOptionalDataStorage.current.Order as IOrderData),
      };
      // Show order element with that cached order data
    } else {
      // fetch order data by orderId
      const orderData: IOrderData = await fetchOrderData(orderId, fetchAbortCtrl);
      orderUIData = {
        ...orderData,
      };
      // cache in order.
      props.ActivityOptionalDataStorage.current.Order = { ...orderData };
      // Show order element
    }
    // show order element
    handleActivityChange(ALL_ELEMENTS.Order, REVEAL, withNavigation, orderUIData);
    // hide order element after a durations.order duration
    TimeoutController.set(
      ALL_ELEMENTS.Order,
      setTimeout(() => {
        handleActivityChange(ALL_ELEMENTS.Order, HIDE);
      }, TimeoutController.durations.order)
    );
  };

  // This function is handling prop listing element UI logic
  const showPropertyListingElement = (withNavigation: boolean = true) => {
    // show it.
    handleActivityChange(ALL_ELEMENTS.PropertyListing, REVEAL, withNavigation);
  };
  /**
   * Takes care of all activity changes, both delayed AND current.
   */
  useEffect(() => {
    // take care of context element
    if (ValidatorFunctions.isNotUndefinedNorNull(props.errand?.context) === true) {
      // check if typeof Arr AND length > 0
      const validated = ArrayValueValidator(props.errand?.context).run();
      if (!validated) {
        // hide Context activity element
        handleActivityChange(ALL_ELEMENTS.Context, HIDE);
      } else {
        // show, if value is valid
        handleActivityChange(ALL_ELEMENTS.Context, REVEAL);
      }
    } else {
      // hide it if value is undefined OR null
      handleActivityChange(ALL_ELEMENTS.Context, HIDE);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.errand?.context, props.errand?.activeContext]);

  useEffect(() => {
    // take care of accessToken element
    if (ValidatorFunctions.isNotUndefinedNorNull(props?.authToken) === true) {
      // check if typeof string AND is not empty string.
      const validated = StringValueValidator(props?.authToken).run();
      if (!validated) {
        handleActivityChange(ALL_ELEMENTS.Auth, HIDE);
      } else {
        // hide Context activity element
        // show, if value is valid
        handleActivityChange(ALL_ELEMENTS.Auth, REVEAL);
      }
    } else {
      // hide it if value is undefined OR null
      handleActivityChange(ALL_ELEMENTS.Auth, HIDE);
    }
  }, [props?.authToken])

  // Private Mode element logic.
  useEffect(() => {
    const withNavigationOvveride = !checkIfShown(ALL_ELEMENTS.Action);

    if (props.privateMode === true) {
      handleActivityChange(ALL_ELEMENTS.PrivateMode, REVEAL, withNavigationOvveride);
    } else {
      handleActivityChange(ALL_ELEMENTS.PrivateMode, HIDE);
    }
  }, [props.privateMode]);

  // Editing Mode element logic.
  useEffect(() => {
    if (props.editingMode === true) {
      handleActivityChange(ALL_ELEMENTS.EditingMode, REVEAL);
    } else {
      handleActivityChange(ALL_ELEMENTS.EditingMode, HIDE);
    }
  }, [props.editingMode]);

  // ESign element logic.
  useEffect(() => {
    // if morphType is form related, show the esign element.
    if (isGivenMorphTypeFormRelated(currMorphType) === true) {
      handleActivityChange(ALL_ELEMENTS.Esign, REVEAL);
    } else {
      handleActivityChange(ALL_ELEMENTS.Esign, HIDE);
    }
  }, [currMorphType]);

  const handleSlotMachine = async (withNavigationOverride: boolean = true) => {
    if (getUserPlayedLast24Hours() === false) {
      handleActivityChange(ALL_ELEMENTS.SlotMachine, REVEAL, withNavigationOverride);
    }
  };

  /**
   * Handles 'Order' and 'PropertyListing' Element Activity Changes. (last messages)
   */
  useEffect(() => {
    if (
      props.errand?.lastMessageData === undefined ||
      props.errand?.lastMessageData === null ||
      props.errand?.lastMessageData?.messageType === undefined
    ) {
      return;
    }

    // do nothing if first check was not done yet.
    if (chatMessagesCheckDone.current === false) {
      return;
    }

    // If last message is of type "Order"
    if (props.errand.lastMessageData.messageType === ALL_ELEMENTS.Order) {
      // lastMessage.message has an order id but its an html string
      // so it will be parsed and 'orderid' will be extracted in handleOrderElement call.
      handleOrderElement(props.errand.lastMessageData?.message);
    }
    // If last mesage is of type "PropertyListing"
    else if (props.errand.lastMessageData?.messageType === 'PropertyListing') {
      // show this prop listing element if its not shown already.
      showPropertyListingElement();
    } else if (findSlotMachineActionMessageIn([props.errand.lastMessageData])) {
      handleSlotMachine();
    }
  }, [props.errand?.lastMessageData?._id]);

  /**
   * Takes care of Live Watching element logic.
   */
  useEffect(() => {
    if (userWatchingLive === true) {
      // show live watching
      handleActivityChange(ALL_ELEMENTS.Live, REVEAL);
    } else {
      // hide live watching
      handleActivityChange(ALL_ELEMENTS.Live, HIDE);
    }
  }, [userWatchingLive]);

  /**
   * Take care of Slot Machine element.
   */
  useEffect(() => {
    // Show slot machine element if it is set as shown.
    if (props.isSlotMachineShown === false) {
      handleActivityChange(ALL_ELEMENTS.SlotMachine, HIDE);
    }
  }, [props.isSlotMachineShown]);

  useEffect(() => {
    if (hasMounted === false) {
      return;
    }
    // clear the first msg check
    chatMessagesCheckDone.current = false;
  }, [i18n.language, hasMounted]);

  /**
   * Takes care of Order & PropertyListing messages present in chat. (not last message)
   * If order or prop listing or both are present, then display the more recent element
   *   in activityTracker and reveal the other one on the background.
   */
  useEffect(() => {
    // chatMessagesCheckDone.current === true is for messages=[] edge case && i18n lang change
    if (chatMessagesCheckDone.current === true) return;

    if (ValidatorFunctions.isUndefinedOrNull(props.errand?.messages) === true) {
      return;
    }

    if (props.errand?.messages.length === 0) {
      return;
    }
    // check the present messages in chat to contain Order OR propListing message or both
    const orderMessage = findMessageOfTypeOrder(props.errand.messages);
    const propListingMessage = findMessageOfTypePropertyListing(props.errand.messages);
    const slotMachineMessage = findSlotMachineActionMessageIn(props.errand.messages);
    const hasBoth = orderMessage !== undefined && propListingMessage !== undefined;

    // SlotMachine logic
    // If slot machine related message is present in chat,
    // reveal indicator in activity tracker.
    if (slotMachineMessage) {
      // if there is an orderMsg or propListMsg or both of them are present in in chat,
      // set to false (WITHOUT_NAVIGATION)
      // otherwise, if none of them present in chat,
      // set to true (WITH_NAVIGATION)
      const navigationOvveride = !(hasBoth || orderMessage !== undefined || propListingMessage !== undefined);
      handleSlotMachine(navigationOvveride);
    }

    // Show whatever has a newer sent date.
    if (hasBoth) {
      // check which one was sent sooner
      const orderMessageWasCreatedDate = new Date(orderMessage.createdAt);
      const propListingMessageWasCreatedDate = new Date(propListingMessage.createdAt);

      if (orderMessageWasCreatedDate > propListingMessageWasCreatedDate) {
        // order is newer so show order element with navigating to it and prop listing without navigating to it.
        handleOrderElement(orderMessage.message, true); // navigate to
        showPropertyListingElement(false); // reveal in the background
      } else {
        // property listing is newer, so show order element without navigating to it and prop listing with navigating to it.
        handleOrderElement(orderMessage.message, false); // reveal in the background
        showPropertyListingElement(true); // navigate to
      }
    }

    // Has only one of two
    else {
      // has order message in chat
      if (orderMessage !== undefined) {
        // make api call to get order data and show it.
        handleOrderElement(orderMessage.message);
      }

      // has prop listing message in chat
      if (propListingMessage !== undefined) {
        // show it as element in ActivityTracker
        showPropertyListingElement();
      }
    }

    // mark check as done.
    chatMessagesCheckDone.current = true;
  }, [props.errand?.messages?.length, i18n.language]);

  return props.UI;
};
