import React from "react";
import { getLangName } from '@common/common';
import { useUserContext } from '@contexts/user';
import OriginalLangStyle from '@styles/OriginalLangStyle';
import TranslationInfoStyle from '@styles/TranslationInfoStyle';
import { ActionIcon } from "./ActionIcon";
import Styles from "../Styles.module.css";
import { useTranslatedMessageAndOptions } from "../useTranslatedMessageAndOptions";

import type { FC } from "react";
import type { Option } from "@components/MorphSwiper/Types";
import type { IErrand, IMessage } from '@interfaces/Conversation';

const Curve = process.env.REACT_APP_MORGAN_CDN + '/Images/TranslationCurve.svg';

type ContentProps = {
  message: IMessage;
  errand: IErrand
  options: Option[]
}

const Content: FC<ContentProps> = ({ message, errand, options }) => {
  const { isOperator } = useUserContext();
  const { isFading, displayMessage, displayOptions, isTranslated, fade } = useTranslatedMessageAndOptions(message, options);

  return (
    <div 
      style={{ 
        minWidth: "12rem", 
        maxWidth: "29.3125rem",
        ...( isOperator ? { padding: "16px 0 0 10px" } : {}),
      }}
    >
      <header 
        style={{
          fontSize: "16px",
          fontWeight: 600,
          color: "var(--gray000)",
          backgroundColor: "var(--orange700)",
          border: "1px solid var(--orange700)",
          borderRadius: "8px 8px 0 0",
          position: "relative",
          ...( isOperator 
            ? { padding: "4px 8px 4px 8px", textIndent: "24px" }
            : { padding: "4px 30px 4px 8px" }
          )
        }}
      >
        <span className={isFading ? Styles.fade : ""}>
          { displayMessage }
        </span>
        <ActionIcon {...{ message, errand }} />
      </header>
      <ol 
        style={{
          padding: "4px 16px",
          backgroundColor: "var(--peach600)",
          border: "1px solid var(--orange700)",
          borderRadius: "0 0 8px 8px",
          listStyleType: "none",
        }}
      >
        { 
          displayOptions.slice(0, 7).map((option, index) => (
            <li 
              key={option.keyword}
              style={{
                fontSize: "12px",
                fontFamily: "Poppins",
                color: "var(--blue999)",
                padding: "4px 0",
                position: "relative",
              }}
            >
              <i
                style={{
                  width: "20px",
                  aspectRatio: "1/1",
                  fontSize: "12px",
                  fontWeight: "600",
                  textAlign: "center",
                  paddingRight: "2px",
                  color: "var(--gray000)",
                  backgroundColor: "var(--orange700)",
                  border: "2px solid var(--peach100)",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: "50%",
                  left: "-26px",
                  transform: "translateY(-50%)",
                }}
              >
                { index + 1 }
              </i>
              <span className={isFading ? Styles.fade : ""}>
                { option.description }
              </span>
            </li>
          ))
        }

        { 
          (options.length > 7) ? (
            <li 
              style={{
                fontSize: "12px",
                fontFamily: "Poppins",
                color: "var(--blue999)",
                padding: "4px 0",
                position: "relative",
              }}
              className={isFading ? Styles.fade : ""}
            >•••</li>
          ) : null
        }
      </ol>
      {
        isTranslated ? (
          <div
            style={{
              margin: "auto",
              paddingBottom: '4px',
              minWidth: '220px',
              maxWidth: '220px',
              textAlign: 'center',
              color: 'var(--blue999)',
              backgroundColor: 'inherit',
              backgroundImage: `url(${Curve})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain 100%',
              alignSelf: 'center',
              display: 'flex',
              justifyContent: "center",
              alignItems: 'center',
              gap: '10px',
              position: 'relative',
              backgroundPosition: '50% 100%',
            }}
          >
            <OriginalLangStyle onClick={fade}>
              <u>{getLangName(message?.detectedLanguage)}</u>
            </OriginalLangStyle>
            <TranslationInfoStyle>&#8644; &nbsp; &nbsp; { message?.localizedLanguage }</TranslationInfoStyle>
          </div>
        ) : null
      }
    </div>
  );
}

export { Content };
export type { ContentProps };
