import React, { useCallback, useEffect, useMemo, useState } from "react";

import { uiTranslationController } from '@common/common';
import LanguageUtils from '@common/LanguageUtils';
import { translateOptions } from "@common/TranslateQIDOptions";
import useDebounce from "@common/hooks/useDebounce";

import type { Option } from "@components/MorphSwiper/Types";
import type { IMessage } from '@interfaces/Conversation';

const useTranslatedMessageAndOptions = (message: IMessage, options: Option[]) => {
  const { dispMessage: translatedMessage, isTranslated } = uiTranslationController(message || '');
  const [translatedOptions, setTranslatedOptions] = useState<Option[]>(options);
  
  const [unTranslatedShown, setUnTranslatedShown] = useState(false);

  const displayMessage = useMemo(
    () => !isTranslated || unTranslatedShown ? message.message : translatedMessage,
    [isTranslated, unTranslatedShown]
  )
  const displayOptions = useMemo(
    () => !isTranslated || unTranslatedShown ? options : translatedOptions,
    [isTranslated, unTranslatedShown, translatedOptions]
  )

  const [isFadingIn, setIsFadingIn] = useState(false);
  useDebounce(() => setIsFadingIn(false), 1000, [isFadingIn])

  const [isFadingBack, setIsFadingBack] = useState(false);
  useDebounce(() => setIsFadingBack(false), 1000, [isFadingBack])

  const translateCurrentOptions = useCallback(async () => {
    const translatedOptions = await translateOptions(options);
    
    // assigning async function to promise first prevents state from temperarily being set to undefined and allows you to validate the state.
    setTranslatedOptions(translatedOptions);
  }, [options]);

  const fadeIn = useCallback(() => {
    setIsFadingIn(true);
    setTimeout(() => setUnTranslatedShown(true), 500);
  }, [setIsFadingIn, setUnTranslatedShown]);

  const fadeBack = useCallback(() => {
    setIsFadingBack(true);
    setTimeout(() => setUnTranslatedShown(false), 500);
  }, [setIsFadingBack, setUnTranslatedShown]);

  /**
   * This function is a handler callback for the Original Language component's
   * onClick event. It is responsible for temporarily revealing the origianl
   * message.
   */
  const fade = useCallback(async () => {
    fadeIn();
    setTimeout(() => fadeBack(), 4000);
  }, [fadeIn, fadeBack]);

  useEffect(() => {
    translateCurrentOptions()
  }, [isTranslated, LanguageUtils.fetchLocalizationLanguageSetting()]);

  return { isFading: (isFadingIn || isFadingBack), displayMessage, displayOptions, isTranslated, fade }
}

export { useTranslatedMessageAndOptions };