import { IErrand, IUserChatAction } from '@interfaces/Conversation';
import { getUserPlayedLast24Hours } from '@storage/userStorage';
import axiosCall from '@services/axios';
import { t } from 'i18next';

export const shouldPreventPlaySlotMachine = async (errand: IErrand, action: IUserChatAction, timeZone: string) => {
  const payload = {
    url: `slotmachine/check/${getUserPlayedLast24Hours()}?fields=chat,status,action&status=resolved&chat=${
      errand._id
    }&action=${action._id}`,
    method: 'POST',
    data: {
      timeZone,
    },
  };
  return await axiosCall(payload);
};

// returns the time if user has played
// return false if user has not played
export const checkHasPlayedInPastDay = async (userId: string) => {
  // Check if localstorage timestamp shows user has played already
  // If not, request Core to check for resolved slot machine userActions
  if (!getUserPlayedLast24Hours()) {
    return await axiosCall({url: `slotmachine/check/${userId}`});
  }
  return true;
}

function getRandomItem(arr) {
  if (arr.length === 0) {
    return '';
  }
  const randomIndex = Math.floor(Math.random() * arr.length);
  return arr[randomIndex];
}

export const getBeforePlayMessage = () => {
  const messagesBeforePlaying = [
    t('slotMachineThrill'),
    t('getReadyToSpin'),
    t('luckIsOnYourSide'),
    t('bigWin'),
    t("letsSpin"),
    t('spinAndWin'),
  ];
  return getRandomItem(messagesBeforePlaying);
};

export const getAfterWinMessage = () => {
  const messagesAfterWinning = [
    t('youDidIt'),
    t('congratsOnSpin'),
    t('wellDoneWin'),
    t('struckGold'),
    t("hitItBig"),
  ];
  return getRandomItem(messagesAfterWinning);
};

export const getAfterLoseMessage = () => {
  const messagesAfterLosing = [
    t('slotMachineNoPrize'),
    t('betterLuck'),
    t('closeOne'),
    t('spinAgain'),
    t('notThisTime'),
    t('missedIt'),
  ];
  return getRandomItem(messagesAfterLosing);
};
