import type { Option } from "@components/MorphSwiper/Types";

const getSelctedOptionsFromMessage = (string: string, options: Option[]): Option[] => {
  try {
    if (string.indexOf('<i class="messageArrow"/>') !== -1) {
      // Remove the old message.
      string = string.split(`<i class="messageArrow"/>`)[1];
    }

    const selected = JSON.parse(string);
    if (!(selected instanceof Array)) return [];

    const selctedSet = new Set(selected);

    return options.flatMap<Option>(option => selctedSet.has(option.keyword) ? option : []);
  } catch(error) {
    return [];
  }
}

export { getSelctedOptionsFromMessage };