// import { DoubleTick, Tick } from '@assets/Icons';
import { Fade, IconButton, Box, Stack, MorganTheme, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useSocketContext } from '@contexts/socket';
import axiosCall from '@services/axios';

import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import { ChatBubbleStyle, MessageClickableBubbleStyle } from '@styles/ChatBubbleStyle';
import Sanitized from '@components/Sanitized';
import MessageTextStyle from '@styles/MessageTextStyle';
import OriginalLangStyle from '@styles/OriginalLangStyle';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import TranslationInfoStyle from '@styles/TranslationInfoStyle';
import TranslationTabLeft from '@styles/TranslationTabLeft';
import TranslationTabRight from '@styles/TranslationTabRight';
// import { useTranslation } from 'react-i18next';
import eventBus from '@common/eventBus.js';
import { uiTranslationController, getLangName } from '@common/common';
import { IErrand, IMessage } from '@interfaces/Conversation';
import { useErrandContext } from '@contexts/ErrandContext';
import { MorphType } from '@common/MorphType';
import useDebounce from '@common/hooks/useDebounce';
import { ChatType } from '@common/ChatType';
import { useRootContext } from '@contexts/RootContext';
import MessageTime from '@components/MessageTime';
import { AccessType } from '@common/AccessType';
import { useUserContext } from '@contexts/user';
import { copyObj } from '@common/userMessagesUtils';
const WoodGrain = process.env.REACT_APP_MORGAN_CDN + '/Images/woodgrain.png';

const getTextDialogHtmlVal = (visible, dispMessage) => {
  return visible === false
      ? dispMessage === undefined
        ? ''
        : dispMessage.replace(/./g, '*')
      : visible === true
      ? dispMessage === undefined
        ? ''
        : dispMessage
      : '';
}

type TActionMessageContentProps = {
  errand: IErrand;
  index: number,
  message: IMessage;
}

const ActionMessageContent = ({
  errand, index, message,
}: TActionMessageContentProps) => {
  const rootContext = useRootContext();
  const errandContext = useErrandContext();
  const { isOperator, isUser, _id, tpConsentGiven } = useUserContext();
  const theme: MorganTheme = useTheme();
  const messageRef = useRef(null);
  const revealEnabledRef = useRef<boolean>(false);
  /* Set the placeholder for a message  (If it is action or field type)*/
  const [isFading, setIsFading] = useState(true);
  const [debounceCounter, setDebounceCounter] = useState(0);

  // it will run at every single render and it's ok because it's used as a render condition flag
  const userActionCreateFormFlag: Boolean = message?.action?.chatTypeInitiator === ChatType.Form;

  const { dispMessage, isTranslated } = uiTranslationController(message || '');

  const { messagesSocket, isMessagesConnected } = useSocketContext();

  useDebounce(() => errandContext.createFormHandler(index), 400, [debounceCounter]);

  /* On click handler for clicking an action icon */
  const handleClick = async (e) => {
    e?.preventDefault();
    // Don't let the user initiate the action if it is not intended for them, or a public action.
    if (!message.visible || (message.intendedAudience?.length > 0 && !message.intendedAudience?.includes(_id))) return;
    
    if (isUser && (!tpConsentGiven || !rootContext.returnConsentGiven)) {
      return eventBus.dispatch('showConsentContent');
    }
    
    // Returning early for Calendar and Time if the user tries to click on the same action while they're in it already
    // Fixes a bug where the morphType would change without canceling the action, breaking the calendar/time's hide/show capabilities
    if ((errandContext.morphType === MorphType.CalendarMonth || errandContext.morphType === MorphType.DOB) && 
        (message?.action?.fieldAttribute.description === "CALENDAR" || message?.action?.fieldAttribute.description === "DOB")) {
      return;
    }

    if (errandContext.morphType === MorphType.Time && message?.action?.fieldAttribute.description === "TIME"){
      return;
    }
    if ([MorphType.Attachment, MorphType.PhotoMain, MorphType.PhotoPlain].includes(errandContext.morphType)) {
      //reset the morph data on change.
      errandContext.setMainPhoto(null);
      errandContext.setPhotoLimit(null);
      errandContext.setPhotoSelectorIndex(null);
    }
    if (message?.action.fieldName === 'creditCardDetails') {
      //do a check to see if we already paid for the product
      //textInputEnabled.current = false;
      const res = await axiosCall({
        url: `pay/field`,
        method: 'POST',
        data: { chat: errandContext.errandId, context: errand.activeContext },
      });
      if (res.data !== 'payment already made') {
        errandContext.setMorphType(MorphType.Payment);
      } else {
        errandContext.setMorphType((prev) => {
          if (message?.accessType === AccessType.private) return MorphType.PrivateChat;
          return MorphType.None;
        });
        return;
      }
    } else {
      errandContext.setEditMessageId('');
      errandContext.setMessageOptionsIndex(-1);
      errandContext.setIsMorphedFooterCloseButtonOnLeft(false);
      errandContext.setMorphType((prev) => {
        if (message?.accessType === AccessType.private) return MorphType.PrivateChat;
        return MorphType.None;
      });
    }
    // Instead of the approach to split type by Message Contents, moving to new
    // property for actionModel -- chatTypeInitiator which will have: "form", "activity",
    // "page", and "". The default will be "" for regular conversation/team/group chats

    if (isUser && userActionCreateFormFlag) {
      // trigger createFormHandler
      setDebounceCounter((prev) => prev + 1);

      return;
    }

    // updates the icon, placeholder, and action on user side when the operator clicks the icon
    if (isOperator) {
      messagesSocket.current?.emit('emit-chat-event', {
        chatId: errand._id,
        data: {
          type: 'action',
          message: message._id,
        },
        recipients: [],
      });
    } else {
      // sets the corresponding userAction status to in-progress
      const payload = {
        url: `useraction/${message?.userAction?._id}`,
        method: 'put',
        data: {
          status: 'in-progress',
        },
      };
      await axiosCall(payload);
      rootContext.setErrands((prev) => {
        if (!Array.isArray(prev)) {
          console.warn('setErrands prev is not an array');
          prev = [];
        }
        // find index of curr errand
        let index = prev.findIndex((e) => e._id === errand._id);
        // update the curr errand data with new placeholder, icon and action.
        prev[index] = {
          ...prev[index],
          icon: message.icon,
          placeholder: message?.action?.description,
          action: {
            ...message?.userAction,
            action: message?.action,
            userActionId: message?.userAction?._id,
            active: true,
          },
          recipients: message.intendedAudience ? [message.sender._id, ...message.intendedAudience].sort() : [],
        };
        return [...prev];
      });
      console.log('User triggered action, ' + message?.action?._id);
      errandContext?.footerInputRef?.current?.focus();
    }
  };

  /**
   * This function is a handler callback for the Original Language component's
   * onClick event. It is responsible for temporarily revealing the origianl
   * message.
   */
  const handleClickOriginalLang = async (e) => {
    e?.stopPropagation();
    if (!message.visible) return;
    if (revealEnabledRef.current) {
      return;
    }
    const reset = messageRef.current.innerHTML;

    // Start the inital fadeout:
    revealEnabledRef.current = true;
    setIsFading(false);

    // Wait until the fadeout is done to fade in the original message:
    setTimeout(
      (reset) => {
        let editedMessage = message?.message;

        let [firstSection, secondSection] = message?.message
          ?.split(`<i class="messageArrow"/>`)
          .map((x) => x.replace(/(<([^>]+)>)/gi, ''));

        if (secondSection) {
          editedMessage = `<s>${firstSection}</s>&nbsp;<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-1r4vxry-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowCircleRightRoundedIcon"><path d="M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12s4.48 10 10 10 10-4.48 10-10zm-10 2.79V13H9c-.55 0-1-.45-1-1s.45-1 1-1h3V9.21c0-.45.54-.67.85-.35l2.79 2.79c.2.2.2.51 0 .71l-2.79 2.79c-.31.31-.85.09-.85-.36z"></path></svg> <i>${secondSection}</i>`;
        }

        // Style the original message:
        editedMessage = `<div style="font-size: 0.8em; font-family: Poppins; font-weight: 400;">${editedMessage}</div>`;

        messageRef.current.innerHTML = editedMessage;

        setIsFading(true);

        // Allow the revealed message to display for some time, then begin fading
        // it out:
        setTimeout(
          (reset) => {
            setIsFading(false);

            // Finally, wait until the fadeout is done, then fade in the translated
            // message:
            setTimeout(
              (reset) => {
                messageRef.current.innerHTML = reset;
                setIsFading(true);
                revealEnabledRef.current = false;
              },
              500,
              reset
            );
          },
          3000,
          reset
        );
      },
      500,
      reset
    );
  };

  // Message side icon button wrapper we have multiple Icon Button definition on react component
  const MessageSideIconButtonComponent = () => {
    // chatTypeInitiator doesn't have side clickable action icon button
    if (userActionCreateFormFlag) {
      return null;
    }

    return (
      <IconButton sx={{ p: 0, overflow: 'hidden', borderRadius: message.alignByCurrentUser ? '9px 0 0 9px' : '0 9px 9px 0' }}>
        <Stack
          alignItems='stretch'
          justifyContent='center'
          flexDirection='column'
          sx={{
            flexGrow: 1,
            pr: 0.5,
            height: '100%',
            backgroundColor: 'var(--orange700)',
            backgroundImage: theme.system.name === 'SUNWST000' ? `url(${WoodGrain})` : 'none',
            border: 'none',
            p: '5px',
            cursor: 'pointer',
          }}
        >
          {typeof message?.icon === 'string' && message?.icon ? (
            <img
              style={{
                height: '20px',
                width: '20px',
                filter: 'brightness(0) saturate(100%) invert(100%) drop-shadow(4px 3px 3px purple)',
              }}
              src={message?.icon}
              alt={`Action Icon`}
            />
          ) : (
            <QuestionMarkIcon
              fontSize='small'
              sx={{
                color: 'var(--gray000)',
                height: '20px',
                width: '20px',
                filter: 'brightness(0) saturate(100%) invert(100%) drop-shadow(4px 3px 3px purple)',
              }}
            />
          )}
        </Stack>
      </IconButton>
    );
  };

  // adds listener on user side to update the icon, placeholder, and action on user side when the operator clicks the icon
  useEffect(() => {
    if (!isMessagesConnected) return;
    if (isOperator) return;

    const chatEventHandler = (payload) => {
      if (!payload?.data) return;
      if (!payload.data?.type || !payload.data?.message) return;
      if (payload.data.type === 'action' && payload.data.message === message?._id) {
        handleClick(null);
      }
    };

    messagesSocket.current?.on('chat-event-emitted', chatEventHandler);
    return () => {
      messagesSocket.current?.off('chat-event-emitted', chatEventHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessagesConnected, errandContext.errandId]);

  /**
   * This Wrapper is for modifying message buble style
   * Currently it's used as a whole clickable message vs regular message
   *
   * @returns
   */
  const MessageBubleStyle = (): JSX.Element => {
    return isUser && userActionCreateFormFlag ? (
      <MessageClickableBubbleStyle 
        onClick={handleClick}
        onMouseDown={(e) => {e.preventDefault()}}
        // onTouchStartCapture={(e) => handleClick(e)}
        sx={{
          minWidth: isTranslated ? '220px' : '85px',
          borderColor: message.accessType === AccessType.internal ? 'var(--gray400)' : 'var(--orange700)',
          borderBottomRightRadius: '80px',
          background: message.accessType === AccessType.internal
            ? 'var(--peach020)'
            : message.sentByCurrentUser
              ? 'var(--gray000)'
              : 'var(--peach600)',
          pointerEvents: 'all',
        }}
      >
        {CommonChildComponent()}
      </MessageClickableBubbleStyle>
    ) : (
      <ChatBubbleStyle
        onClick={handleClick}
        onMouseDown={(e) => {e.preventDefault()}}
        // onTouchStartCapture={(e) => handleClick(e)}
        sx={{
          minWidth: isTranslated ? '220px' : '85px',
          borderColor: message.accessType === AccessType.internal ? 'var(--gray400)' : 'var(--orange700)',
          borderBottomRightRadius: '10px',
          background: message.accessType === AccessType.internal
            ? 'var(--peach020)'
            : message.sentByCurrentUser
              ? 'var(--gray000)'
              : 'var(--peach600)',
          pointerEvents: 'all',
        }}
      >
        {CommonChildComponent()}
      </ChatBubbleStyle>
    );
  };

  /**
   * This wrapper is for common element of chat buble
   */
  const CommonChildComponent = (): JSX.Element => {
    return (
      <Stack flexDirection="column" width="100%">
        {/* If the message is of type ACTION, render an svg icon at the BEGINNING of the chat bubble */}
        {/* FOR USER SCREEN ONLY - OPERATOR SIDE HAS THIS ON THE END, USER AT THE BEGINNING */}
        <Stack flexDirection="row">
          {isOperator && MessageSideIconButtonComponent()}
          <Stack
            alignItems="center"
            sx={{
              d: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              p: '6px 7px 8px 9px',
              maxWidth: '100%',
              position: 'relative',
              width: '100%',
            }}
          >
            {message?.messageStatus === 'edited' ? (
              <>
                <Stack direction="row" justifyContent="center" alignItems="center" ref={messageRef}>
                  <Fade in={isFading} appear={false}>
                    {message.visible ? (
                      <MessageTextStyle
                        sx={{
                          wordWrap: 'break-word',
                          width: 'fit-content',
                          color: 'var(--red400)',
                          textDecorationColor: 'var(--red400)',
                          textDecorationLine: 'line-through',
                        }}
                      >
                        {dispMessage?.split(`<i class="messageArrow"/>`)[0]?.replace(/(<([^>]+)>)/gi, '')}
                      </MessageTextStyle>
                    ) : (
                      <MessageTextStyle>{getTextDialogHtmlVal(false, dispMessage)}</MessageTextStyle>
                    )}
                  </Fade>
                  <ArrowCircleRightRoundedIcon
                    sx={{
                      color: 'var(--orange700)',
                      marginRight: '5px',
                    }}
                  ></ArrowCircleRightRoundedIcon>
                  <Fade in={isFading} appear={false}>
                    {message.visible ? (
                      <MessageTextStyle>
                        {dispMessage?.split(`<i class="messageArrow"/>`)[1]?.replace(/(<([^>]+)>)/gi, '')}
                      </MessageTextStyle>
                    ) : (
                      <MessageTextStyle>{getTextDialogHtmlVal(false, dispMessage)}</MessageTextStyle>
                    )}
                  </Fade>
                </Stack>
              </>
            ) : (
              <Fade in={isFading} appear={false}>
                <Stack direction="row" justifyContent="center" alignItems="center" ref={messageRef}>
                  <MessageTextStyle>
                    <Sanitized
                      highlight={message?.searchWords ? message?.searchWords : undefined}
                      html={getTextDialogHtmlVal(message.visible, dispMessage)}
                    />
                  </MessageTextStyle>
                </Stack>
              </Fade>
            )}
            &nbsp;
            <Stack
              sx={{
                margin: '0 0 0 auto',
                flexWrap: 'nowrap',
                backgroundColor: 'transparent',
              }}
            >
              <MessageTime message={message} />
              {userActionCreateFormFlag && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    display: 'flex',
                    position: 'absolute',
                    background: 'var(--orange700)',
                    right: '25px',
                    borderRadius: '15px',
                    bottom: '-15px',
                    height: '30px',
                    width: '30px',
                  }}
                >
                  {typeof message?.icon === 'string' && message?.icon ? (
                    <img
                      style={{
                        height: '20px',
                        width: '20px',
                        filter: 'brightness(0) saturate(100%) invert(100%) drop-shadow(4px 3px 3px purple)',
                      }}
                      src={message?.icon}
                      alt={`Action Icon`}
                    />
                  ) : (
                    <QuestionMarkIcon
                      fontSize="small"
                      sx={{
                        color: 'var(--gray000)',
                        height: '20px',
                        width: '20px',
                        filter: 'brightness(0) saturate(100%) invert(100%) drop-shadow(4px 3px 3px purple)',
                      }}
                    />
                  )}
                </Box>
              )}
            </Stack>
          </Stack>

          {/* If the message is of type ACTION, render an svg icon at the END of the chat bubble */}
          {/* FOR USER SCREEN ONLY - OPERATOR SIDE HAS THIS ON THE LEFT SIDE, USER ON THE RIGHT */}

          {isUser && MessageSideIconButtonComponent()}
        </Stack>
      </Stack>
    );
  }

  const inAudience = useMemo(() => {
    if (message.operatorView) return true;
    if (!Array.isArray(message.intendedAudience)) return true;
    if (message?.intendedAudience?.length === 0) return true;
    return message.intendedAudience.includes(message.userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message?.intendedAudience?.length, message.operatorView, message.userId]);

  return (
    <Stack
      display='flex'
      flexDirection='column'
      alignItems={
        // On the user side, Team, and Group chats: only sender is on the right side
        // On the operator side, only sender (operator) and Morgan is on the right side
        message?.alignByCurrentUser
          ? 'flex-end'
          : 'flex-start'
      }
      width='fit-content'
      maxWidth={window.innerWidth > 900 ? 'min(60%, calc(100vw - 50px))' : 'min(90%, calc(100vw - 50px))'}
      minWidth={isTranslated ? '220px' : '85px'}
      sx={{
        filter: inAudience ? 'unset' : 'blur(3px)'
      }}
    >
      {/* Added an additional stack wrapper because the translate sub bubble will position itself
      in the center of the entire stack, and become off center, if a thumbnail is rendered */}
      <Stack display='flex' flexDirection='column'>
        {MessageBubleStyle()}
        {isTranslated &&
          (message?.alignByCurrentUser ? (
            <TranslationTabRight>
              <OriginalLangStyle onClick={handleClickOriginalLang}>
                <u>{getLangName(message?.detectedLanguage)}</u>
              </OriginalLangStyle>
              <TranslationInfoStyle>&#8644; &nbsp; &nbsp; {message?.localizedLanguage}</TranslationInfoStyle>
            </TranslationTabRight>
          ) : (
            <TranslationTabLeft>
              <OriginalLangStyle onClick={handleClickOriginalLang}>
                <u>{getLangName(message.detectedLanguage)}</u>
              </OriginalLangStyle>
              <TranslationInfoStyle>&#8644; &nbsp; &nbsp; {message.localizedLanguage}</TranslationInfoStyle>
            </TranslationTabLeft>
          ))}
      </Stack>
    </Stack>
  );
};

export default ActionMessageContent;
