import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

interface passwordState {
  isPasswordValid: boolean;
  hasAttemptedPassword: boolean;
}

const initialState: passwordState = {
  isPasswordValid: false,
  hasAttemptedPassword: false,
};

export const passwordSlice = createSlice({
  name: 'password',
  initialState,
  reducers: {
    validatePassword: (state) => {
      state.isPasswordValid = true;
    },
    invalidatePassword: (state) => {
      state.isPasswordValid = false;
    },
    attemptedPassword: (state) => {
      state.hasAttemptedPassword = true;
    },
    resetAttemptedPassword: (state) => {
      state.hasAttemptedPassword = false;
    },
  },
});

export const { validatePassword, invalidatePassword, attemptedPassword, resetAttemptedPassword } =
  passwordSlice.actions;
export const selectIsPasswordValid = (state: RootState) => state.password.isPasswordValid;
export const selectHasAttemptedPassword = (state: RootState) => state.password.hasAttemptedPassword;
export default passwordSlice.reducer;
