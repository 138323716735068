import {
  Avatar,
  Badge,
} from '@mui/material';
import React, { PropsWithChildren, useMemo, useState, useRef, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRootContext } from '@contexts/RootContext';
import { getCurrentParticipant } from '@common/errandUtils';
import { MorphType } from '@common/MorphType';
import Styles from '@styles/Errand.module.css';
import Sanitized from './Sanitized';
import { useUserContext } from '@contexts/user';
import { DragIndicator, BorderColor, Delete } from '@mui/icons-material';
import Ballerina1 from '../Assets/Icons/ballerina1.svg';
import Ballerina2 from '../Assets/Icons/ballerina2.svg';
import Ballerina3 from '../Assets/Icons/ballerina3.svg';
import Ballerina4 from '../Assets/Icons/ballerina4.svg';
import Ballerina5 from '../Assets/Icons/ballerina5.svg';
import { handlePreviews } from '@common/loadPreview';
import { isMobileOrTablet } from '@common/deviceTypeHelper';
import { Timeout } from 'react-number-format/types/types';
import { ValidatorFunctions } from '@common/Validators';

const BALLERINA_IMAGES = [Ballerina1, Ballerina2, Ballerina3, Ballerina4, Ballerina5];
const AngelAi = process.env.REACT_APP_MORGAN_CDN + '/Images/AngelAi-color.png';

const PREVIEW_DEFAULT = '...';

const LONG_PRESS_DURATION = 1000;

/*
 *  This component renders a message in the conversation. This includes not only the chat bubble, but the Author and
 *
 *  This component has the following properties:
 *    - name - The name of the errand
 *    - preview - A preview of the most recently sent message in that errand's chat
 *    - active - A boolean value representing wether or not the errand is currently selected
 *    - isDefault - A boolean value telling representing if this is the default morgan chat,
 *                  This will hide the close butt on the errand in the sidebar, as well as rendering
 *                  The morgan logo for the avatar icon. This is only for the first and default errand
 */
const Errand: React.FC<PropsWithChildren<any>> = (props: any) => {
  const { errand, checkedErrands, index, toggleCheckedErrands, handleSelectErrand, isSelected, isDragging, isMatch } = props;

  const [isLongPress, setIsLongPress] = useState(false); // used to toggle checked status
  const [wasUnchecked, setWasUnchecked] = useState(false); // used to prevent selection of errand immediately after unchecking
  const hasMoved = useRef(false);
  const longPressTimerRef = useRef<Timeout>();

  const { t } = useTranslation();
  const { _id, tpConsentGiven } = useUserContext();

  const { drawerRef, editErrandId, errandColorRef, morphedId, setRootMorphType, chatBulkClose, selectedIndex, errands, returnConsentGiven, handleShakingConsent } = useRootContext();

  const currentParticipant = useMemo(() => getCurrentParticipant(errand, _id), [_id, errand]);

  const messageHistoryAllowed = useMemo(() => {
    if(ValidatorFunctions.isNotUndefinedNorNull(currentParticipant?.messageHistoryAllowed)) {
      return currentParticipant.messageHistoryAllowed;
    } else {
      return false;
    }
  }, [currentParticipant, currentParticipant?.messageHistoryAllowed]);

  const isChecked = useMemo(() => checkedErrands.findIndex((checkedErrand) => checkedErrand._id === errand._id) !== -1, [checkedErrands, errand]);

  const avatar = useMemo(() => errand.isDefault ? AngelAi : BALLERINA_IMAGES[(index as number) % BALLERINA_IMAGES.length], [errand, index]);

  const checkedErrandsLength = useMemo(() => checkedErrands?.length, [checkedErrands?.length]);

  /**
   * User side preview
   */
  const preview = useMemo(() => {
    const messageType = errand.lastMessageData?.messageType;

    if(messageType === 'WelcomeUser') {
      return <Sanitized html={errand?.preview}/> ?? "Welcome User Message.";
    }
    // 1: Show preview based on message history allowed status
    if (messageHistoryAllowed === false) {
      // replace preview with stars
      return "******";
    }

    // 2: Show preview based on lastMessageData
      const showData = handlePreviews(errand.lastMessageData) || PREVIEW_DEFAULT;
      if (showData !== PREVIEW_DEFAULT) {
      return showData;
      }

    // 3: chat preview field as a backup
    return errand.preview || PREVIEW_DEFAULT;
  }, [errand, messageHistoryAllowed]);
 
  const handleMouseDown = useCallback((e) => {
    if (checkedErrandsLength > 0) {
      toggleCheckedErrands(errand, index, currentParticipant);
      setWasUnchecked(true); 
    } else {
      longPressTimerRef.current = setTimeout(() => {
        setIsLongPress(true);
        if (navigator.vibrate){
          navigator.vibrate(100);
        }
        toggleCheckedErrands(errand, index, currentParticipant);
      }, LONG_PRESS_DURATION);
    }
  }, [checkedErrandsLength, currentParticipant, errand, index, toggleCheckedErrands]);
  
  const handleMouseUp = useCallback((e) => {
    clearTimeout(longPressTimerRef.current);
    if (!isLongPress && !wasUnchecked && checkedErrandsLength === 0) {
      handleSelectErrand(index);
    }
    setIsLongPress(false);
    setWasUnchecked(false);
  }, [checkedErrandsLength, handleSelectErrand, index, isLongPress, wasUnchecked]);

  const handleMouseLeave = useCallback(() => {
    clearTimeout(longPressTimerRef.current);
    setIsLongPress(false);
    setWasUnchecked(false);
  }, []);;

  const handleTouchStart = useCallback((e) => {
    e.preventDefault();
    
    if (!hasMoved.current){
      longPressTimerRef.current = setTimeout(() => {
        setIsLongPress(true);
        if (navigator.vibrate){
          navigator.vibrate(100);
        }
        toggleCheckedErrands(errand, index, currentParticipant);
      }, LONG_PRESS_DURATION);
    }
  }, [currentParticipant, errand, index, toggleCheckedErrands]);

  const handleTouchMove = useCallback((e) => {
    hasMoved.current = true; // Mark that movement occurred
    clearTimeout(longPressTimerRef.current);
    setIsLongPress(false);
    setWasUnchecked(false);
  }, []);

  const handleTouchEnd = useCallback(
    (e) => {
      e.preventDefault();

      if (!hasMoved.current && !isLongPress && checkedErrandsLength > 0) {
        toggleCheckedErrands(errand, index, currentParticipant);
        setWasUnchecked(true);
      }

      clearTimeout(longPressTimerRef.current);
      if (!hasMoved.current && !isLongPress && !wasUnchecked && checkedErrandsLength === 0) {
        handleSelectErrand(index);
      }
      setIsLongPress(false);
      setWasUnchecked(false);
      hasMoved.current = false;
    },
    [
      checkedErrandsLength,
      currentParticipant,
      errand,
      handleSelectErrand,
      index,
      isLongPress,
      toggleCheckedErrands,
      wasUnchecked,
    ]
  );

  const handleClose = useCallback(async (e) => {
    if (!tpConsentGiven || !returnConsentGiven) return;
    try {
      e.stopPropagation();
  
      chatBulkClose([errand]);

    } catch (err) {
      console.error('Errand.tsx: handleClose()', err);
    }
  }, [errand, chatBulkClose, tpConsentGiven, returnConsentGiven]);

  const handleEditErrand = useCallback(
    (e) => {
      e.stopPropagation();
      // hide the errands drawer on mobile devices. On desktop it is always visible
      drawerRef.current?.click();
      if (!tpConsentGiven || !returnConsentGiven){
        handleShakingConsent();
        return;
      } else {
        editErrandId.current = errand._id;
        errandColorRef.current = errand.color ?? null;
        setRootMorphType((prev): MorphType => {
          // Get the ID of the errand that is currently selected or use the id of the primary errand if split screen
          // so that we know which screen to have the footer be morphed on
          const errand = errands[selectedIndex[selectedIndex.length - 1]];

          if (!errand) return prev;

          const primaryErrand = errands[0];

          if (!primaryErrand) return prev;

          morphedId.current = selectedIndex.length === 2 ? primaryErrand._id : errand._id;
          if (prev === MorphType.ErrandEdit) {
            return MorphType.None;
          }
          return MorphType.ErrandEdit;
        });
        if (checkedErrandsLength=== 1) {
          toggleCheckedErrands(errand, index, currentParticipant);
        }
      }
    },
    [
      checkedErrandsLength,
      currentParticipant,
      drawerRef,
      editErrandId,
      errand,
      errandColorRef,
      errands,
      handleShakingConsent,
      index,
      selectedIndex,
      morphedId,
      setRootMorphType,
      toggleCheckedErrands,
      tpConsentGiven,
      returnConsentGiven
    ]
  );

  useEffect(() => {
    return () => {
      clearTimeout(longPressTimerRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className={[
      Styles.errand,
      ...(currentParticipant && currentParticipant?.messageHistoryAllowed ? [Styles.messageHistoryAllowed] : []),
      ...(currentParticipant && currentParticipant.primary ? [Styles.primary] : []),
      ...(errand.isDefault ? [Styles.isDefault] : []),
      ...(isSelected ? [Styles.isSelected] : []),
      ...(isDragging ? [Styles.isDragging] : []),
      ...(isChecked ? [Styles.isChecked] : []),
      ...(isMatch ? [Styles.isMatch] : []),
      ...(!tpConsentGiven || !returnConsentGiven ? [Styles.disabled] : [])
    ].join(' ')} onClick={(!tpConsentGiven || !returnConsentGiven) ? handleShakingConsent : null}>
      <div
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
      >
        <button>
          <Badge
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            badgeContent={
              props.notifications?.filter((x) => [x.chat?._id, x.messageId?.chat].includes(errand._id)).length
            }
            color="error"
            max={9}>
            <Avatar src={avatar} className={Styles.avatar} sx={{ width: '30px', height: '30px', background: errand.isDefault ? 'var(--shadow000)' : errand.color ? `${errand.color}` : 'var(--gray200)' }}></Avatar>
          </Badge>
          <div className={Styles.waveBg}><span /></div>
          <div className={Styles.waveMiddle}></div>
        </button>
        <button
        >
          <h2>{(errand.type === 'form' && errand.name.includes('TEMPLATEMGAI') ? 'Ai-Sign' : errand.displayName) || 'AngelAi'}</h2>
          <p className={Styles.errandPreview}>{preview}</p>
        </button>
      </div>
        <footer 
          onClick={(!tpConsentGiven || !returnConsentGiven) ? handleShakingConsent : null}
          className={!tpConsentGiven || !returnConsentGiven ? Styles.disabled : isChecked ? Styles.showFooter : isMobileOrTablet() ? Styles.hideFooter : undefined}>
          <button {...props.dragHandleProps}><svg/><DragIndicator /> {t('tMove')}</button>
          <button onClick={handleEditErrand}><BorderColor /> {t('tEdit')}</button>
          <button onClick={handleClose}>
            <Delete />
            {currentParticipant && currentParticipant.primary ? t('closeButton') : t('leaveButton')}
          </button>
        </footer>
    </section>
  );
};

export default Errand;
