/*
==================================================================================
  This file serves as configuration for language support. Here the module is
  initialized, then exported as a module and bundled in with index.js.
  When the root of the react app is run, language control is given to any
  component app-wide.
==================================================================================
*/

import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from '../Assets/Languages/en.json';

/**
 * Dynamic loader for translations
 * @param {string} lng - Language code (e.g., 'en', 'es')
 * @returns {Promise<object>} - A promise that resolves to the translation object
 */
const loadResources = async (lng) => {
  try {
    const translation = await import(`../Assets/Languages/${lng}.json`);
    return { translation: translation.default };
  } catch (error) {
    console.error(`Failed to load translations for language: ${lng}`, error);
    return { translation: {} }; // Return an empty object as a fallback
  }
};

const resources = {
  en: {
    translation: enTranslation,
  },
};

/*
=======================================================================
  Language detector will automatically detect the client's
  language and set it site-wide.

  A language can be passed as a query parameter:
  ?lng=en <--- this will set language to english
  ?lng=es <--- this does the same for spanish

  NOTE: This query parameter CAN be chained with other parameters:
  ?theme=main&?lng=es <--- Theme is main and language is spanish

  Additionally, localStorage can be used to detect/set language:
  i18nextLng: "en"  <--- this will set language to english
  i18nextLng: "es"  <--- this does the same for spanish

  When the user first accesses the app, the browser's default
  language will set in localStorage. This will persist between
  sessions.

  There are many different ways the preferred language can be detected
  For additional methods of detecting the client's language
  See: https://github.com/i18next/i18next-browser-languageDetector
 ======================================================================
*/

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources,
  });

// Load the initial language dynamically
i18n.on('languageChanged', async (lng) => {
  if (!i18n.hasResourceBundle(lng, 'translation')) {
    const resources = await loadResources(lng);
    i18n.addResourceBundle(lng, 'translation', resources.translation);
  }
});

export default i18n;
