import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// Internal dependencies
import './index.css';
import '@common/i18n';
import '@assets/Fonts/HoneyScript.ttf';
import { Provider } from 'react-redux'
import { store } from './store';
import { App } from './App';

const baseName = process.env.REACT_APP_MORGAN_BASENAME || process.env.REACT_APP_PUBLIC_URL;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter basename={process.env.REACT_APP_MORGAN_BASENAME || process.env.REACT_APP_PUBLIC_URL}>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// if (process.env.REACT_APP_SERVICE_WORKER_ENABLED === 'true') {
//   console.log("Default SW being registered");
//   serviceWorkerRegistration.register();
// }
