import React, {
  forwardRef,
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useSwipeable } from 'react-swipeable';
// import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import axiosCall from '@services/axios';
import { Styles, MSDivider } from '@styles/MultiSelectFieldStyles';
import ActionControls from '@components/ActionControls';
import {
  UserRole,
  LoanPurpose,
  LoanType,
  LoanTerm,
  LeadSource,
  PropertyOccupancy,
  PropertyState,
  PropertyType,
  TShirtColor,
  TShirtSize,
  CreditPullIcon,
  CompensationType,
  AppraisalType
} from '@assets/Icons';
import { CReqState, REQ_STATES, TReqState } from '@common/msgUtils';
import useAbortController from '@common/hooks/useAbortController';
import { useFooterContext } from '@contexts/FooterContext';
import useThrottle from '@common/hooks/useThrottle';
import LanguageUtils from '@common/LanguageUtils';
import useDebouncedScrollHandler from '@common/hooks/useDebouncedScrollHandler';
import { isMobileOrTablet } from '@common/deviceTypeHelper';
import { copyObj } from '@common/userMessagesUtils';
import { Timeout } from 'react-number-format/types/types';
import useTimeoutStorage from '@common/hooks/useTimeoutStorage';
import { useHasMounted } from '@common/hooks/useHasMounted';
import { ValidatorFunctions } from '@common/Validators';
import getImageSource from '@common/getImageSource';
import { CircularProgress } from '@mui/material';

const ScrollWheelGearsDown = process.env.REACT_APP_MORGAN_CDN + '/Images/ScrollWheelGearsDown.gif';
const ScrollWheelGearsUp = process.env.REACT_APP_MORGAN_CDN + '/Images/ScrollWheelGearsUp.gif';
const ScrollWheelGearsStatic = process.env.REACT_APP_MORGAN_CDN + '/Images/ScrollWheelGearsStatic.png';

const QIDs: Record<string, QID> = {
  MORGAN_STATE: 'MORGAN.STATE',
  TAB8_CD_031: 'TAB8_CD.031',
  MORGAN_LOANTYPE: 'MORGAN.LOANTYPE',
  MORGAN_TSIZE: 'MORGAN.TSIZE',
  MORGAN_TCOLOR: 'MORGAN.TCOLOR',
  MORGAN_ROLE: 'MORGAN.ROLE',
  FieldEndpoint_LoanNumberApplications: 'FieldEndpoint.LoanNumberApplications',
  CREDITPULLTYPE: 'CREDITPULLTYPE',
  FieldEndpoint_LoanProducts: 'FieldEndpoint.LoanProducts',
};

const TSIZE = {
  L: 'L',
  S: 'S',
  M: 'M',
  XL: 'XL',
  XXL: 'XXL',
};

type QID =
  | 'MORGAN.STATE'
  | 'MORGAN.LOANPURP'
  | 'MSC4_CD.010' // property type
  | 'TAB8_CD.031' // loan purpose
  | 'MSCF_CD.002' // loan type
  | 'MORGAN.LOANTYPE'
  | 'MORGAN.TSIZE'
  | 'MORGAN.TCOLOR'
  | 'MORGAN.ROLE'
  | 'CMP4_CD.019' // role
  | 'FieldEndpoint.LoanNumberApplications'
  | 'CREDITPULLTYPE'
  | 'FieldEndpoint.LoanProducts';

type Options = {
  description: string;
  data: string;
}[];

// returns new array based on given arr (options of TSIZE), in correct order
const getReorderedTSIZEOptions = (optionsArr: Options) => {
  const optionsObj = {};
  // assign by sizes
  for (const option of optionsArr) {
    const sizeKey = option.data;
    optionsObj[sizeKey] = option;
  }
  // return needed arr in correct order
  return [optionsObj[TSIZE.L], optionsObj[TSIZE.M], optionsObj[TSIZE.S], optionsObj[TSIZE.XXL], optionsObj[TSIZE.XL]];
};

const QIDOptionsModifiers = {
  [QIDs.MORGAN_TSIZE]: getReorderedTSIZEOptions,
};

// reorders the formattedOptions array by reference.
function transformOptionsOf(_QID: QID, formattedOptions: Options) {
  const tranformFn = QIDOptionsModifiers[_QID];
  if (tranformFn) return tranformFn(formattedOptions);
  else console.warn(`No reorder function present for QID: ${_QID}`);
}
/**
 * Gets the action.
 *
 * @todo Abstract into a utils function.
 *
 * @author Joshua Brusa <joshua.brusa@swmc.com>
 */
async function getAction(
  { actionId }: { actionId: string },
  config: { signal: AbortSignal }
): Promise<Core.Action | null> {
  try {
    return await axiosCall({ url: `action/${actionId}` }, config);
  } catch (err) {
    console.error(err);
    return null;
  }
}

/**
 * Gets the field endpoint options.
 * A switch as there can be many different types of field endpoints.
 * Used for programmatic options.
 *
 * @author Joshua Brusa <joshua.brusa@swmc.com>
 */
async function getFieldEndpointOptions(
  {
    fieldEndpoint,
    chatId,
  }: {
    fieldEndpoint: string;
    chatId: string;
  },
  config: { signal: AbortSignal }
): Promise<Core.FieldEndpoint | null> {
  try {
    switch (fieldEndpoint) {
      case 'loanNumberApplications':
        return await axiosCall({ url: `fieldEndpoint/${fieldEndpoint}?chatId=${chatId}` }, config);
      case 'loanProducts':
        return await axiosCall({ url: `fieldEndpoint/${fieldEndpoint}?chatId=${chatId}` }, config);
      case 'loanProductLockPeriods':
        return await axiosCall({ url: `fieldEndpoint/${fieldEndpoint}?chatId=${chatId}` }, config);
      case 'loanProductRates':
        return await axiosCall({ url: `fieldEndpoint/${fieldEndpoint}?chatId=${chatId}` }, config);
      case 'ticketAttributeToChange':
        return await axiosCall({ url: `fieldEndpoint/${fieldEndpoint}?chatId=${chatId}` }, config);
      case 'ticketToEdit':
        return await axiosCall({ url: `fieldEndpoint/${fieldEndpoint}?chatId=${chatId}` }, config);
      default:
        throw new Error(`Invalid field endpoint: ${fieldEndpoint}`);
    }
  } catch (err) {
    console.error(err);
    return null;
  }
}

/**
 * Gets the qid options.
 * Used for static options.
 *
 * @author Joshua Brusa <joshua.brusa@swmc.com>
 */
async function getQidOptions(
  { qid }: { qid: string },
  { actionId }: { actionId: string },
  config: { signal: AbortSignal }
): Promise<Core.Qid | null> {
  try {
    return await axiosCall({ url: `qid/${qid}`, method: 'POST', data: { actionId } }, config);
  } catch (err) {
    console.error(err);
    return null;
  }
}

async function translateOptions(options) {
  try {
    const language = LanguageUtils.fetchLocalizationLanguageSetting() || 'en';
    if (!LanguageUtils.fetchTranslationEnabledSetting()) return options;
    if (language === 'en') return options;
    const translatedOptions = [];
    for await (const option of options) {
      const translatedOption = { ...option };
      if (option.description) {
        try {
          const description = await LanguageUtils.translateOne(option.description, language);
          if (description) {
            translatedOption.description = description;
          }
        } catch (err) {
          console.error(err);
        }
      }
      translatedOptions.push(translatedOption);
    }
    return translatedOptions;
  } catch (err) {
    console.error(`Could not translate prompts: `, options, err);
    return options;
  }
}

/**
 * Gets the options. Returns the values in a useable way for the UI.
 * If there is a field endpoint get those options instead.
 *
 * @author Joshua Brusa <joshua.brusa@swmc.com>
 */
async function getOptions(
  {
    qid,
    actionId,
    fieldEndpoint,
    chatId,
  }: {
    qid: string;
    actionId: string;
    fieldEndpoint: string;
    chatId: string;
  },
  config: { signal: AbortSignal }
): Promise<Options | null> {
  if (fieldEndpoint) {
    const options = await getFieldEndpointOptions({ fieldEndpoint, chatId }, config);

    if (!options) {
      return null;
    }

    const translatedOptions = await translateOptions(options);

    return translatedOptions.map((option) => {
      return { description: option.description, data: typeof option.data === "string" ? option.data : JSON.stringify(option.data) };
    });
  } else {
    const options = await getQidOptions({ qid }, { actionId }, config);

    if (!options) {
      return null;
    }

    const translatedOptions = await translateOptions(options);

    return translatedOptions.map((option) => {
      return { description: option.description, data: option.keyword };
    });
  }
}

/**
 * If there are little options populate with duplicates.
 *
 * @author Joshua Brusa <joshua.brusa@swmc.com>
 */
function formatOptions({ options }: { options: Options }): Options {
  switch (options.length) {
    case 1:
      return Array(6).fill(options).flat();
    case 2:
      return Array(3).fill(options).flat();
    case 3:
      return Array(2).fill(options).flat();
    default:
      return options;
  }
}

const DefaultIcon = (props) => {
  if (ValidatorFunctions.isNotUndefinedNorNull(props?.icon)
    && ValidatorFunctions.isTypeOfString(props?.icon)
    && ValidatorFunctions.isNotEmptyString(props?.icon)) {
    return (
      <img
        style={{
          height: '20px',
          width: '20px',
          filter: 'brightness(0) saturate(100%) invert(100%)',
        }}
        src={getImageSource(props.icon)}
        alt={`Action Icon`}
      />)
  } else {
    return <AppraisalType stroke={'var(--gray000)'} strokeWidth={'1'} />;
  }
}

const ScollOptionIconQID = (props) => {
  const { qid } = props;
  // if loading state, show loading circle
  if (props?.isLoading === true) {
    return <CircularProgress sx={{ color: 'white', width: '2em !important', height: "unset !important" }} />;
  }

  switch (qid) {
    case 'MORGAN.STATE':
      return <PropertyState stroke={'var(--gray000)'} strokeWidth={'1'} />;
    case 'TAB8_CD.031':
    case 'MORGAN.LOANPURP':
      return <LoanPurpose stroke={'var(--gray000)'} strokeWidth={'1'} />;
    case 'MSCF_CD.002':
    case 'MORGAN.LOANTYPE':
    case 'MORGAN.DOWNPMNT':
    case 'MORGAN.ALLDOWNP':
    case 'MORGAN.DOWNUSDA':
      return <LoanType stroke={'var(--gray000)'} strokeWidth={'1'} />;
    case 'MORGAN.LOANTERM':
      return <LoanTerm stroke={'var(--gray000)'} strokeWidth={'1'} />;
    case 'CUST.OCCUPANCY':
      return <PropertyOccupancy stroke={'var(--gray000)'} strokeWidth={'1'} />;
    case 'MORGAN.KYR_PROP':
    case 'MSC4_CD.010':
      return <PropertyType stroke={'var(--gray000)'} strokeWidth={'1'} />;
    case 'MORGAN.KYR_LEAD':
    case 'MASO_CD.010':
      return <LeadSource stroke={'var(--gray000)'} strokeWidth={'1'} />;
    case 'MORGAN.TSIZE':
      return <TShirtSize stroke={'var(--gray000)'} strokeWidth={'1'} />;
    case 'MORGAN.TCOLOR':
      return <TShirtColor stroke={'var(--gray000)'} strokeWidth={'1'} />;
    case 'CMP4_CD.019':
    case 'MORGAN.KYR_ROLE':
    case 'MORGAN.ROLE':
      return <UserRole stroke={'var(--gray000)'} strokeWidth={'1'} />;
    case 'MORGAN.FICO':
      return <CreditPullIcon className="WhiteIcon" />;
    case 'CUSTOM.005':
      return <CompensationType stroke={'var(--gray000)'} strokeWidth={'1'} />;
    case 'FieldEndpoint.LoanNumberApplications':
      return <CreditPullIcon className="WhiteIcon" />;
    case 'CREDITPULLTYPE':
      return <CreditPullIcon className="WhiteIcon" />;
    case 'MORGAN.INQTYPE':
      return <CreditPullIcon className="WhiteIcon" />;
    case 'FieldEndpoint.LoanProducts':
      return <LoanType stroke={'var(--gray000)'} strokeWidth={'1'} />;
    default:
      return <DefaultIcon icon={props?.icon} />;
  }
};

const WheelScoll = ({ scrollTheWheel, scrollDirectionRef, handleDragStart, onClickHandler }) => {
  const className = !scrollTheWheel
    ? 'scrollWheelGearsStatic'
    : scrollDirectionRef.current === 'up'
      ? 'scrollWheelGearsUp'
      : 'scrollWheelGearsDown';
  const alt = !scrollTheWheel ? 'ScrollwheelStatic' : 'ScrollwheelGif';
  const _onClickHandler = useCallback((e) => onClickHandler(e, true), [onClickHandler]);

  return (
    <img
      draggable
      onDragStart={handleDragStart}
      onClick={_onClickHandler}
      // onMouseLeave={handleMouseUp}
      className={className}
      src={
        !scrollTheWheel
          ? ScrollWheelGearsStatic
          : scrollDirectionRef.current === 'up'
            ? ScrollWheelGearsUp
            : ScrollWheelGearsDown
      }
      alt={alt}
    />
  );
};

const getInitialScrollOptions = () => {
  return [
    { description: '', data: '' },
    { description: 'Loading...', data: '' },
    { description: '', data: '' }
  ]
}

// Only for Desktop (meant for trackPad)
const handleMouseEnter = () => {
  // make page unscrollable (to remove that up/down weird movements on trackpad swipes)
  document.body.style.overflow = 'hidden';
}

const handleMouseLeave = () => {
  document.body.style.overflow = '';
}

/**
 * This component is loaded when the active action is a DROPDOWN.
 *
 * @todo Fix typing issues.
 * @todo Remove the bad practice hooks.
 * @todo Use context and reducer instead of refs to set the value that is sent.
 */
const MultiSelectField: React.FC<PropsWithChildren<any>> = forwardRef((props, ref) => {
  const bdLen = 6;
  const footerContext = useFooterContext();
  const [dataState, setDataState] = useState(0);
  const [throttledDataState, setThrottledDataState] = useState(0);
  const [scrollCount, setScrollCount] = useState(0);
  const [scrollOptions, setScrollOptions] = useState(getInitialScrollOptions());
  const [scrollTheWheel, setScrollTheWheel] = useState(false);
  const [QID, setQID] = useState('');
  const [isCustomUserValue, setIsCustomUserValue] = useState(false);
  const displayedIndexArr = useRef([1, 2, 3, 4, 5, 0]);
  const numberOfClicksRef = useRef(0);
  const editModeRef = useRef(false);
  const [editMode, setEditMode] = useState(false);
  const hasMounted = useHasMounted();
  const numberOfClicksResetTimeoutRef = useRef<Timeout | null>(null);
  const processInputClickTimeoutRef = useRef<Timeout | null>(null);
  const initialScrollDataRef = useRef<Options | []>([]);
  const scrollDirection = useRef(null);
  const readyToScroll = useRef(true);
  const carouselRef = useRef(null);
  const scrollWheelContainerRef = useRef(null);
  const abortController = useAbortController();
  const carouselWrapperRef = useRef(null);
  const timeoutStorage = useTimeoutStorage();
  const clearTimeoutsTMRef = useRef(null);
  const inputClickLockedRef = useRef(false);
  const inputClickLockTimeoutRef = useRef(null);
  const prevActionIdRef = useRef(null);
  const initialLoadCompletedRef = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rollerDims, setRollerDims] = useState({ width: 0, height: 0, left: 0 });
  const [handledDefaultQidOption, setHandledDefaultQidOption] = useState(false);

  const actionAreaStyleObj = {
    position: "fixed",
    width: `${rollerDims.width}px`,
    height: `${rollerDims.height + 100}px`,
    left: `${rollerDims.left}px`,
    zIndex: 98
  } as React.CSSProperties;

  // Extract message req state ref from props.
  // used for preventing Enter press from calling props.handleSubmit
  const msgReqStateRef: MutableRefObject<CReqState> = props.msgReqStateRef;

  const focusCarouselContainer = useCallback(() => {
    carouselRef.current && carouselRef.current.focus();
  }, []);

  /**
   * Fetch the options for the scroll wheel.
   * If there is an error in this process the action will be canceled using the passed function.
   *
   * @author Joshua Brusa <joshua.brusa@swmc.com>
   */
  const setUpOptions = () => {
    startResetCarouselState();
    setScrollOptions(getInitialScrollOptions()); // sets loading placeholders
    setIsLoading(true);
    // check props
    if (
      !props.errand ||
      !props.errand._id ||
      !props.errand.action ||
      !props.errand.action.action ||
      !props.errand.action.action._id
    ) {
      return;
    }

    abortController.reset();
    const config = abortController.get();

    // async function
    async function getScrollOptions() {
      try {
        setHandledDefaultQidOption(false);
        const actionIdSnapshot = props.errand.action.action._id;
        // if the same exact action is being loaded.
        if (prevActionIdRef.current === props.errand.action.action._id) {
          console.log("[Setting UP Options]: Same Action Encountered. Canceling the setup options call!")
          return;
        }
        // get action
        const action = await getAction({ actionId: props.errand.action.action._id }, config);

        // check action
        if (!action || !action.fieldQid) {
          // Check last actionID, if different return nothing OR initial load is not completed 
          if (initialLoadCompletedRef.current === false
            || prevActionIdRef.current === props.errand.action.action._id) {
            console.error("[Setting UP Options]: Canceling action, no action error!");
            await props.cancelAction();
          }
          // If not, just return nothing.
          return;
        }
        // get options
        const options = await getOptions(
          {
            qid: action.fieldQid,
            actionId: props.errand.action.action._id,
            fieldEndpoint: action.fieldEndpoint,
            chatId: props.errand._id,
          },
          config
        );

        // check options
        if (!options) {
          console.error("[Setting UP Options]: No options Error!");
          await props.cancelAction();
          return;
        }

        // set the type for the image
        setQID(action.fieldQid);

        /**
         * Format the options to work with the UI.
         * If there are too little populate with duplicate entries.
         */
        let formattedOptions = formatOptions({ options });

        // if MORGAN TSIZE options fetched, reorder it in correct way
        if (action.fieldQid === QIDs.MORGAN_TSIZE) {
          formattedOptions = transformOptionsOf(QIDs.MORGAN_TSIZE, formattedOptions);
        }
        // set the options
        setScrollOptions(formattedOptions);

        // write to initial scroll data buffer
        initialScrollDataRef.current = copyObj(formattedOptions);

        // set the curr to prev errand action id.
        prevActionIdRef.current = actionIdSnapshot;

        // loading finished
        setIsLoading(false);
      } catch (fetchDataError) {
        console.error('Some error occured during data fetching...', fetchDataError)
        console.error('[getScrollOptions function] Some error occured during data fetching...', {
          message: fetchDataError.message,
          stack: fetchDataError.stack
        });
        // loading finished
        setIsLoading(false);
      }
      // transition all 0.6s 
      finishResetCarouselState();
    }

    // call async function
    getScrollOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (initialLoadCompletedRef.current === false) initialLoadCompletedRef.current = true;
  };

  useEffect(() => {
    setUpOptions();
  }, [
    props?.errand?.action?.action?._id
  ])

  const clearAllLocalRefs = useCallback(() => {
    if (processInputClickTimeoutRef.current) clearTimeout(processInputClickTimeoutRef.current);
    if (numberOfClicksResetTimeoutRef.current) clearTimeout(numberOfClicksResetTimeoutRef.current);
    if (inputClickLockTimeoutRef.current) clearTimeout(inputClickLockTimeoutRef.current);
    if (clearTimeoutsTMRef.current) clearTimeout(clearTimeoutsTMRef.current);

    timeoutStorage.clearAllTimeouts();
    initialScrollDataRef.current = [];
  }, []);

  useEffect(() => {
    if (!hasMounted) return;
    // handle isMobileOrTablet logic
    const _isMobileOrTablet = isMobileOrTablet();
    if (_isMobileOrTablet === true) {
      setEditMode(true); // meaning this should always be true on mobile or tablet views.
    }
  }, [hasMounted])

  useEffect(() => {
    const handleEnterPress = (event) => {
      if (isLoading) return;
      const currMsgReqState = msgReqStateRef.current.getState() as TReqState;
      if (event.key === 'Enter') {
        // This check here serves for not sending multiple requests IF Enter is pressed multiple times.
        // check if the current message request is not in loading state.
        if (currMsgReqState !== REQ_STATES.LOADING) {
          // this will start the request
          props.handleSubmit();
        }
      }
    };

    window.addEventListener('keydown', handleEnterPress);

    // handle focus
    focusCarouselContainer();

    return () => {
      // remove enter press handler
      window.removeEventListener('keydown', handleEnterPress);
    };
  }, [props.handleSubmit, isLoading]);

  // Unmount
  useEffect(() => {
    // set the dimnsions to reflect on action area on mobile/tablet
    const { width, height, left } = carouselWrapperRef.current.getBoundingClientRect();
    setRollerDims({ width, height, left });

    return () => {
      // make sure send button is removed back to ballerina after component unmounts
      setTimeout(() => {
        footerContext.sendButtonRef.current?.update('');
        // make sure we have the scrollable page again.
        handleMouseLeave();
      }, 0)

      clearAllLocalRefs();
    }
  }, [])

  useEffect(() => {
    focusCarouselContainer();
  }, [scrollOptions.length]);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => { footerContext.sendButtonRef.current?.update('') }, 0);
      return;
    }
    const firstOptionDescriptionText = scrollOptions?.[0]?.description || 'default value';
    // trigger the send button animation by updating these
    setTimeout(() => {
      footerContext.sendButtonRef.current?.update(firstOptionDescriptionText);
    }, 0)
  }, [scrollOptions, isLoading])

  useImperativeHandle(
    ref,
    () => {
      return {
        getValue: scrollOptions[displayedIndexArr.current[integerModuleHelper(dataState, bdLen)]],
        isCustomUserValue: isCustomUserValue
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dataState, scrollOptions, isCustomUserValue]
  );

  const handleDragStart = (e) => {
    e.preventDefault();
  };

  const handleFooterScroll = (e) => {
    setScrollCount((prev) => prev + e?.deltaY);
  };

  const handleScrollCount = useCallback(() => {
    if (isLoading) return;
    if (scrollCount === 0) return;
    /**
     * If there are no options and the user scrolls the options will not populate.
     * To prevent this if there are no options the scroll will be disabled.
    */
    if (!scrollOptions.length) {
      return;
    }

    if (!readyToScroll.current) {
      updateCarouselState();
      return resetScroll();
    }

    let dir = scrollCount > 0 ? 'up' : 'down';

    setDataState(scrollCount > 0 ? throttledDataState + 1 : throttledDataState - 1);

    setScrollCount(0);
    handleIndexReplacement(dir);
    handleOptionsValues();
    updateCarouselState();
    resetScroll();
    resetEditMode();
    scrollDirection.current = dir;
  }, [scrollCount, throttledDataState]);

  useThrottle(handleScrollCount, 50);
  const debouncedHandleFooterScroll = useDebouncedScrollHandler(handleFooterScroll, 100);

  const handleThrottledDataState = useCallback(() => {
    setThrottledDataState(dataState);
  }, [dataState]);

  useThrottle(handleThrottledDataState, 50);

  const integerModuleHelper = (inputNumber, inputModule) => {
    return ((inputNumber % inputModule) + inputModule) % inputModule;
  };

  const handleIndexReplacement = (dir) => {
    setDataState((prev) => {
      const currentIndex = integerModuleHelper(prev, bdLen);
      let prevSpotInSixHole;
      let prevSpotInOptionArray;

      if (dir === 'up') {
        prevSpotInSixHole = integerModuleHelper(currentIndex + 1, bdLen);
        prevSpotInOptionArray = integerModuleHelper(displayedIndexArr.current[currentIndex] + 1, scrollOptions.length);
      } else {
        prevSpotInSixHole = integerModuleHelper(currentIndex - 1, bdLen);
        prevSpotInOptionArray = integerModuleHelper(displayedIndexArr.current[currentIndex] - 1, scrollOptions.length);
      }

      displayedIndexArr.current[prevSpotInSixHole] = prevSpotInOptionArray;
      return prev;
    });
  };

  const handleTransitionEnd = (e) => {
    if (e.target === e.currentTarget) {
      setScrollTheWheel(false);
      if (scrollDirection.current) {
        scrollDirection.current = null;
      }
    }
  };

  const startResetCarouselState = () => {
    // make transition instant
    carouselRef.current.style.transition = "none";
    displayedIndexArr.current = [1, 2, 3, 4, 5, 0];
    updateCarouselState(0); // update the actual rotated angle
    setDataState(0); // set the angle multiplier (0) is initial rotating position while also updating the state
  }

  const finishResetCarouselState = () => {
    carouselRef.current.style.transition = "all 0.6s";
  }

  const updateCarouselState = (val = null) => {
    const preAngle = val ?? -(360 / bdLen).toFixed(1);
    setDataState((prev) => {
      if (carouselRef.current && carouselRef.current.style) {
        // based on the updatedIncrement, and number of sides, we calculate and apply
        // transformation, rotating the carousel.
        carouselRef.current.style.transform = 'rotateX(' + prev * preAngle + 'deg)';
        carouselRef.current.style.webkitTranform = 'rotateX(' + prev * preAngle + 'deg)';
        carouselRef.current.style.mozTransform = 'rotateX(' + prev * preAngle + 'deg)';
      }
      return prev;
    });
  };

  const resetScroll = () => {
    if (props.fieldAttribute?.description !== 'DROPDOWN') return;
    if (scrollOptions?.length <= 1 || !scrollOptions) return;

    readyToScroll.current = true;
    if (!scrollTheWheel) {
      setScrollTheWheel(true);
    }
  };

  // Handle Upper/Bottom Part of the Scrollwheel 
  // (To navigate UP or DOWN depending on which part was clicked)
  const handleEdgeClick = (e) => {
    const clickCoords = {
      x: e.event.clientX,
      y: e.event.clientY,
    };
    // Y boundaries
    const greyAreaYstart = scrollWheelContainerRef.current.getBoundingClientRect().y;
    const greyAreaYend =
      scrollWheelContainerRef.current.getBoundingClientRect().y + scrollWheelContainerRef.current.getBoundingClientRect().height;
    // X boundaries
    const greyAreaXEnd =
      carouselWrapperRef.current.getBoundingClientRect().x + carouselWrapperRef.current.getBoundingClientRect().width;
    const greyAreaXStart = carouselWrapperRef.current.getBoundingClientRect().x + 65; // 65 is 24 padding + 25 width of icon + 15 hr width
    const isWithinInputElementArea =
      clickCoords.y > greyAreaYstart &&
      clickCoords.y < greyAreaYend &&
      clickCoords.x < greyAreaXEnd &&
      clickCoords.x > greyAreaXStart;
    // only if not within grey area (where user clicks and nothing happens)
    if (isWithinInputElementArea === false) {
      if (clickCoords.y < greyAreaYstart) {
        // upper part clicked
        handleNavigation('up');
      } else if (clickCoords.y > greyAreaYend) {
        // bottom part clicked
        handleNavigation('down');
      } else {
        if (isMobileOrTablet() === true) return;
        if (clickCoords.x <= greyAreaXStart) {
          // if user clicks in the area where 
          // the space between gear icon and input startX is.
          processInputClick(e, true);
        }
      }
    }
    // Within input area
    else {
      if (isMobileOrTablet() === true) return;
      onInputClick(e);
    }
  }

  const handleOptionsValues = useCallback(() => {
    if (isCustomUserValue === true) {
      // reset the options
      setScrollOptions(copyObj(initialScrollDataRef.current));
      setIsCustomUserValue(false);
    }
  }, [isCustomUserValue])

  const handleNavigation = (dir: 'up' | 'down') => {
    if (isLoading) return;
    const addon = dir === 'up' ? 1 : -1;
    setDataState((prev) => prev + addon);
    handleIndexReplacement(dir);
    handleOptionsValues();
    updateCarouselState();
    resetScroll();
    if (isMobileOrTablet() === false) resetEditMode();
    scrollDirection.current = dir;
  };

  const handleMultipleScrollsMobile = (e, dir) => {
    let cnt = Math.round(e.velocity);
    cnt = cnt === 0 ? 1 : cnt;
    handleLongScroll(cnt, dir);
  }

  const containerHandlers = useSwipeable({
    onTap: (e) => {
      // if not mobile nor tablet
      handleEdgeClick(e);
      if (
        props.fieldAttribute?.description !== 'DROPDOWN' ||
        (e.event.target as HTMLElement).className === 'inputSpan'
      ) {
        return;
      }
      if (props.isDesktop) {
        handleNavigation('down');
      }
    },
    onSwipedUp: (e) => {
      if (props.fieldAttribute?.description !== 'DROPDOWN') {
        return;
      }
      handleMultipleScrollsMobile(e, "down");
      e.event.stopPropagation();
    },
    onSwipedDown: (e) => {
      if (props.fieldAttribute?.description !== 'DROPDOWN') {
        return;
      }
      handleMultipleScrollsMobile(e, "up");
      e.event.stopPropagation();
    },
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const actionHandlers = useSwipeable({
    onSwipedUp: (e) => {
      if (props.fieldAttribute?.description !== 'DROPDOWN') {
        return;
      }
      handleMultipleScrollsMobile(e, "down");
      e.event.stopPropagation();
    },
    onSwipedDown: (e) => {
      if (props.fieldAttribute?.description !== 'DROPDOWN') {
        return;
      }
      handleMultipleScrollsMobile(e, "up");
      e.event.stopPropagation();
    },
    preventScrollOnSwipe: true,
    trackMouse: true,
  })

  const resetEditMode = () => {
    if (isMobileOrTablet() === true) return;
    editModeRef.current = false;
    setEditMode(false);
  }

  const handleArrowKeys = (e) => {
    if (e.key === 'ArrowUp') {
      handleNavigation('up');
      resetEditMode();
    } else if (e.key === 'ArrowDown') {
      handleNavigation('down');
      resetEditMode();
    }
  };

  const onInputChange = useCallback(
    (e, i) => {
      if (editMode === false) return;
      const currVal = e.target.value;
      scrollOptions[i].description = currVal;
      setScrollOptions([...scrollOptions]);
      // check if its not the same as the initial
      // if its the same, then no custom input should be submitted
      const isTheSameValue = initialScrollDataRef.current[i]?.description === currVal;
      if (isTheSameValue) {
        // nothing changed, ignore changes
        setIsCustomUserValue(false);
      } else {
        // custom input detected
        setIsCustomUserValue(true);
      }
    },
    [scrollOptions, editMode, setScrollOptions, setIsCustomUserValue]
  );

  const getInputClickLockState = useCallback(() => {
    if (inputClickLockedRef.current === true) return true;
    inputClickLockedRef.current = true;
    if (inputClickLockTimeoutRef.current) {
      clearTimeout(inputClickLockTimeoutRef.current);
      inputClickLockTimeoutRef.current = null;
    }
    inputClickLockTimeoutRef.current = setTimeout(() => {
      inputClickLockedRef.current = false;
    }, 50)
    return false;
  }, [])

  const onInputClick = (e) => {
    // check if inputClick is locked
    const locked = getInputClickLockState();
    if (locked) return;
    // set timer for catching second click
    numberOfClicksRef.current += 1;
    if (numberOfClicksResetTimeoutRef.current) clearTimeout(numberOfClicksResetTimeoutRef.current);
    // clear out in some time
    numberOfClicksResetTimeoutRef.current = setTimeout(() => {
      numberOfClicksRef.current = 0;
    }, 250); // 300 miliseconds


    let error = false;
    try {
      // check for double click
      if (numberOfClicksRef.current >= 2) {
        const len = document.activeElement?.value?.length;
        // Handle the case where len is undefined. 
        // For example, user somehow double clicked on wheel itself.
        if (len && typeof len === 'number') {
          // set the caret to appear at the end of the value in current input
          // handle case when setSelectionRange is undefined
          if (document.activeElement.setSelectionRange) {
            document.activeElement.setSelectionRange(len, len);
          } else {
            return;
          }
        }
        else {
          return;
        }

        // enable input
        editModeRef.current = true;
        setEditMode(true);
      }
    } catch (err) {
      error = true;
      console.error(err);
    }

    if (error === false) {
      // handle actual click process
      if (processInputClickTimeoutRef.current) clearTimeout(processInputClickTimeoutRef.current);
      processInputClickTimeoutRef.current = setTimeout(() => {
        processInputClick(e);
      }, 300);
    }

  }

  const processInputClick = (e, bypassEditMode = false) => {
    const locked = getInputClickLockState();
    if (locked) return;

    if (e && e.stopPropagation) e?.stopPropagation();
    if (editModeRef.current === false || bypassEditMode === true) {
      if (carouselRef.current) {
        // navigate down
        handleNavigation("down");
      }
    }
  }

  const handleLongScroll = (cnt, dir) => {
    for (let i = 0; i < cnt; i++) {
      timeoutStorage.addTimeout(setTimeout(() => handleNavigation(dir), 50 * (i + 1)));
    }
  }

  const onWheelHandler =
    isMobileOrTablet() === true
      ? handleFooterScroll
      : (e) => debouncedHandleFooterScroll(e, handleLongScroll, timeoutStorage);

  const inputClassStr = [`inputSpan`, editMode === false ? 'noCaret' : ''].join(' ');

  const handleDefaultQidOption = () => {
    const action = props?.errand?.action;
    if (!action?.action?.defaultQidOption) {
      // No default specified
      return;
    }
    const defaultOptionIndex = scrollOptions.findIndex((option) => option.data === action.action.defaultQidOption);
    if (defaultOptionIndex <= 0) {
      // The default option was not found or is already the first option
      return;
    }
    // First option should be the default
    displayedIndexArr.current[0] = defaultOptionIndex;
    // Second option should be the one after the default
    displayedIndexArr.current[1] = (defaultOptionIndex + 1 >= scrollOptions.length) ?
      0 :
      defaultOptionIndex + 1;
    // Last (previous) option should be the one before the default
    displayedIndexArr.current[displayedIndexArr.current.length - 1] = defaultOptionIndex - 1;
  }

  const renderOptions = () => {
    if (!isLoading && !handledDefaultQidOption) {
      // If options have just loaded and the action has a default option
      // specified, render the default option as the first one
      handleDefaultQidOption();
      setHandledDefaultQidOption(true);
    }
    return displayedIndexArr.current.map((i, index) => (
      <figure key={index}>
        {
          window.innerWidth > 375 && (
            <>
              <div className="StyledOptionIcon">
                <ScollOptionIconQID
                  qid={QID}
                  icon={props?.errand?.action?.actionIcon ?? props?.errand?.action?.action?.actionIcon}
                  isLoading={isLoading}
                />
              </div>
              <MSDivider orientation="vertical" />
            </>
          )
        }
        <form className="figureForm" onSubmit={props.handleSubmit}>
          <input
            className={inputClassStr}
            value={scrollOptions[i]?.description || ''} // description of the QID however the keyword will be submitted.
            onChange={(e) => onInputChange(e, i)}
          />
        </form>
      </figure>
    ))
  }

  return (
    <Styles
      {...containerHandlers}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onWheel={onWheelHandler}
      onKeyDown={handleArrowKeys}
    >
      <div
        className={'scrollWheelContainer isScrolling'}
        ref={scrollWheelContainerRef}
      >
        <WheelScoll
          scrollTheWheel={scrollTheWheel}
          scrollDirectionRef={scrollDirection}
          handleDragStart={handleDragStart}
          onClickHandler={processInputClick}
        />
      </div>
      {isMobileOrTablet() ? (<div style={actionAreaStyleObj} className="action-area" {...actionHandlers}></div>) : null}
      <div className="carousel-wrapper" ref={carouselWrapperRef}>
        <div
          className="carousel"
          onTransitionEnd={handleTransitionEnd}
          tabIndex={-1}
          ref={carouselRef}
          data-state={`${integerModuleHelper(dataState, bdLen) + 1}`} // typeof number
          data-displayedIndexArr={`${displayedIndexArr.current}`} // typeof number
          data-options={`${scrollOptions}`} // ['s', 'm', 'l'];
          data-value={`${scrollOptions[displayedIndexArr[dataState]]}`} // undefined, but replace dataState with 1: 's'
        >
          {renderOptions()}
        </div>
      </div>
      <ActionControls cancelAction={props.cancelAction} isPrivate={props.isPrivate} setJustClickedCancel={props.setJustClickedCancel} />
    </Styles>
  );
});

export default MultiSelectField;
