import React, { useCallback, useEffect, useState } from "react";
import axiosCall from "@services/axios";
import { translateOptions } from "@common/TranslateQIDOptions";

import type { Option } from "@components/MorphSwiper/Types";

type UseQIDOptionsFromActionId = (actionId: string, translated?: boolean) => [Option[], boolean];

const useQIDOptionsFromActionId: UseQIDOptionsFromActionId = (actionId, translated = true) => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState<Option[]>([]);

  const getOptionsFromActionId = useCallback(async () => {
    const action = await axiosCall({ url: `action/${actionId}` }).catch(() => ({ fieldQid: null}));
    const QID = action.fieldQid;
    const responseOptions = await axiosCall({ url: `qid/${QID}`, method: 'POST', data: { actionId} }).catch(() => []);

    if (translated) {
      const translatedOptions = await translateOptions(responseOptions);

      setOptions(translatedOptions);
    } else {
      setOptions(responseOptions);
    }

    setLoading(false);
  }, [actionId, translated]);

  useEffect(() => {
    getOptionsFromActionId();

  }, [getOptionsFromActionId])

  return [options, loading];
}

export { useQIDOptionsFromActionId };
export type { UseQIDOptionsFromActionId };
