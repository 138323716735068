import { keyframes } from "@mui/material";

const firstRevealAnimation = keyframes`
  0%, 50%, 100% {
    transform: translateY(0);
  }
  25% {
    transform: translateX(15px);
  }
  75% {
    transform: translateX(15px);
  }
`;

const Animations = {
    firstRevealAnimation,
}

export {
    Animations
}