import React, { useRef } from 'react';
import { isMobile } from "@common/deviceTypeHelper";
import { useState, useMemo, useEffect } from "react";
import { TSubContent } from "../types";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { VERY_SMALL_DEVICE_SHIFT_VALUE } from './SubContent';

interface IOpenButtonProps {
    subContent: TSubContent;
    show: boolean;
    isVerySmallDevice: boolean;
    isFullyRevealed: boolean;
    isRevealed: boolean;
    rightOffsetPos: number;
    onClick: () => void;
    isFullyClosed: boolean;
    rightPosAddon: number;
}

const OpenBtnBaseStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    transition: 'all 0.3s ease-out',
    cursor: 'pointer',
    position: "absolute",
}
const OpenBtnStyles = {
    default: (rightPos, opacity) => ({
        ...OpenBtnBaseStyle,
        backgroundColor: "var(--peach700)",
        width: "12px",
        height: "40px",
        borderRadius: "0 10px 10px 0px",
        top: "43%",
        right: `${rightPos}px`,
        opacity: opacity,
        zIndex: -1
    }) as React.CSSProperties
}

const OpenButton = (props: IOpenButtonProps) => {
    const closedPos = 0;
    const openPos = (props.rightOffsetPos - 12); // rightOffsetPos will be negative

    const [rightPos, setRightPos] = useState(closedPos);
    const [opacity, setOpacity] = useState(0);
    const [localRightPosAddon, setLocalRightPosAddon] = useState(0);
    const isMobileView = useMemo(() => isMobile(), []);
    const delayedLocalRightPosTmRef = useRef(null);

    useEffect(() => {
        if (props.isFullyClosed && !props.isRevealed) {
            // reveal
            setOpacity(1);
            setRightPos(openPos);
        } else {
            // hide
            setOpacity(0);
            setRightPos(closedPos);
        }
    }, [props.isFullyClosed, props.isRevealed])

    useEffect(() => {
        // artificial delay for smoothnes of OPEN animation because
        // it is faster then the SLIDEOUT peek animation.
        if(props.rightPosAddon !== 0) {
            delayedLocalRightPosTmRef.current = setTimeout(() => {
                setLocalRightPosAddon(props.rightPosAddon);
            }, 150);
        } else {
            setLocalRightPosAddon(0);
        }

        return () => {
            clearTimeout(delayedLocalRightPosTmRef.current);
        }
    }, [props.rightPosAddon])

    if (!props.show) {
        return <></>;
    }

    const onClickHandler = () => {
        props.onClick();
    }

    const renderOpenIcon = () => {
        if (isMobileView) {
            return <ChevronRightIcon sx={{ width: '20px', height: '20px' }} />
        }
        else {
            return ">"
        }
    }

    const openBtnStyle = OpenBtnStyles.default(rightPos + localRightPosAddon, opacity);

    return (
        <div style={openBtnStyle} onTouchEnd={onClickHandler} onClick={onClickHandler}>
            {renderOpenIcon()}
        </div>
    )
}

export {
    OpenButton
}