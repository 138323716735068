import getImageSource from './getImageSource';
import axiosCall from '../Services/axios';
import LanguageUtils from './LanguageUtils';
import { sanitize } from "dompurify";
import { getLangName, translateMessageToLocalLang, translateToLocalLang } from "./common"
import { ChatType } from '@common/ChatType';
import { constructFieldMessageText, parseLocalDateTime } from '@common/msgUtils';
import { removeHtmlTags } from './StringUtils';
import { AccessType } from './AccessType';
import i18n from "i18next";

/*
========================================================================================================================
Private functions
========================================================================================================================
*/

// // Function to dispatch the custom event
// const fireOverwriteConsentEvent = () => {
//   // Create the custom event with a 'detail' property for passing data
//   const customEvent = new CustomEvent('consentGivenOwerwriteEvent', { detail: null });
//   // Dispatch the event
//   window.dispatchEvent(customEvent);
// };

// // // { accepted?: string, initial?: string, overwriteConsent?: boolean }
// const preProcessConsent = (consent) => {
//   if (consent?.overwriteConsent && consent?.overwriteConsent === true) {
//     fireOverwriteConsentEvent();
//     return null;
//   } else {
//     return consent;
//   }
// }

const OBJECTID_LENGTH = 24

/**
 * This is the main function that loads messages for all chats in the system. It gets the basic
 * information from the messages collection and adds additional metadata depending on the message
 * type.
 * 
 * @param request - URL to be used when making the request to Morgan Core. The URL changes
 *                  depending which side is trying to load the messages. For instance
 *                  loading the messages from the user side requires the userAxios object
 *                  but loading the messages from the operator side requires the operatorAxios
 *                  object. The URL determines the amount of messges to load. 
 * @param userId - The user who is loading the messages in a chat. This can be either the user
 *                 or the operator
 * @param chatId - The chat for which we are trying to load the messages.
 * @param messageHistoryAllowed - Indicator that removes the blurred effect on the messages based on 
 *                                access rights.
 * @param operatorView - Indicator that tells that the messages are getting loaded for a chat that was
 *                      opened on the console side. * 
 * @param messageIn - This is the message as it comes from Morgan Messages. It is passed to this
 *                    function so that it is proccesed in the same manner as the rest of the 
 *                    messages coming in batch.
 * @returns a messages array if any messages were loaded or undefined if the was a problem loading them.
 * 
 */
const loadMessages = async (request, userId, chatId, chatType, messageHistoryAllowed, operatorView, messageIn, abortController=null) => {  
    if (chatId === undefined || chatId === null || chatId.length !== OBJECTID_LENGTH) {
      console.info(`loadMessages: Invalid chat id:`, chatId);
      return null;
    }

    let messages = [];
    let tempMessages = []; // used to construct the final messages array from above list

    // Collect all messages in messages array
    if (messageIn) {
      messages.push(messageIn);
      console.log(`Recieved new message ${messageIn._id}:`, messages);
    } else {
      try{
        messages.push(...await axiosCall(request, { signal: abortController?.signal }));
      } catch (e) {
        if (e.message !== 'CanceledError: canceled') console.error('Error in load messages: ',e);
        return null;
      }
      console.log(`Fetched ${messages?.length} message(s): `, messages);
    }

    const localizedLanguage = getLangName(LanguageUtils.fetchLocalizationLanguageSetting().split('-')[0].toLowerCase());
    for await(const message of messages) {
      if (message.accessType === AccessType.system && message.messageType !== 'Action') continue;
      let constructedMessage = message;
      if (!message.sender) {
        constructedMessage.sender = {
          _id: '',
          userId: '',
          webUserId: '',
          firstname: 'Deleted',
          middlename: '',
          lastname: 'User',
          role: '',
          roleDescription: '',
          status: '',
          banned: false,
          active: false,
          smsNotifications: false,
          emailNotifications: false,
          tcpaConsentGiven: false,
          optInConsentGiven: false,
          language: 'en',
          lenderCompany: 'SUNWST000',
          accessDetails: '',
        }
      }
      
      // shared properties
      constructedMessage.visible = operatorView ? true : messageHistoryAllowed;
      constructedMessage.operatorView = operatorView;
      constructedMessage.userId = userId;
      // Defaults for translation:
      constructedMessage.localizedLanguage = localizedLanguage;
      constructedMessage.sentByCurrentUser = (message.sender?._id === userId) ||
        (message.messageType === 'Invite' && message.inviteSentById === userId);
      constructedMessage.alignByCurrentUser =
        (operatorView && message.sender?.accessLevel === 'Level 0' && chatType !== ChatType.Team) ||
        (message.sender?._id === userId) ||
        (message.messageType === 'Invite' && message.inviteSentById === userId) || 
        message.messageType === 'Errand';

      try {
      switch (message.messageType) {
        case 'Action':
          const action = message.action || {};
          
          // assign public recipients 
          constructedMessage.action = action;
          constructedMessage.actionId = action?._id;
          constructedMessage.icon = getImageSource(action.actionIcon);
          constructedMessage.placeholder = action?.description;

          // Peform language translation:
          if (constructedMessage?._id !== userId) {
            await translateMessageToLocalLang(constructedMessage);
          }
          break;
        case 'UserPromptsMenu':
        case 'VideoListMenu':
           if (constructedMessage?._id !== userId) {
            await translateMessageToLocalLang(constructedMessage);
          }
          break;
        case 'WelcomeUser':
          constructedMessage.message = typeof message.message === 'string' && message.message.startsWith('*') && message.message.endsWith('*') 
            ? message.message // if masked just return ******
            : JSON.parse(decodeURIComponent(message.message)) 
          // constructedMessage.message.consent = preProcessConsent(constructedMessage.message.consent);
        break;
        case 'Errand':
          let array = message.message.split('<errandmsg/>');
          constructedMessage.errand = array[0];
          constructedMessage.displayName = array[1];
          constructedMessage.start = array[2];
          constructedMessage.end = array[3];
          break;
        case 'Field':
          // parse edited Field message data (QID edited answers)
          let editedMessageData = null;
          const sepStr = '<separator/>';
          // if edited msg status AND has separator str (which only QID based field message has)
          if(message.messageStatus === 'edited' && message.message.indexOf(sepStr) !== -1) {
            // message looks like: 
            // "firstToDisp <sepStr> firstDataPart <splitterStr> secondToDisp <sepStr> secondDataPart"
            const splitterStr = '<i class=\"messageArrow\"/>';
            // check splitterStr
            // if there is indeed such splitterStr
            if(message.message.indexOf(splitterStr) !== -1) {
              // [firstToDisp <sepStr> firstDataPart, secondToDisp <sepStr> secondDataPart]
              const splitted = message.message.split(splitterStr)
              // check splitted ln
              if(splitted.length === 2) {
                const firstToDisp = splitted[0].slice(0, splitted[0].indexOf(sepStr)); // firstToDisp
                const secondToDisp = splitted[1].slice(0, splitted[1].indexOf(sepStr)); // secondToDisp
  
                editedMessageData = {
                  first: removeHtmlTags(firstToDisp).trim(), // remove html tags from firstToDisp
                  second: removeHtmlTags(secondToDisp).trim() // remove html tags from secondToDisp
                }
              } else {
                console.error('[loadMessages]: case \'Field\' <QID edited condition> Unsupported format detected in given message: ', {...message});
              }
            }
          }

          if (message.userAction !== undefined) {
            constructedMessage = {
              ...constructedMessage,
              icon: getImageSource(message.action?.actionIcon),
              action: message.action,
              message: constructFieldMessageText(message)
            }

            if(editedMessageData) constructedMessage.editedMessageData = editedMessageData;
          }
          break;
        case 'Invite':
          let match;
          // Create DOM Parser
          let parser = new DOMParser();
          // Convert Span element containing user info from string to html
          let htmlDoc = parser.parseFromString(message.message, 'text/html');
          // Select the span element from the DOM element
          let element = htmlDoc.getElementsByTagName('span')[0];
          // define phone attribute and remove any existing formatting
          let phoneAtt = ('' + element?.attributes['data-phone']?.value).replace(/\D/g, '');
          // split phone into ### ### ####  
          match = phoneAtt.match(/^(\d{3})(\d{3})(\d{4})$/);
          if (match) {
            // enforce (222) 333-4444 format
            phoneAtt = '(' + match[1] + ') ' + match[2] + '-' + match[3];
          }
          // define email attribute
          let emailAtt = '' + element?.attributes['data-email']?.value;
          // split email into username @ server . domain
          match = emailAtt.match(/^([^@]*)(@)(.+)(\.)(.+)$/);
          if (match) {
            // match[0] is full string
            let username = match[1].toLowerCase();
            // match[2] is @
            let server = match[3].toLowerCase();
            // match[4] is .
            let domain = match[5].toLowerCase();
            // shorten username to first character *** last character
            if (username.length > 5) {
              username = username.substring(0,1) + '***' + username.slice(-1);
            }
            // shorten server to first character *** last character
            if (server.length > 5) {
              server = server.substring(0,1) + '***' + server.slice(-1);
            }
            // shorten domain to first character * last character
            if (domain.length > 3) {
              domain = domain.substring(0,1) + '*' + domain.slice(-1);
            }
            // build shortened email string
            emailAtt = username + '@' + server + '.' + domain;
          }
          constructedMessage.accepted = element?.attributes['data-accepted']?.value === 'true';
          constructedMessage.pending = element?.attributes['data-pending']?.value === 'true';
          constructedMessage.email = emailAtt;
          constructedMessage.firstname = element?.attributes['data-firstname']?.value;
          constructedMessage.inviteSentById = element?.attributes['data-inviteSentById']?.value;
          constructedMessage.lastname = element?.attributes['data-lastname']?.value;
          constructedMessage.phone =  phoneAtt;
          constructedMessage.referer = element?.attributes['data-referer']?.value;
          break;
        case 'Referrer':
          // Select the part of the message that holds the theme (referral custom greeting)
          let elements = message.message.split('<>')
          let theme = elements[8]
          if (theme !== '') {
            let { translatedMessage } = await translateToLocalLang(theme)
            constructedMessage.theme = translatedMessage ? translatedMessage : theme
          }
          break;
        case 'Appointment':
          // Create DOM Parser
          let apptParser = new DOMParser();
          // Convert span element containing appointment info
          let apptDoc = apptParser.parseFromString(message.message, 'text/html');
          let apptElement = apptDoc.getElementsByTagName('span')[0];
          // Select the span element from the DOM element
          let apptDataJSON = apptElement?.attributes['data-apptData']?.value;
          let apptData = JSON.parse(apptDataJSON);
          if (apptData.apptMsg){
            let { translatedMessage } = await translateToLocalLang(apptData.apptMsg)
            constructedMessage.apptMsg = translatedMessage ? translatedMessage : apptData.apptMsg
          }
          if (apptData.month){
            constructedMessage.month = parseInt(apptData.month, 10) || 0;
          }
          if (apptData.date){
            let { translatedMessage } = await translateToLocalLang(apptData.date)
            constructedMessage.date = translatedMessage ? translatedMessage : apptData.date
          }
          if (apptData.time){
            let { translatedMessage } = await translateToLocalLang(apptData.time)
            constructedMessage.time = translatedMessage ? translatedMessage : apptData.time
          }
          break;
        case 'BlockchainWelcome':
            const blockchainWelcomeData = JSON.parse(decodeURIComponent(message.message));

            if (blockchainWelcomeData.title || blockchainWelcomeData.welcomeExcited){
              let text = blockchainWelcomeData.title ?? blockchainWelcomeData.welcomeExcited
              let { translatedMessage, fail } = await translateToLocalLang(text)
              constructedMessage.title = translatedMessage && !fail ? translatedMessage : text
            }
  
            if (blockchainWelcomeData.subtitle || blockchainWelcomeData.welcomeHeresHow){
              let text = blockchainWelcomeData.subtitle ?? blockchainWelcomeData.welcomeHeresHow
              let { translatedMessage, fail } = await translateToLocalLang(text)
              constructedMessage.subtitle = translatedMessage && !fail ? translatedMessage : text
            }
  
            const blockchainSteps = blockchainWelcomeData.steps;
            Promise.all(blockchainSteps.map(async (step, index, arr) => {
              let { translatedMessage, fail } = await translateToLocalLang(step.message);
              arr[index] = { message: translatedMessage && !fail ? translatedMessage : step.message, icon: step.icon };
            }))
            constructedMessage.steps = blockchainSteps;
  
            if (blockchainWelcomeData.footer) {
              let { translatedMessage, fail } = await translateToLocalLang(blockchainWelcomeData.footer);
              constructedMessage.footer = translatedMessage && !fail ? translatedMessage : blockchainWelcomeData.footer;
            }
  
            if (blockchainWelcomeData.specialGif === true) {
              constructedMessage.specialGif = true;
            }

            if (blockchainWelcomeData.header && blockchainWelcomeData.header.type) {
              constructedMessage.header = {...blockchainWelcomeData.header};
            }

            if(blockchainWelcomeData.subContent 
              && blockchainWelcomeData.subContent.title 
              && blockchainWelcomeData.subContent.buttonText) {
              try {
                let results = await Promise.all([
                  translateToLocalLang(blockchainWelcomeData.subContent.title), 
                  translateToLocalLang(blockchainWelcomeData.subContent.buttonText),
                  translateToLocalLang(blockchainWelcomeData.subContent.description)
                ]);
                constructedMessage.subContent = {
                  ...blockchainWelcomeData.subContent, 
                  title: results[0].translatedMessage && !results[0].fail ? results[0].translatedMessage : blockchainWelcomeData.subContent.title,
                  buttonText: results[1].translatedMessage && !results[1].fail ? results[1].translatedMessage : blockchainWelcomeData.subContent.buttonText,
                  description: results[2].translatedMessage && !results[2].fail ? results[2].translatedMessage : blockchainWelcomeData.subContent.description,
                };
              } catch(err) {
                constructedMessage.subContent = {
                  ...blockchainWelcomeData.subContent
                };
              }
            }
  
            constructedMessage.eventName = blockchainWelcomeData.eventName;
            constructedMessage.eventDisplay = blockchainWelcomeData.eventDisplay;
            // If true, the message will use the normal orange color instead of the blue blockchain color
            constructedMessage.useDefaultColor = blockchainWelcomeData.useDefaultColor;
            // constructedMessage.consent = blockchainWelcomeData.consent;
          break;
        case 'NumberedList':
          let decodedMessage = decodeURIComponent(message.message);
          // Check if the message has been edited, and use the updated message if so
          const editDelim = '<i class="messageArrow"/>'
          const editIndex = decodedMessage.indexOf(editDelim);
          if (message.messageStatus === 'edited' && editIndex !== -1) {
            decodedMessage = decodedMessage.substring(editIndex + editDelim.length);
          }
          constructedMessage.message = JSON.parse(decodedMessage);
          break;
        case 'CreditRepairWelcome':
        case 'RefinanceCalculatorWelcome':
        case 'CalculatorsWelcome':
          // Create DOM Parser
          let creditRepairWelcomeParser = new DOMParser();
          let creditRepairWelcomeDoc = creditRepairWelcomeParser.parseFromString(message.message, 'text/html');
          let creditRepairWelcomeElement = creditRepairWelcomeDoc.getElementsByTagName('span')[0];
          let creditRepairWelcomeDataJSON = creditRepairWelcomeElement?.attributes['data-crData']?.value;
          let creditRepairWelcomeData = JSON.parse(creditRepairWelcomeDataJSON);

          /*
          The new expected format of this message type is
          { title: string; subtitle: string; steps: string[]; footer?: string; }

          However, the old format is still supported for backward compatibility so
          that old messages are still displayed properly.

          The old format is
          { welcomeExcited: string; welcomeHeresHow: string;
            welcomeStep1: string; welcomeStep2: string; welcomeStep3: string; welcomeStep4: string; }
          */
          if (creditRepairWelcomeData.title || creditRepairWelcomeData.welcomeExcited){
            let text = creditRepairWelcomeData.title ?? creditRepairWelcomeData.welcomeExcited
            let { translatedMessage, fail } = await translateToLocalLang(text)
            constructedMessage.title = translatedMessage && !fail ? translatedMessage : text
          }

          if (creditRepairWelcomeData.subtitle || creditRepairWelcomeData.welcomeHeresHow){
            let text = creditRepairWelcomeData.subtitle ?? creditRepairWelcomeData.welcomeHeresHow
            let { translatedMessage, fail } = await translateToLocalLang(text)
            constructedMessage.subtitle = translatedMessage && !fail ? translatedMessage : text
          }

          let steps = [
            creditRepairWelcomeData.welcomeStep1,
            creditRepairWelcomeData.welcomeStep2,
            creditRepairWelcomeData.welcomeStep3,
            creditRepairWelcomeData.welcomeStep4,
          ].filter(Boolean);
          if (steps.length === 0 && Array.isArray(creditRepairWelcomeData.steps)) {
            steps = creditRepairWelcomeData.steps;
          }
          Promise.all(steps.map(async (message, index, arr) => {
            let { translatedMessage, fail } = await translateToLocalLang(message);
            arr[index] = translatedMessage && !fail ? translatedMessage : message;
          }))
          constructedMessage.steps = steps;

          if (creditRepairWelcomeData.footer) {
            let { translatedMessage, fail } = await translateToLocalLang(creditRepairWelcomeData.footer);
            constructedMessage.footer = translatedMessage && !fail ? translatedMessage : creditRepairWelcomeData.footer;
          }

          // constructedMessage.consent = preProcessConsent(creditRepairWelcomeData.consent);
          break;
        case 'PropertyListing':
            // Create DOM Parser
            let parser2 = new DOMParser();
            // Convert Span element containing user info from string to html
            let htmlDoc2 = parser2.parseFromString(message.message, 'text/html');
            let element2 = htmlDoc2.getElementsByTagName('span')[0];
            // Select the span element from the DOM element
            let address = element2?.attributes['data-address']?.value;
            let link = element2?.attributes['data-link']?.value;
            //the message will be split on the ~ character to retrieve the data.
            address+= '<>'+ element2?.attributes['data-price']?.value;
            // Make specific variable to format when setting to state
            constructedMessage.message = address;
            constructedMessage.url = link;
            break;
        case 'CalculatorResult':
          let editedMessageHtml = message.message;
          const delim = '<i class="messageArrow"/>';
          // Check if the message has been edited, and use the updated message if so
          const delimIndex = editedMessageHtml.indexOf(delim);
          if (message.messageStatus === 'edited' && delimIndex !== -1) {
            editedMessageHtml = editedMessageHtml.substring(delimIndex + delim.length);
          }
          let bubbleDomParser = new DOMParser();
          let bubbleHtmlDoc = bubbleDomParser.parseFromString(message.message, 'text/html');
          let bubbleElement = bubbleHtmlDoc.getElementsByTagName('span')[0];
          const dataWindowHtmlString =
            message.messageStatus === 'edited'
              ? editedMessageHtml.replace(/&quot;/g, '"').replace(/&apos;/g, "'")
              : bubbleElement.getAttribute('data-window');
          const dataItemsJsonString = bubbleElement.getAttribute('data-items') || '';
          constructedMessage.result = {};
          if (dataItemsJsonString){
            const parsedJson = JSON.parse(dataItemsJsonString);
            constructedMessage.result = parsedJson;
            constructedMessage.result.title = i18n.t(parsedJson.title);
            if (dataItemsJsonString.includes('-')){
              constructedMessage.result.badResult = true;
              break;
            }
            if (Object.values(constructedMessage?.result?.expandedDetails ?? {}).includes('NaN')){
              constructedMessage.result.badResult = true;
            }
            if (dataWindowHtmlString && dataWindowHtmlString !== ''){
              constructedMessage.result.htmlContent = dataWindowHtmlString;
            }
            break;
          } else if (dataWindowHtmlString) {
            // Since dataWindowHtmlString contains HTML and not a JSON string,
            // we store it directly rather than parsing it as JSON.
            constructedMessage.result.htmlContent = dataWindowHtmlString;
          }
          else {
            constructedMessage.result.badResult = true;
          }
          break;
        case 'HelpTip':
          // The <span> element sent from AI contains data for the tip
          // Here the data is extracted and parsed
          // If it's text, it is translated
          // If it's an image, the url is fetched
          let helpTipDomParser = new DOMParser();
          let helpTipHtmlDoc = helpTipDomParser.parseFromString(message.message, 'text/html');
          let helpTipElement = helpTipHtmlDoc.getElementsByTagName('span')[0];
          let helpTipName = helpTipElement?.attributes['data-tipName']?.value;
          let helpTipText = helpTipElement?.attributes['data-tipText']?.value;
          let helpTipImageId = helpTipElement?.attributes['data-tipImageId']?.value;
          let helpTipActive = helpTipElement?.attributes['data-tipActive']?.value;

          if (helpTipText) {
            let { translatedMessage } = await translateToLocalLang(helpTipText)
            constructedMessage.helpTipText = translatedMessage ? translatedMessage : helpTipText;
          }
          if (helpTipImageId) {
            const { storageLocation } = await axiosCall({url: `helptipimage/${helpTipImageId}`, method: 'GET'})
            constructedMessage.helpTipImageUrl = storageLocation;
          }
          constructedMessage.helpTipName = helpTipName;
          constructedMessage.helpTipActive = helpTipActive;
          break;
        case 'HelpTipGroup':
          // The <span> element sent from AI contains data for all tips in the group
          // Here we extract this data and parse it
          // so it can be usable by the <TipsDialog> component
          let helpTipGroupDomParser = new DOMParser();
          let helpTipGroupHtmlDoc = helpTipGroupDomParser.parseFromString(message.message, 'text/html');
          let helpTipGroupElement = helpTipGroupHtmlDoc.getElementsByTagName('span')[0];
          let helpTipGroupTips = helpTipGroupElement?.attributes['data-tipsArr']?.value;
          let tipsArr = JSON.parse(helpTipGroupTips);

          // If a tip is text tip, translate it
          // If a tip is an image tip, get the image URL from the id
          for (const tip of tipsArr) {
            if (tip.text) {
              let {translatedMessage} = await translateToLocalLang(tip.text);
              if (translatedMessage) {
                tip.text = translatedMessage;
              }
            }
            if (tip.image) {
              const { storageLocation } = await axiosCall({url: `helptipimage/${tip.image}`, method: 'GET'});
              tip.imageUrl = storageLocation;
            }
          }
          constructedMessage.helpTipList = tipsArr;
          break;
        case 'Notification':
          // Peform language translation:
          await translateMessageToLocalLang(constructedMessage);
          break;
        case 'Disclaimer':
          // Peform language translation:
          await translateMessageToLocalLang(constructedMessage);
          break;
        case 'Text':
          // Peform language translation:
          if (constructedMessage?._id !== userId) {
            await translateMessageToLocalLang(constructedMessage);
          }
          break;
        case 'Greeting':
          // Peform language translation:
          if (constructedMessage?._id !== userId) {
            await translateMessageToLocalLang(constructedMessage);
          }
          break;
        case 'Order':
        case 'DownloadAppMessage':
          // Perform language translation:
          if (constructedMessage?._id !== userId) {
            await translateMessageToLocalLang(constructedMessage);
          }
          break;
        case 'Url':
          let msg, string;
          // If we are edited, we only worry about getting the links from the current message
          // not the edited part of the message
          if (message.messageStatus === 'edited') {
            msg = message.message?.split('<i class="messageArrow"/>');
            msg = [`<s>${(msg[0]).replace(/(<([^>]+)>)/gi, '')}</s>`, sanitize(msg[1])];
            string = msg[1];
            msg = msg[0] + '<i class="messageArrow"/>';
          } else {
            string = sanitize(message.message);
            msg = '';
          }
          let links = [];
          let url;
          if (string.indexOf('href=') !== -1) {
            // Create DOM Parser
            let parser = new DOMParser();
            // Convert Anchor elements containing from string to html
            let htmlDoc = parser.parseFromString(string, 'text/html');
            // Select the anchor tags the message
            let tag = htmlDoc.getElementsByTagName('a');
            // Make specific variable to format when setting to state
            // For each anchor tag element
            Array.from(tag).forEach(a => {
              // Grab the href
              url = a?.attributes['href']?.value?.replace(/\\"/g, '');
              // Attach http if it does not exist
              if (url?.indexOf('http') === -1 && url?.indexOf('mailto:') === -1 && url?.indexOf('tel:') === -1) {
                url = `https://${url}`
              }
              // Add it to the list of links on the message object
              if (url?.indexOf('mailto:') === -1 && url?.indexOf('tel:') === -1) {
                links.push(url);
              }
            })
          }
          // Gather links for message object
          constructedMessage.links = links;
          // Peform language translation:
          if (constructedMessage?._id !== userId) {
            await translateMessageToLocalLang(constructedMessage);
          }
          break;
        case 'LockConfirmation':
          // deconstruct <span> element sent from AI
          let lockDomParser = new DOMParser();
          let lockHtmlDoc = lockDomParser.parseFromString(message.message, 'text/html');
          let lockElement = lockHtmlDoc.getElementsByTagName('span')[0];
          let lockDataJSON = lockElement?.attributes['data-lockData']?.value;
          let lockData = JSON.parse(lockDataJSON);

          constructedMessage.loanNumber = lockData.loanNumber;
          constructedMessage.borrowerName = lockData.borrowerName;
          constructedMessage.rate = lockData.lockFinalRate
          constructedMessage.price = lockData.lockFinalPrice
          constructedMessage.loanTypeKeyword = lockData.loanTypeKeyword;
          constructedMessage.paymentDescription = lockData.paymentDescription;
          constructedMessage.expDate = lockData.lockExpDate
          constructedMessage.productCode = lockData.productCode
          break;
        case 'RateInfo':
          constructedMessage.message = parseLocalDateTime(constructedMessage);
          break;
        case 'ImagingUploadStatus':
          const statusDomParser = new DOMParser();
          const statusHtmlDoc = statusDomParser.parseFromString(message.message, 'text/html');
          const statusElement = statusHtmlDoc.getElementsByTagName('span')[0];
          const statusDataJSON = statusElement?.attributes['data-statusData']?.value;
          const statusDataList = JSON.parse(statusDataJSON);

          constructedMessage.docList = statusDataList
          break;
        case 'CreditRepairDisputeAccounts':
          const disputeAccountsDomParser = new DOMParser();
          const disputeAccountsHtmlDoc = disputeAccountsDomParser.parseFromString(message.message, 'text/html');
          const disputeAccountsElement = disputeAccountsHtmlDoc.getElementsByTagName('span')[0];
          const dataOpeningMessageHtmlString = disputeAccountsElement.getAttribute('data-openingMessage') || '';
          const dataClosingMessageHtmlString = disputeAccountsElement.getAttribute('data-closingMessage') || '';
          const dataItemsListJsonString = disputeAccountsElement.getAttribute('data-itemsList') || '';
          const dataSelectable = Boolean(disputeAccountsElement.getAttribute('data-selectable'));
          const dataItemsList = JSON.parse(dataItemsListJsonString);

          constructedMessage.result = {
            openingMessage: dataOpeningMessageHtmlString,
            closingMessage: dataClosingMessageHtmlString,
            itemsList: dataItemsList,
            selectable: dataSelectable,
          };
          break;
        case 'RatePricingResult':
          const resultDomParser = new DOMParser();
          const resultHtmlDoc = resultDomParser.parseFromString(message.message, 'text/html');
          const resultElement = resultHtmlDoc.getElementsByTagName('span')[0];
          const resultDataJSON = resultElement?.attributes['data-resultData']?.value;
          const resultDataObj = JSON.parse(resultDataJSON);

          constructedMessage.resultData = resultDataObj;
          break;
        default:
          // Perform language translation for default messages:
          if (
              (constructedMessage !== undefined) &&
              (constructedMessage !== null) &&
              (constructedMessage._id !== userId)) {
                await translateMessageToLocalLang(constructedMessage);
          }
          break;
      }
      } catch (err) {
        console.error(`loadMessages.js - messageType:|${message?.messageType}| parsing failed!`);
      }

      tempMessages.push(constructedMessage);
    }
    console.log(`Loaded ${tempMessages?.length} messages: `, tempMessages);
    if (!messageIn && !messageHistoryAllowed && !operatorView) {
      tempMessages.push({
        middleButton: true,
        timestamp: tempMessages[tempMessages.length - 1]?.createdAt + 1,
        visible: operatorView ? true : messageHistoryAllowed
      });
    }
    // This guard is coupled with the changes in the conversation component to help ensure no undefined messages
    // are returned and added to the messages array of an errand.
    return messageIn ? tempMessages[0] : tempMessages.filter((message, index, self) => message !== undefined && self.findIndex((m) => m._id === message._id) === index);
};

export default loadMessages;
