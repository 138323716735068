import React from "react";
import { useQIDOptionsFromActionId } from "@common/hooks/useQIDOptionsFromActionId"
import ActionMessageContent from "@components/MessageContent/ActionMessageContent";
import { Content } from "./Components/Content";
import { Loader } from "./Components/Loader";

import type { FC } from "react";
import type { IErrand, IMessage } from '@interfaces/Conversation';

type SelectMultpleIncomingMessageContentProps = {
  message: IMessage,
  errand: IErrand,
  index: number,
}

const SelectMultpleIncomingMessageContent: FC<SelectMultpleIncomingMessageContentProps> = ({ message, errand, index }) => {
  const [options, loading] = useQIDOptionsFromActionId(message?.action._id, false);

  if (loading) return <Loader />
  if (options.length) return <Content {...{ message, errand, options }} />

  return <ActionMessageContent {...{ message, errand, index }}/>;
}

export { SelectMultpleIncomingMessageContent }