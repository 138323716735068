import React from 'react';
import ErrorBoundary from '@components/ErrorBoundary';
import Styles from '@styles/ConsentPage.module.css';
import { UserProvider } from '@contexts/user';
import { SocketProvider } from '@contexts/socket';
import ConversationPage from './Conversation';

const Consent = (props) => {
  return (
    <ErrorBoundary debug={`.src/Pages/Conversation/Consent.tsx`}>
      <UserProvider>
        <SocketProvider>
          <section className={[Styles.pageContainer].join(' ')}>
            <ConversationPage {...props} />
          </section>
        </SocketProvider>
      </UserProvider>
    </ErrorBoundary>
  );
};

export default Consent;
