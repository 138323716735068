import React, { useState, useEffect, forwardRef, ReactNode, useMemo, useCallback, memo } from "react";
import { getIconPath } from "../utils";
import { TSubContent } from "../types";
import { IErrand, IMessage } from "@interfaces/Conversation";
import { Styles } from "./SubContentStyles";
import { isMobile } from "@common/deviceTypeHelper";
import { getUserPlayedLast24Hours } from "@storage/userStorage";
import { Master as GlareEffectMaster } from "../GlareEffects/Master";
import { CloseButton } from "./CloseButton";
import { Overlay } from "./Overlay";
import { Title } from './Title';
import { ActionButton } from "./ActionButton";
import { GifOrImage } from "./GifOrImage";
import { useUserContext } from "@contexts/user";
import { Description } from "./Description";
import { Animations } from "./Animations";

import styled from "@emotion/styled";
import '../main.css';
import { ValidatorFunctions } from "@common/Validators";
import { copyObj } from "@common/userMessagesUtils";
import { OpenButton } from "./OpenButton";
import { useErrandContext } from "@contexts/ErrandContext";
import { MorphType } from "@common/MorphType";
interface SlideOutAnimatedProps {
    styleObj: React.CSSProperties
}

const SlideOutAnimated = styled('div')<SlideOutAnimatedProps>(({ styleObj }) => ({
    ...styleObj,
    animation: `${Animations.firstRevealAnimation} 1.5s ease forwards`,
    animationDelay: '0.2s',
}));

interface ISlideOutCountainerProps {
    playMountAnimation: boolean,
    styleObj: React.CSSProperties,
    children: ReactNode,
    onClick: (e: any) => void,
    onLoseHover: () => void,
    onHover: () => void,
    onMountAnimationEnded: () => void,
    onTouchStart: (e: React.TouchEvent) => void,
    onTouchEnd: () => void,
    onTouchMove: (e: React.TouchEvent) => void,
    isDraggingRef: React.MutableRefObject<boolean>
}

const SlideOutContainerMaster = forwardRef<HTMLDivElement, ISlideOutCountainerProps>(({
    onMountAnimationEnded,
    playMountAnimation,
    styleObj,
    onClick,
    onHover,
    onLoseHover,
    children,
    onTouchStart,
    onTouchEnd,
    onTouchMove,
    isDraggingRef
}, ref) => {

    // on mount
    // if no playMountAnimation is specified, then mark as mount animation ended by default
    useEffect(() => {
        if (!playMountAnimation)
            onMountAnimationEnded();
    }, []);

    // disable horizontal page scroll
    useEffect(() => {
        const preventHorizontalScroll = (e: TouchEvent) => {
            // Check for horizontal movement (touch in x direction)
            if (isDraggingRef.current === true) {
                e.preventDefault(); // Prevent horizontal scroll
            }
        };

        // Attach event listener to document
        document.addEventListener("touchmove", preventHorizontalScroll, { passive: false });

        return () => {
            // Cleanup event listener on unmount
            document.removeEventListener("touchmove", preventHorizontalScroll);
        };
    }, []);

    if (playMountAnimation) {
        // right bounces initial animation
        return (
            <SlideOutAnimated ref={ref} styleObj={styleObj} onAnimationEnd={onMountAnimationEnded}>
                {children}
            </SlideOutAnimated>
        );
    } else {
        return (
            <div
                ref={ref}
                style={{ ...styleObj }}
                onClick={onClick}
                onMouseEnter={onHover}
                onMouseLeave={onLoseHover}
                onTouchStart={onTouchStart}
                onTouchEnd={onTouchEnd}
                onTouchMove={onTouchMove}>
                {children}
            </div>
        );
    }
})

interface Props {
    subContent: TSubContent,
    errand: IErrand,
    message: IMessage
};

// the key will be based on the subType of the rendered component
const getLocalStorageKey = (type: string, subType: string) => {
    return `bwmc_subcontent_${type}_${subType}`;
}

type TLocalStorageData = {
    wasMounted: boolean
}

// fetches from Local Storage
const fetchSubtypeLocalData = (type: string, subType: string) => {
    const rawData = localStorage.getItem(getLocalStorageKey(type, subType));
    const parsedData = JSON.parse(rawData) as TLocalStorageData;
    return parsedData;
}

const setSubtypeLocalData = (type, subType, data: TLocalStorageData) => {
    localStorage.setItem(
        getLocalStorageKey(type, subType),
        JSON.stringify({ ...data })
    );
}

const getInitialBtnState = (subType: string) => {
    if (subType === 'slotMachine') {
        return {
            userCanPlay: true
        }
    }

    return {}
}

const getInitialSubContentState = (subContent: TSubContent) => {
    return {
        button: getInitialBtnState(subContent.subType)
    }
}

const getWasAlreadyMountedBefore = (type: string, subType: string) => {
    let wasMounted = fetchSubtypeLocalData(type, subType)?.wasMounted;

    if (subType === 'slotMachine') {
        wasMounted = wasMounted === true || getUserPlayedLast24Hours() === true;
    }

    return wasMounted;
}

const MOUNTED_FLAG_DATA = { wasMounted: true } as TLocalStorageData;
const HIDE_REVEAL_ANIMATION_DURATION = 1400;
const SLIDEOUT_INIT_RIGHT_POS_VAL = -15;
export const VERY_SMALL_DEVICE_SHIFT_VALUE = 30;

const StateKeys = {
    rightPos: 'rightPos',
    rightPosAddon: 'rightPosAddon',
    overlayOpacity: 'overlayOpacity',
    glareOpacity: 'glareOpacity',
    buttonHovered: 'buttonHovered',
    forceEnlargeGif: 'forceEnlargeGif',
    replayGif: 'replayGif',
    initialGifPlayed: 'initialGifPlayed',
    isFullyClosed: 'isFullyClosed',
    firstMountPlayed: 'firstMountPlayed',
    wasHidden: 'wasHidden',
    UIData: 'UIData',
} as const;

type TStateKey = keyof typeof StateKeys;

const checkIsFullyRevealed = (rightPos, containerWidth, isVerySmallDevice) => {
    if (ValidatorFunctions.isUndefinedOrNull(containerWidth) === true) return false;
    return Math.abs(rightPos) === containerWidth - (isVerySmallDevice ? VERY_SMALL_DEVICE_SHIFT_VALUE : 0)
}

const getInitialState = (wasAlreadyMountedBefore, gifSrc, subContent) => {
    return {
        rightPos: SLIDEOUT_INIT_RIGHT_POS_VAL,
        rightPosAddon: 0,
        overlayOpacity: 1,
        glareOpacity: 0,
        buttonHovered: false,
        forceEnlargeGif: false,
        replayGif: false,
        initialGifPlayed: (wasAlreadyMountedBefore ?? gifSrc === null) as boolean,
        isFullyClosed: (wasAlreadyMountedBefore ?? false) as boolean,
        firstMountPlayed: (wasAlreadyMountedBefore ?? false) as boolean,
        wasHidden: (wasAlreadyMountedBefore ?? false) as boolean,
        UIData: getInitialSubContentState(subContent)
    }
}

const getConfig = (subContent) => {
    return {
        gifSrc: (subContent?.gif && subContent?.gif !== '') ? getIconPath(subContent.gif) : null,
        staticSrc: (subContent?.gifStatic && subContent?.gifStatic !== '') ? getIconPath(subContent.gifStatic) : null,
        gifDuration: subContent?.gifDuration ?? null,
        revealOnMount: subContent?.revealOnMount ?? false,
        revealIn: subContent?.revealIn ?? 100,
        withGlare: subContent?.withGlare ?? false,
        wasAlreadyMountedBefore: getWasAlreadyMountedBefore(subContent?.type, subContent?.subType),
        isMobileView: isMobile(),
        isVerySmallDevice: window.innerWidth < 400,
        subContent: copyObj(subContent) // make a copy in case parent prop changes for some reason.
    }
}

// Use this context in child components to ensure best state-update wise performance.
// Make sure to combine any setStates into one call.
// f.e. revealGlare and hideGlare and etc.
export const LocalStateUpdatersContext = React.createContext<{
    // merged setState calls (with mutliple vars changes)
    revealGlare: () => void;
    hideGlare: () => void;
    revealOverlay: () => void;
    hideOverlay: () => void;
    // single setState calls (with single var changes)
    updateIsFullyClosed: (val: boolean) => void;
    updateInitialGifPlayed: (val: boolean) => void;
    updateReplayGif: (val: boolean) => void;
    updateGlareOpacity: (val: number) => void;
    updateForceEnlargeGif: (val: boolean) => void;
    updateButtonHovered: (val: boolean) => void;
    updateUIData: (val: any) => void;
    updateFirstMountPlayed: (val: any) => void;
    updateRightPosAddon: (val: number) => void;
} | null>(null);


const SlideOut = memo((props: Props) => {
    const mainContainerRef = React.useRef(null);
    // Initial Config Vars from props (Never changes because its config logic)
    const config = useMemo(() => getConfig(props.subContent), [])

    // State vars
    const [state, setState] = useState<Record<TStateKey, any>>(getInitialState(config.wasAlreadyMountedBefore, config.gifSrc, props.subContent));

    // State derived Variable
    const isRevealed = state.rightPos !== SLIDEOUT_INIT_RIGHT_POS_VAL;
    // mainly used for very small device view (< 400)
    const [isFullyRevealed, setIsFullyRevealed] = useState(checkIsFullyRevealed(state.rightPos, mainContainerRef?.current?.getBoundingClientRect()?.width, config.isVerySmallDevice));

    const { subContentPartExternalState, setSubContentPartExternalState } = useErrandContext();

    const errandContext = useErrandContext();

    const isDraggingRef = React.useRef(false);
    const startX = React.useRef<number | null>(null); // To store initial touch position
    const threshold = 50;

    // used mainly for handling very small device screen width view
    // isFullyRevealed becomes true/false AFTER HIDE_REVEAL_ANIMATION_DURATION time passes.
    useEffect(() => {
        const newIsFullyRevealedValue = checkIsFullyRevealed(state.rightPos, mainContainerRef?.current?.getBoundingClientRect()?.width, config.isVerySmallDevice);
        let tm;
        // if it is fully revealed, update only in animation_duration timeout
        if (newIsFullyRevealedValue === true) {
            tm = setTimeout(() => {
                setIsFullyRevealed(newIsFullyRevealedValue);
            }, HIDE_REVEAL_ANIMATION_DURATION);
        } else {
            // else, update immidiately
            setIsFullyRevealed(newIsFullyRevealedValue);
        }

        return () => {
            clearTimeout(tm);
        }
    }, [state.rightPos])

    // handles the forceOpen signal from outer scope from other parent component.
    // used to handle the click "anywhere" on the parent whole message component.
    useEffect(() => {
        // if this is the same message that the forceOpen was fired on
        if (subContentPartExternalState.openSignal.messageId !== null && props.message?._id === subContentPartExternalState.openSignal.messageId) {
            // check if the click was indeed made within this same component container (slideout portion)
            if (!mainContainerRef.current.contains(subContentPartExternalState.openSignal.eTarget) && state.isFullyClosed) { // only if fully closed
                onEdgeClick();
            }
            // make sure to process & clear the external signal
            setSubContentPartExternalState((prev) => {
                return {
                    ...prev,
                    openSignal: {
                        messageId: null,
                        eTarget: null
                    }
                }
            })
        }
    }, [subContentPartExternalState.openSignal.eTarget, subContentPartExternalState.openSignal.messageId, state.rightPos, state.isFullyClosed]);

    const Updaters = useMemo(() => {
        // Helper function for functions below (closure)
        const updateStateKeyVal = (key: TStateKey, val: any) => {
            setState((prev) => ({
                ...prev,
                [key]: val
            }));
        }

        // All state update functions
        return {
            revealGlare: () => updateStateKeyVal(StateKeys.glareOpacity, 1),
            hideGlare: () => updateStateKeyVal(StateKeys.glareOpacity, 0),
            revealOverlay: () => updateStateKeyVal(StateKeys.overlayOpacity, 1),
            hideOverlay: () => updateStateKeyVal(StateKeys.overlayOpacity, 0),

            updateIsFullyClosed: (val: boolean) => updateStateKeyVal(StateKeys.isFullyClosed, val),
            updateInitialGifPlayed: (val: boolean) => updateStateKeyVal(StateKeys.initialGifPlayed, val),
            updateReplayGif: (val: boolean) => updateStateKeyVal(StateKeys.replayGif, val),
            updateGlareOpacity: (val: number) => updateStateKeyVal(StateKeys.glareOpacity, val),
            updateForceEnlargeGif: (val: boolean) => updateStateKeyVal(StateKeys.forceEnlargeGif, val),
            updateButtonHovered: (val: boolean) => updateStateKeyVal(StateKeys.buttonHovered, val),
            updateUIData: (val) => updateStateKeyVal(StateKeys.UIData, val),
            updateFirstMountPlayed: (val) => updateStateKeyVal(StateKeys.firstMountPlayed, val),
            updateRightPosAddon: (val: number) => updateStateKeyVal(StateKeys.rightPosAddon, val),
        }
    }, []) // never changes.

    // on mount handler
    useEffect(() => {
        // SLOTMACHINE subtype
        if (config.subContent.subType === 'slotMachine') {
            const userPlayedToday = getUserPlayedLast24Hours();
            if (userPlayedToday) {
                Updaters.updateUIData({ button: { userCanPlay: false } })
            };
        }
    }, [isRevealed])

    // Combined State Updates
    // Used within same scope, no further optimization needed,
    // unless passed down to other children as props.
    const doRevealUpdate = (containerWidthVal: number) => {
        setState((prev) => ({
            ...prev,
            isFullyClosed: false,
            rightPos: ValidatorFunctions.isTypeOfNumber(containerWidthVal) ? -containerWidthVal : prev.rightPos,
            rightPosAddon: 0,
            overlayOpacity: 0
        }))
    }

    const doHideUpdate = () => {
        setState((prev) => ({
            ...prev,
            rightPos: SLIDEOUT_INIT_RIGHT_POS_VAL,
            wasHidden: true,
            buttonHovered: false,
            forceEnlargeGif: false
        }))
    }

    const reveal = () => {
        // local storage part, mark component as mounted.
        setSubtypeLocalData(config.subContent.type, config.subContent.subType, MOUNTED_FLAG_DATA);
        // reset morph type
        errandContext.setMorphType(MorphType.None);
        // do reveal state update
        let mainContainerWidthValue = mainContainerRef?.current?.getBoundingClientRect()?.width as number;
        // if very small device width (< 400)
        if (config.isVerySmallDevice === true) {
            mainContainerWidthValue -= VERY_SMALL_DEVICE_SHIFT_VALUE;
        }
        doRevealUpdate(mainContainerWidthValue);
        setTimeout(() => {
            Updaters.revealGlare();
        }, HIDE_REVEAL_ANIMATION_DURATION)
    }


    const doHide = () => {
        // reset morph type
        errandContext.setMorphType(MorphType.None);

        // do the hide state update
        doHideUpdate();

        // show grey overlay in half of HIDE_REVEAL_ANIMATION_DURATION time
        setTimeout(() => {
            Updaters.revealOverlay();
        }, Math.floor(HIDE_REVEAL_ANIMATION_DURATION / 2))

        // Set as fully closed after animation is done.
        setTimeout(() => {
            Updaters.updateIsFullyClosed(true);
        }, HIDE_REVEAL_ANIMATION_DURATION);
    }

    const hide = () => {
        // if was not yet hidden, remove the initial glare in animated way (via opacity 1 -> 0).
        if (!state.wasHidden && config.withGlare) {
            // SlotMachine subContent subType case.
            if (props?.subContent?.subType === 'slotMachine') {
                Updaters.hideGlare();
                setTimeout(() => {
                    doHide();
                }, HIDE_REVEAL_ANIMATION_DURATION); // x2 longer tm time to ensure opacity was changed before actually hiding.
                return;
            }
        }

        // default behaviour.
        doHide();
    }

    // Handles initial reveal animation
    useEffect(() => {
        // config related vars never changes, no need to include in deps
        if (
            config.revealOnMount &&
            config.revealIn &&
            typeof config.revealIn === 'number' &&
            config.revealOnMount === true &&
            state.firstMountPlayed === true &&
            state.wasHidden === false
        ) {
            const tm = setTimeout(() => {
                reveal();
            }, config.revealIn);

            return () => clearTimeout(tm);
        }
    }, [state.firstMountPlayed])

    // on mount make sure the morphType is undefined.
    useEffect(() => {
        errandContext.setMorphType(MorphType.None);
    }, []);

    // On Main Slideout Click Handler
    // When hidden, open on edge click.
    // When shown, do nothing.
    const onEdgeClick = (e ?: React.MouseEvent | React.TouchEvent) => {
        e?.preventDefault();
        e?.stopPropagation();
        // if hidden.
        if (!isRevealed) {
            reveal();
        }
    }

    // Hover related
    const onContainerHover = () => {
        // only when the slideout is fully 
        if (!isRevealed && !config.isMobileView && state.isFullyClosed) {
            Updaters.updateRightPosAddon(-7);
        }
    }
    const onContainerLoseHover = () => {
        if (!isRevealed && !config.isMobileView && state.isFullyClosed) {
            Updaters.updateRightPosAddon(0);
        }
    }

    // Close Button Related
    const onCloseButtonClick = () => {
        if (isRevealed) {
            hide();
        }
    }

    // Mount animation related
    const onMountAnimationEnded = useCallback(() => Updaters.updateFirstMountPlayed(true), []);

    // Any Special Conditions
    const mobileCloseBtnCondition = useMemo(() => (
        (config.subContent?.withCloseButton ?? true)
        && ((isMobile() && state.wasHidden) || (!isMobile()))
    ), [state.wasHidden, config.subContent?.withCloseButton]);

    const handleTouchStart = (e: React.TouchEvent) => {
        isDraggingRef.current = true;
        // Store the initial touch position
        startX.current = e.touches[0].clientX;
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        if (startX.current === null) return; // Ignore if no initial touch position

        const currentX = e.touches[0].clientX;
        const diffX = currentX - startX.current; // Calculate swipe distance

        if (Math.abs(diffX) > threshold) {
            // Check if the swipe distance exceeds the threshold
            if (diffX > 0) {
                // Right swipe
                if (state.isFullyClosed) {
                    reveal();
                }
            } else {
                // left swipe
                if (isFullyRevealed) {
                    hide();
                }
            }
            startX.current = null; // Reset the initial touch position after detecting swipe
        }
    };

    const handleTouchEnd = () => {
        isDraggingRef.current = false;
        // Reset touch position when the touch ends
        startX.current = null;
    };

    return (
        // make sure to pass all state updaters to all sub-components for easier extensibility
        <LocalStateUpdatersContext.Provider value={Updaters}>
            <>
                {/* Glare Effect Component */}
                <GlareEffectMaster
                    subContent={config.subContent}
                    glareOpacity={state.glareOpacity}
                    isRevealed={isRevealed}
                    wasHidden={state.wasHidden}
                    rightPos={state.rightPos}
                    show={config.withGlare}
                />
                {/* Open Button Component */}
                <OpenButton
                    onClick={onEdgeClick}
                    show={state.wasHidden} // show on desktop always, on mobile only if it was hidden before.
                    isRevealed={isRevealed}
                    subContent={config.subContent}
                    rightOffsetPos={state.rightPos}
                    isFullyRevealed={isFullyRevealed}
                    isFullyClosed={state.isFullyClosed}
                    rightPosAddon={state.rightPosAddon}
                    isVerySmallDevice={config.isVerySmallDevice}
                />
                {/* Close Button Component */}
                <CloseButton
                    show={mobileCloseBtnCondition} // show on desktop always, on mobile only if it was hidden before.
                    subContent={config.subContent}
                    onClick={onCloseButtonClick}
                    rightOffsetPos={state.rightPos}
                    isRevealed={isRevealed}
                    isVerySmallDevice={config.isVerySmallDevice}
                    isFullyRevealed={isFullyRevealed}
                />
                {/* SlideOut Wrapper for this whole Component */}
                <SlideOutContainerMaster
                    styleObj={Styles.MainContainer(isFullyRevealed, config.isVerySmallDevice, isMobile(), state.rightPos + state.rightPosAddon, isRevealed, state.isFullyClosed)}
                    onMountAnimationEnded={onMountAnimationEnded}
                    playMountAnimation={!state.firstMountPlayed} // play bounce (to the right direction) animation only on first mount
                    onLoseHover={onContainerLoseHover}
                    onHover={onContainerHover}
                    ref={mainContainerRef}
                    onClick={onEdgeClick}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                    isDraggingRef={isDraggingRef}
                >
                    {/* Main Overlay Layer that dims when closed and transparent when opened */}
                    <Overlay overlayOpacity={state.overlayOpacity} />
                    {/* SubContent title */}
                    <Title
                        subContent={config.subContent}
                        text={config.subContent.title}
                        isRevealed={isRevealed}
                        wasHidden={state.wasHidden}
                    />
                    {/* SubContent Gif or Image controller Component */}
                    <GifOrImage
                        enlargeOnStart={!state.wasHidden && (config.subContent.subType === 'slotMachine')} // only if it was not mounted before, enlarge on start of the gif. specific to slotMachine
                        classNameSuffix={config.subContent.subType}
                        forceEnlargeGif={state.forceEnlargeGif}
                        slideOutRevealed={isRevealed}
                        gifDuration={config.gifDuration}
                        playOnStart={!state.wasHidden} // only if it was not mounted before, play the gif on intial show up.
                        replayGif={state.replayGif} // make sure to replay gif animation only before hiding.
                        staticSrc={config.staticSrc}
                        gifSrc={config.gifSrc}
                    />
                    {/* SubContent Description */}
                    <Description
                        subContent={props?.subContent}
                        UIData={state.UIData}
                    />
                    {/* SubContent Button */}
                    <ActionButton
                        initialGifPlayed={state.initialGifPlayed}
                        buttonHovered={state.buttonHovered}
                        subContent={config.subContent}
                        btnUIData={state.UIData.button}
                        isRevealed={isRevealed}
                        replayGif={state.replayGif}
                        wasHidden={state.wasHidden}
                        errand={props.errand}
                        hide={hide}
                    />
                </SlideOutContainerMaster>
            </>
        </LocalStateUpdatersContext.Provider>
    )
});

const SubContentPart = (props: Props) => {
    const { isOperator } = useUserContext();

    if (props.subContent.type === 'slideOut') {
        if (isOperator)
            return <></>;

        return (
            <SlideOut message={props.message} errand={props.errand} subContent={props.subContent} />
        );
    }

    return <></>;
}

export {
    SubContentPart,
}