import React, { useCallback, useEffect, useState } from 'react';
import { AngelSign } from 'morgan-sign/dist';
// import 'morgan-sign/dist/style.css'
// import AngelSign from './AngelSign/AngelSign';
import { UAParser } from 'ua-parser-js';
import { useUserContext } from '@contexts/user';
import axiosCall from '@services/axios';
import Styles from '@styles/AngelSignWrapper.module.css';
import { Button } from '@mui/material';
import { useErrandContext } from '@contexts/ErrandContext';
import { IMessage } from '@interfaces/Conversation';
import { useTranslation } from 'react-i18next';
import eventBus from '@common/eventBus';

type TAngelSignUser = {
  ipAddress: string;
  location: string;
  operatingSystem: string;
  browser: string;
  timezone: string;
  hashKey: string;
};

type TAngelSignWrapperProps = {
  onSuccess: Function;
  onError: Function;
  hashKey: string;
  actionMessage: IMessage;
};

interface IUserAccessPartial {
  address: string;
  countryCode: string;
  timezone: string;
  userAgent: string;
}

const AngelWithHeadset = process.env.REACT_APP_MORGAN_CDN + '/Images/AngelSign/AngelWithHeadset.svg';
const ChatBubbles = process.env.REACT_APP_MORGAN_CDN + '/Images/AngelSign/ChatBubbles.svg';

/**
 * The button that appears at the bottom of the window to return to the chat
 */
function CloseButton({ onClick }) {
  const { t } = useTranslation();

  return (
    <div onClick={onClick} className={Styles.closeButtonContainer}>
      <Button className={Styles.closeButton} variant="contained">
        <img src={AngelWithHeadset} className={Styles.angelWithHeadsetImage} alt="" />
        <div className={Styles.backToChatContainer}>
          <p className={Styles.backToChatText}>{t('backToChat')}</p>
          <img src={ChatBubbles} className={Styles.chatBubblesImage} alt="" />
        </div>
      </Button>
    </div>
  );
}

/**
 * This component is responsible for displaying the Ai-Sign window that allows
 * users to sign, download, and decline envelopes
 */
export default function AngelSignWrapper(props: TAngelSignWrapperProps) {
  const contextUser = useUserContext();
  const errandContext = useErrandContext();
  const [userAccessData, setUserAccessData] = useState<IUserAccessPartial | null>(null);
  const [closed, setClosed] = useState(0);
  const handleClose = useCallback(() => {
    console.log('Closing AngelSign window');
    setClosed(1);
    // Allow time for the animation to play first
    setTimeout(() => {
      errandContext.setAngelSignData(null);
    }, 1000);
  }, [errandContext]);
  const userCanInteract = contextUser.isOperator || contextUser._id === props.actionMessage?.userAction?.owner;

  /**
   * Fetches the user's most recent data from the accesses collection.
   * This is done to get the user's IP address, location, operating system,
   * browser, and timezone for Ai-Sign to use.
   */
  const getUserAccessData = useCallback(async () => {
    try {
      if (typeof contextUser?._id !== 'string') {
        throw new Error(`invalid contextUser: ${JSON.stringify(contextUser)}`);
      }
      const userAccessArr = await axiosCall({
        url: `user/${contextUser._id}/access?order=desc&limit=1`,
      });
      if (!Array.isArray(userAccessArr)) {
        throw new Error('userAccessArr is not an array');
      }
      if (userAccessArr.length === 0) {
        throw new Error('userAccessArr is an empty array');
      }
      const userAccess = userAccessArr[userAccessArr.length - 1];
      setUserAccessData(userAccess);
    } catch (error) {
      console.error(error);
    }
  }, [contextUser]);

  useEffect(() => {
    getUserAccessData();
  }, [getUserAccessData]);

  useEffect(() => {
    function handleCloseAngelSignWindow() {
      console.log('Received closeAngelSignWindow event');
      handleClose();
    }
    eventBus.on('closeAngelSignWindow', handleCloseAngelSignWindow);
    return () => {
      eventBus.remove('closeAngelSignWindow', handleCloseAngelSignWindow);
    };
  }, [handleClose]);

  if (!userAccessData) {
    return <></>;
  }

  // Parse the user's IP address
  let ipAddress = userAccessData.address;
  const ipRegex = /(\d{1,3}\.){3}\d{1,3}/;
  const match = ipAddress.match(ipRegex);
  if (match?.[0]) {
    ipAddress = match?.[0];
  }
  ipAddress = ipAddress.substring(0, 15);

  // Construct the user prop for the AngelSign component
  const parsedUserAgent = UAParser(userAccessData.userAgent);
  const user: TAngelSignUser = {
    ipAddress: ipAddress,
    location: userAccessData.countryCode,
    operatingSystem: parsedUserAgent?.os?.name,
    browser: parsedUserAgent?.browser?.name,
    timezone: userAccessData.timezone,
    hashKey: props.hashKey,
  };

  return (
    <div
      className={Styles.container}
      // @ts-ignore
      closed={closed}
    >
      <div
        className={[Styles.angelSignContainer, ...(!userCanInteract ? [Styles.disablePointerEvents] : [])].join(' ')}
      >
        <AngelSign
          user={user}
          onSuccess={props.onSuccess}
          onError={props.onError}
          endpointUrl={process.env.REACT_APP_MORGAN_CORE + '/angelsign'}
        />
      </div>
      <CloseButton onClick={handleClose} />
    </div>
  );
}
